import { useEffect } from "react";

/**
 * A custom hook for adding an event listener to the window object.
 * Can be used to listen to custom events created by window.dispatchEvent.
 *
 * Should only be used when absolutely necessary
 *
 * @param event the type of event to listen for
 * @param handleEvent the function to be called when the specified event is triggered
 *
 * @returns void
 */
export const useCustomEventListener = (event: string, handleEvent: () => void): void => {
  useEffect(() => {
    window.addEventListener(event, handleEvent);

    return () => {
      window.removeEventListener(event, handleEvent);
    };
  }, []);
};
