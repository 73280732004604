import { APIProvider } from "@vis.gl/react-google-maps";

import { MapConstants } from "./constants";
import { KanaMap } from "./KanaMap/KanaMap";

interface MapProps {
  coordinates: string[];
  fullWidth?: boolean;
  mode?: "terrain" | "satellite" | "hybrid" | "roadmap";
  height: number;
}

export const Map = ({ coordinates, fullWidth = false, mode = "roadmap", height }: MapProps): JSX.Element => {
  return window.API_URL !== "http://localhost:7108" ? (
    <APIProvider apiKey={MapConstants.API_KEY}>
      <KanaMap coordinates={coordinates} fullWidth={fullWidth} mode={mode} height={height} />
    </APIProvider>
  ) : (
    <div />
  );
};
