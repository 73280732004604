import { FormEvent, useCallback, useContext, useEffect, useState } from "react";

import { InteractionObjectType } from "../../../../constants";
import { addOrRemoveObjectInteractions } from "../../../../service/interaction";
import { searchInteractions, searchProjects } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../../../widget";
import { TagsContext } from "../../TagsContext";

interface ProjectTag {
  projectUuid: string;
  projectName: string;
  checked: boolean;
}

interface UseTagProjectsProps {
  onClose: () => void;
}

interface UseTagProjectsReturnData {
  currentTagProjects: ProjectTag[] | undefined;
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleTagProjectCheckboxChange: (isChecked: boolean, tagProjectUuid: string) => void;
}

export const useTagProjects = ({ onClose }: UseTagProjectsProps): UseTagProjectsReturnData => {
  const { showTagProjectsPanelUuid, errors, setErrors } = useContext(TagsContext);

  const [initTagProjects, setInitTagProjects] = useState<ProjectTag[]>();
  const [currentTagProjects, setCurrentTagProjects] = useState<ProjectTag[]>();

  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleTagProjectCheckboxChange = (isChecked: boolean, tagProjectUuid: string): void => {
    if (currentTagProjects) {
      const updatedTags = currentTagProjects.map((tagProject) =>
        tagProject.projectUuid === tagProjectUuid ? { ...tagProject, checked: isChecked } : tagProject
      );

      setCurrentTagProjects(updatedTags);
    }
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (!showTagProjectsPanelUuid) return;

    const changedTagProjects =
      currentTagProjects?.filter((currentTagProject) =>
        initTagProjects?.some(
          (initTagProject) =>
            initTagProject.projectUuid === currentTagProject.projectUuid &&
            initTagProject.checked !== currentTagProject.checked
        )
      ) ?? [];

    const addOrRemoveObjectInteractionsRes = await addOrRemoveObjectInteractions({
      objectInteractions:
        changedTagProjects?.map((tagProject) => ({
          interactionUuid: showTagProjectsPanelUuid,
          objectType: InteractionObjectType.PROJECT,
          objectUuid: tagProject.projectUuid,
          operation: tagProject.checked === true ? "add" : "remove",
        })) ?? [],
    });

    if (addOrRemoveObjectInteractionsRes.status === Status.Success) {
      Toast.success({ message: "Project tags updated successfully" });
      onClose();
    }

    if (addOrRemoveObjectInteractionsRes.status === Status.Error) {
      setErrors(addOrRemoveObjectInteractionsRes.errors);
    }
  }, setIsHandleSubmitLoading);

  const fetchTagProjects = useCallback(async () => {
    const searchInteractionsFilterCriteria = dataGridMapFilterCriteria([]);
    searchInteractionsFilterCriteria.uuid = {
      operator: "in",
      value: [showTagProjectsPanelUuid],
    };

    const [searchProjectsRes, searchInteractionsRes] = await Promise.all([
      searchProjects({
        paging: {
          beforeCursor: null,
          afterCursor: null,
          limit: 50,
        },
      }),
      searchInteractions({
        paging: {
          beforeCursor: null,
          afterCursor: null,
          limit: 50,
        },
        filter: {
          results: searchInteractionsFilterCriteria,
        },
      }),
    ]);

    if (
      searchProjectsRes.status === Status.Success &&
      searchProjectsRes.data?.results != null &&
      searchInteractionsRes.status === Status.Success &&
      searchInteractionsRes.data?.results != null
    ) {
      const tagProjects = searchProjectsRes.data.results.map((project) => {
        return {
          projectUuid: project.uuid,
          projectName: project.displayName,
          checked:
            searchInteractionsRes.data?.results.some((interaction) =>
              interaction.objectInteractions.some((oi) => oi.objectUuid === project.uuid && oi.endDate == null)
            ) ?? false,
        };
      });
      setCurrentTagProjects(tagProjects);
      setInitTagProjects(tagProjects);
    }
  }, [showTagProjectsPanelUuid]);

  useEffect(() => {
    if (showTagProjectsPanelUuid !== undefined) {
      fetchTagProjects();
    } else {
      setCurrentTagProjects(undefined);
      setInitTagProjects(undefined);
    }
  }, [showTagProjectsPanelUuid]);

  return {
    currentTagProjects,
    errors,
    isHandleSubmitLoading,
    handleTagProjectCheckboxChange,
    handleSubmit,
  };
};
