/* Note - some crude DOM node manipulation & using queryselectors. 
  This isn't standard practice but required in this case to integrate effectively with the google charts component */
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { ColorConstants } from "../../../../constants";
import { SVGConstants } from "../../../../constants/generic/SVGConstants";
import { ProjectRoadmapData } from "../../../../models";
import { ChartReferenceNodes, ViewMode } from "../models";

// --- Pure Utils ---
// Utility function to create an SVG rectangle
const createRectangle = (x: string, y: string, height: string, width: string | null, fill: string): SVGRectElement => {
  const rect = document.createElementNS(SVGConstants.SVG_NAMESPACE, "rect");
  rect.setAttribute("x", x);
  rect.setAttribute("y", y);
  rect.setAttribute("height", height);
  rect.setAttribute("width", width || "0");
  rect.setAttribute("fill", fill);
  return rect;
};

// --- Impure Utils ---
// Handles the marker placement on the timeline
const placeMarker = (
  startDate: Date,
  markerDate: Date,
  timelineUnit: number,
  height: number,
  svgNode: Element,
  baselineBounds: DOMRect
): void => {
  const markerSpan = markerDate.getTime() - startDate.getTime();

  // --- Create marker line svg ---
  const markerLine = createRectangle(
    (baselineBounds.x + timelineUnit * markerSpan).toString(),
    "0",
    height.toString(),
    "2",
    ColorConstants.I_BLUE_40
  );

  // Append marker to SVG node
  svgNode.appendChild(markerLine);
};

// Handles the scroll position adjustment
// BR-01941
const adjustScrollPosition = (
  projectRoadmapRef: MutableRefObject<HTMLDivElement | null>,
  startDate: Date,
  markerDate: Date,
  viewMode: ViewMode,
  baselineBounds: DOMRect,
  timelineUnit: number
): void => {
  // --- Scroll Position Calculation ---
  const secondsInAMonth = 30 * 24 * 60 * 60 * 1000; // Approximate milliseconds in a month
  let scrollDateInSeconds = markerDate.getTime();

  // Adjust scrollDateInSeconds based on viewMode
  switch (viewMode) {
    case ViewMode.Months3:
      scrollDateInSeconds -= secondsInAMonth * 0.6;
      break;
    case ViewMode.Months6:
      scrollDateInSeconds -= secondsInAMonth * 1.2;
      break;
    case ViewMode.Year:
      scrollDateInSeconds -= secondsInAMonth * 2.4;
      break;
    case ViewMode.Years3:
      scrollDateInSeconds -= secondsInAMonth * 7.2;
      break;
    case ViewMode.Years10:
      scrollDateInSeconds -= secondsInAMonth * 24;
      break;
    case ViewMode.Years20:
      scrollDateInSeconds -= secondsInAMonth * 48;
      break;
    default:
      break;
  }

  const scrollSpan = scrollDateInSeconds - startDate.getTime();

  // Set horizontal scroll position
  if (projectRoadmapRef.current) {
    // eslint-disable-next-line no-param-reassign
    projectRoadmapRef.current.scrollLeft = baselineBounds.x + timelineUnit * scrollSpan;
  }
};

// Calculate and superimpose today's date indicator onto timeline (based on https://stackoverflow.com/a/48509661)
export const addMarkerAndSetScrollPosition = (
  projectRoadmapRef: MutableRefObject<HTMLDivElement | null>,
  viewMode: ViewMode,
  svgNode: SVGGraphicsElement | undefined,
  height: number | undefined,
  data: ProjectRoadmapData[]
): void => {
  if (!svgNode || !height) return;

  // --- List of all chart dates ---
  const dates = data.flatMap((x) =>
    Object.values(x.dates)
      .filter((value) => value.start && value.end)
      .map((value) => ({
        startDate: value.start.getTime(),
        endDate: value.end.getTime(),
      }))
  );

  // --- Calculate start, end, and marker dates ---
  const startDate = new Date(Math.min(...dates.map((x) => x.startDate)));
  const endDate = new Date(Math.max(...dates.map((x) => x.endDate)));
  const markerDate = new Date();

  // --- Marker Placement Calculation ---
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const timelineWidth = parseFloat(svgNode.getAttribute("width")!);
  const baselineBounds = svgNode.getBBox();
  const timespan = endDate.getTime() - startDate.getTime();
  const timelineUnit = (timelineWidth - baselineBounds.x) / timespan;

  placeMarker(startDate, markerDate, timelineUnit, height, svgNode, baselineBounds);
  adjustScrollPosition(projectRoadmapRef, startDate, markerDate, viewMode, baselineBounds, timelineUnit);
};

// Google charts renders the chart as multiple <svg> elements, this function finds the top level one
export const getTopLevelRoadmapSvg = (
  projectRoadmapRef: MutableRefObject<HTMLDivElement | null>
): SVGGraphicsElement | undefined => {
  // Query all SVG elements in the specified container
  const svgs = projectRoadmapRef.current?.querySelectorAll<SVGGraphicsElement>(
    ".ProjectRoadmap .ProjectRoadmapContainer svg"
  );

  // Find the SVG with the largest "_ABSTRACT_RENDERER_ID_" number in its <defs>
  let largestNumber = -1;
  let topLevelSvg: SVGGraphicsElement | undefined;

  svgs?.forEach((svg) => {
    const defs = svg.querySelector("defs");
    if (defs && defs.id.startsWith("_ABSTRACT_RENDERER_ID_")) {
      const number = parseInt(defs.id.replace("_ABSTRACT_RENDERER_ID_", ""), 10);
      if (number > largestNumber) {
        largestNumber = number;
        topLevelSvg = svg;
      }
    }
  });

  return topLevelSvg;
};

/**
 * Ensures that all bars in the timeline have a minimum width of 8px
 * @param svgNode - The top level svg element of the timeline
 */
export const setBarMinWidth = (chartReferenceNodes: ChartReferenceNodes, svgNode?: Element): void => {
  if (svgNode && svgNode.childNodes.length > 5) {
    // Safely assign reference nodes
    // eslint-disable-next-line no-param-reassign
    chartReferenceNodes.bars = svgNode.childNodes[3] as SVGElement;
    // eslint-disable-next-line no-param-reassign
    chartReferenceNodes.highlightBars = svgNode.childNodes[5] as SVGElement;
  }

  // Combine rects from both 'bars' and 'highlightBars' into one loop
  [chartReferenceNodes.bars, chartReferenceNodes.highlightBars].forEach((group) => {
    // Ensure the group exists and has child nodes
    group?.querySelectorAll("rect").forEach((rect) => {
      // Set 'rx' attribute for rounded corners
      rect.setAttribute("rx", "4");

      // If the stroke is black by default, we know it is selected
      const currentStroke = rect.getAttribute("stroke");
      if (currentStroke === "#000000") {
        rect.classList.add("select"); // makes targeting this element easier in css
      }

      const currentFill = rect.getAttribute("fill");
      if (currentFill) {
        rect.setAttribute("stroke", currentFill);
      }
      // Update 'width' only if necessary
      const currentWidth = parseFloat(rect.getAttribute("width") ?? "0");
      if (currentWidth < 8) {
        rect.setAttribute("width", "8"); // No need for "px" in SVG attributes
      }
    });
  });
};

/**
 * Adjusts the timeline's position and styling so it appears on top of the horizontal axis
 * @param projectRoadmapRef - The reference to the ProjectRoadmap component
 * @param chartReferenceNodes - References to chart elements including the timeline clone.
 * @param svgNode - The top level svg element of the timeline chart
 */
export const adjustTimeline = (
  projectRoadmapRef: MutableRefObject<HTMLDivElement | null>,
  chartReferenceNodes: ChartReferenceNodes,
  svgNode?: Element
): void => {
  if (svgNode === undefined || projectRoadmapRef === null) return;

  // --- Find the svg group for the horizontal axis ---
  const hAxisSvgGroup = projectRoadmapRef.current?.querySelector("g text")?.closest("g");

  if (hAxisSvgGroup == null) return;

  // --- Create timeline background svgs ---
  const timelineBackground = createRectangle(
    "0",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (projectRoadmapRef!.current!.scrollHeight - 104.2).toString(),
    "100.2",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    parseFloat(svgNode.getAttribute("width")!).toString(),
    ColorConstants.WHITE
  );
  const timelineBackgroundBottomBorder = createRectangle(
    "0",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (projectRoadmapRef!.current!.scrollHeight - 4).toString(),
    "1",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    parseFloat(svgNode.getAttribute("width")!).toString(),
    ColorConstants.GREY_20
  );

  // --- Add timeline background svgs behind the horizontal axis ---
  hAxisSvgGroup.insertBefore(timelineBackground, hAxisSvgGroup.childNodes[0]);
  hAxisSvgGroup.insertBefore(timelineBackgroundBottomBorder, hAxisSvgGroup.childNodes[0]);

  // --- Clone the timeline and replace it so it's in the right order (svg doesn't have z-index) ---
  const hAxisSvgGroupClone = hAxisSvgGroup.cloneNode(true) as SVGElement;
  hAxisSvgGroupClone.classList.add("timeline"); // makes targeting this element easier in css

  hAxisSvgGroup.remove(); // remove old horizontal axis

  svgNode.appendChild(hAxisSvgGroupClone); // add new horizontal axis to svg
  // eslint-disable-next-line no-param-reassign
  chartReferenceNodes.timelineClone = hAxisSvgGroupClone; // set reference to new horizontal axis clone
};

/**
 * Updates the position of the tooltip based on the current scroll position of the ProjectRoadmap component.
 * @param projectRoadmapRef - The reference to the ProjectRoadmap component
 * @param chartReferenceNodes -  References to chart elements including the tooltip clone.
 */
export const updateTooltipPosition = (
  projectRoadmapRef: MutableRefObject<HTMLDivElement | null>,
  chartReferenceNodes: ChartReferenceNodes
): void => {
  if (chartReferenceNodes && chartReferenceNodes.tooltipClone && projectRoadmapRef.current) {
    // eslint-disable-next-line no-param-reassign
    chartReferenceNodes.tooltipClone.style.top = `${projectRoadmapRef.current.scrollTop.toString()}px`;
    // eslint-disable-next-line no-param-reassign
    chartReferenceNodes.tooltipClone.style.left = `${(projectRoadmapRef.current.scrollLeft + projectRoadmapRef.current.clientWidth - 435).toString()}px`;
  }
};

/**
 * Resets the height of the chart by setting the height of the svg element and
 * its parent div to the height of the ProjectRoadmapRowLabels component.
 * @param projectRoadmapRowLabelsRef - The reference to the ProjectRoadmapRowLabels component.
 * @param height - The height of the chart.
 * @param setHeight - The function to set the height of the chart.
 * @param shouldResetChartHeight - A boolean to indicate if the chart height should be reset.
 * @param setShouldResetChartHeight - The function to set the value of shouldResetChartHeight.
 * @param svgNode - The svg element to set the height of.
 */
export const resetChartHeight = (
  projectRoadmapRowLabelsRef: MutableRefObject<HTMLDivElement | null>,
  height: number | undefined,
  setHeight: Dispatch<SetStateAction<number | undefined>>,
  shouldResetChartHeight: boolean,
  setShouldResetChartHeight: Dispatch<SetStateAction<boolean>>,
  svgNode: Element | undefined
): void => {
  if (projectRoadmapRowLabelsRef.current?.scrollHeight && shouldResetChartHeight) {
    setHeight(projectRoadmapRowLabelsRef.current.scrollHeight);
    setShouldResetChartHeight(false);
  }

  if (height) {
    svgNode?.setAttribute("height", height.toString());
    // eslint-disable-next-line no-param-reassign
    (svgNode?.parentNode as HTMLElement).style.height = `${height}px`; // set parent div height
  }
};

/**
 * Handles the scroll event for the project roadmap.
 * Updates the position of the timeline clone based on the scroll position of the project roadmap container.
 * Also updates the tooltip position to align with the current scroll position.
 *
 * @param projectRoadmapRef - The reference to the project roadmap container element.
 * @param chartReferenceNodes - References to chart elements including the timeline clone.
 * @param height - The height of the chart, used to calculate the translation for the timeline clone.
 */

export const onScroll = (
  projectRoadmapRef: MutableRefObject<HTMLDivElement | null>,
  chartReferenceNodes: ChartReferenceNodes,
  height: number | undefined
): void => {
  if (!projectRoadmapRef.current?.scrollTop && !chartReferenceNodes.timelineClone) return;

  if (height !== undefined)
    chartReferenceNodes.timelineClone?.setAttribute(
      "transform",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `translate(0,-${height! - projectRoadmapRef.current!.scrollTop - 48})`
    );

  updateTooltipPosition(projectRoadmapRef, chartReferenceNodes);
};
