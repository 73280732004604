import "../styles/DataGrid.css";

import { Dispatch, SetStateAction, UIEvent, useEffect, useState } from "react";
import { Column as RdgColumn, SortColumn as RdgSortColumn } from "react-data-grid";

import { FilterCriteria, FilterOperator, SortCriteria } from "../../../../models";
import { ResultData } from "../../../../service/Shared";
import { onFilterChangedEvent } from "../components/HeaderRenderer";
import { EditColumns } from "../models";
import { getFilterCriteriaWithCastedValues, mapColumnDefinitionToRdgColumn } from "../utils";
import { CursorDataGridProps } from "./CursorDataGrid";

export interface UseDataGridReturnData {
  filterCriteria: FilterCriteria[];
  setFilterCriteria: Dispatch<SetStateAction<FilterCriteria[]>>;
  resultData: readonly ResultData[];
  filteredRdgColumns: RdgColumn<ResultData>[];
  sortColumns: readonly RdgSortColumn[];
  onSort: (newSortColumns: RdgSortColumn[]) => void;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  totalCount: number;
  filtersEnabled: boolean;
  setFiltersEnabled: Dispatch<SetStateAction<boolean>>;
  showFilterToggleSwitch: boolean;
  onExport: () => void;
  onScroll: (e: UIEvent<HTMLElement>) => void;
  onRefreshColumns: () => void; // for edit columns
}

export const useCursorDataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  onChange,
  pagination = { pageSize: 10 },
  defaultSortingCriteria = [],
  refresh,
  showFilterToggle,
  triggerPagePrevious,
  onExternalExport,
  exportFileName = "",
  filterExportRows,
  filterExportColumns,
  infiniteScroll = false,
  editColumnsLocalStorageItemName,
}: CursorDataGridProps): UseDataGridReturnData => {
  const [resultData, setResultData] = useState<readonly ResultData[]>(data || []);
  const [sortColumns, setSortColumns] = useState<readonly RdgSortColumn[]>(
    sortable && defaultSortingCriteria ? defaultSortingCriteria : []
  );
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria[]>([]);

  const [dgStartCursor, setDgStartCursor] = useState<string | undefined>(pagination.startCursor);
  const [dgEndCursor, setDgEndCursor] = useState<string | undefined>(pagination.endCursor);
  const [dgBeforeCursor, setDgBeforeCursor] = useState<string>();
  const [dgAfterCursor, setDgAfterCursor] = useState<string>();

  const [hasNext, setHasNext] = useState<boolean>(true);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [filtersEnabled, setFiltersEnabled] = useState(false);

  const [onNextTriggered, setOnNextTriggered] = useState(true); // For infinite scroll

  const [editColumns, setEditColumns] = useState<EditColumns>(); // For editable columns

  const onPrevious = (): void => {
    setDgBeforeCursor(dgStartCursor);
    setDgAfterCursor(undefined);
  };

  useEffect(() => {
    if (triggerPagePrevious !== undefined && hasPrevious) {
      onPrevious();
    }
  }, [triggerPagePrevious]);

  const onNext = (): void => {
    setOnNextTriggered((prevOnNextTriggered) => !prevOnNextTriggered);

    setDgBeforeCursor(undefined);
    setDgAfterCursor(dgEndCursor);
  };

  const onSort = (newSortColumns: RdgSortColumn[]): void => {
    setSortColumns(newSortColumns);
    // reset paging when changing sort order
    setDgBeforeCursor(undefined);
    setDgAfterCursor(undefined);
  };

  const pageSize = pagination.pageSize || 10;

  const onFilterChanged: onFilterChangedEvent = (
    columnKey: string,
    operator: FilterOperator,
    value: string,
    filterId: number | undefined
  ): void => {
    let starterFilters = filterCriteria.filter((f) => f.key !== columnKey);

    starterFilters = starterFilters.concat({
      key: columnKey,
      operator,
      value,
      filterId,
    });

    setFilterCriteria(starterFilters);
    // reset paging when changing filtering
    setDgBeforeCursor(undefined);
    setDgAfterCursor(undefined);
  };

  const masterRdgColumns: RdgColumn<ResultData>[] = mapColumnDefinitionToRdgColumn(
    columns,
    sortable,
    filterable,
    sortColumns,
    setSortColumns,
    filtersEnabled,
    filterCriteria,
    setFilterCriteria,
    onFilterChanged
  );

  const filteredRdgColumns = editColumns
    ? masterRdgColumns
        ?.filter((column) => editColumns[column.key].show)
        .sort((a, b) => editColumns[a.key].index - editColumns[b.key].index)
    : masterRdgColumns;

  const getCurrentFilterCriteria = (criteria: FilterCriteria[]): FilterCriteria[] => {
    let tempFilterCriteria = [...criteria];

    if (criteria.some((fc) => fc.value === "" && fc.operator !== "contains")) {
      // If value is empty, remove that filter from a temp Array so that all data is being refetched
      tempFilterCriteria = tempFilterCriteria.filter((tfc) => !(tfc.value === "" && tfc.operator !== "contains"));
    }
    return tempFilterCriteria;
  };

  const getCurrentSortCriteria = (sortCols: readonly RdgSortColumn[]): SortCriteria[] => {
    return sortCols.map(
      (sc): SortCriteria => ({
        key: sc.columnKey,
        direction: sc.direction === "DESC" ? "desc" : "asc",
      })
    );
  };

  const onExport = (): void => {
    if (onChange) {
      let csvURL: string;

      const tempFilterCriteria = getCurrentFilterCriteria(filterCriteria);
      const sortCriteria = getCurrentSortCriteria(sortColumns);

      onChange({
        filtering: getFilterCriteriaWithCastedValues(tempFilterCriteria, columns),
        sorting: sortCriteria,
        paging: {
          pageSize: totalCount > 1000 ? 1000 : totalCount,
        },
      })
        .then((update) => {
          const exportResultData =
            filterExportRows !== undefined ? filterExportRows(update.resultData) : update.resultData;
          const exportColumns =
            filterExportColumns !== undefined ? filterExportColumns(filteredRdgColumns) : filteredRdgColumns;

          const separator = ",";
          const header = `${exportColumns.map((x) => x.name).join(separator)}\n`;
          const csv =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prettier/prettier
          header + exportResultData.map((row) => exportColumns.map((col) => row[col.key]?.text?? row[col.key]).join(separator)).join("\n");
          const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          csvURL = window.URL.createObjectURL(csvData);
          const tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", `${exportFileName}${new Date().toISOString()}_grid_data.csv`);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
        })
        .finally(() => {
          URL.revokeObjectURL(csvURL);
        });
    }
  };

  const onScroll = (e: UIEvent<HTMLElement>): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventTarget = e.target as any;

    if (eventTarget.scrollTop + eventTarget.clientHeight >= eventTarget.scrollHeight) {
      if (hasNext) onNext();
    }
  };

  /**
   * Updates the columns in the DataGrid based on the user's selection
   * stored in local storage.
   */
  const onRefreshColumns = (): void => {
    if (editColumnsLocalStorageItemName) {
      const storedObject = localStorage.getItem(editColumnsLocalStorageItemName);
      if (storedObject) {
        setEditColumns(JSON.parse(storedObject) as EditColumns);
        setFilterCriteria([]);
        setSortColumns([]);
      }
    }
  };

  const onPageChange = (onInfiniteScroll?: boolean, onReset?: boolean): void => {
    if (onChange) {
      const tempFilterCriteria = getCurrentFilterCriteria(filterCriteria);

      const sortCriteria = getCurrentSortCriteria(sortColumns);

      onChange({
        filtering: getFilterCriteriaWithCastedValues(tempFilterCriteria, columns),
        sorting: sortCriteria,
        paging: {
          pageSize,
          afterCursor: onReset ? undefined : dgAfterCursor,
          beforeCursor: onReset ? undefined : dgBeforeCursor,
        },
      }).then((update) => {
        if (onInfiniteScroll && !onReset) {
          setResultData((prevResultData) => prevResultData.concat(update.resultData));
        } else {
          setResultData(update.resultData);
        }

        if (update.paging?.startCursor) setDgStartCursor(update.paging.startCursor);
        if (update.paging?.endCursor) setDgEndCursor(update.paging.endCursor);
        setHasPrevious(update.paging.hasPreviousPage);
        setHasNext(update.paging.hasNextPage);
        setTotalCount(update.paging.totalCount);
      });
    }
  };

  // --- Infinite scroll ---
  useEffect(() => {
    // On scroll
    if (infiniteScroll) onPageChange(true);
  }, [onNextTriggered]);
  useEffect(() => {
    // On filter / sort
    if (infiniteScroll) {
      onPageChange(undefined, true);
    }
  }, [sortColumns, filterCriteria, refresh]);

  // --- Regular pagination ---
  useEffect(() => {
    if (!infiniteScroll) onPageChange();
  }, [sortColumns, filterCriteria, dgBeforeCursor, dgAfterCursor, refresh]);

  // For moving controlls outside the data grid component
  useEffect(() => {
    if (onExternalExport !== undefined) {
      onExport();
    }
  }, [onExternalExport]);

  return {
    resultData,
    filterCriteria,
    setFilterCriteria,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
    filteredRdgColumns,
    onSort,
    sortColumns,
    totalCount,
    filtersEnabled,
    setFiltersEnabled,
    showFilterToggleSwitch: showFilterToggle ?? filterable,
    onExport,
    onScroll,
    onRefreshColumns,
  };
};
