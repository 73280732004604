import "./AutocompleteMultiSelect.css";

import RCSelect from "rc-select";
import { ReactNode } from "react";

import { DropdownLightIcon } from "../../../assets";
import { SelectData } from "../../../models";
import { FormLabel } from "../FormLabel";
import { useAutocompleteMultiSelect } from "./useAutocompleteMultiSelect";

export interface AutocompleteMultiSelectProps {
  label?: string;
  allowLabelDangerousHtml?: boolean;
  initialKey?: string[] | null;
  values?: string[] | null;
  placeholder?: string;
  className?: string;
  dropdownMatchSelectWidth?: boolean;
  data: SelectData;
  order?: string[];
  onChange: (values: string[]) => void;
  onTypeSearch?: (value?: string) => void;
  tooltip?: ReactNode;
}

export const AutocompleteMultiSelect = ({
  label,
  allowLabelDangerousHtml,
  initialKey,
  values,
  placeholder = "Please select",
  className,
  dropdownMatchSelectWidth,
  data,
  order,
  onChange,
  onTypeSearch,
  tooltip,
}: AutocompleteMultiSelectProps): JSX.Element => {
  const { searchValue, selectedValues, onChangeInternal, handleSearch, options } = useAutocompleteMultiSelect({
    values,
    data,
    order,
    onChange,
    onTypeSearch,
  });

  return (
    <FormLabel label={label} tooltip={tooltip} tooltipJustify="left" allowDangerousHtml={allowLabelDangerousHtml}>
      <div className={`${className || ""} ${onTypeSearch !== undefined ? "Search" : ""} form_field`}>
        {placeholder && !searchValue && <p className="body1 AutocompleteMultiSelectPlaceholder">{placeholder}</p>}
        <RCSelect
          mode="multiple"
          defaultValue={initialKey}
          searchValue={searchValue}
          value={selectedValues}
          suffixIcon={<DropdownLightIcon />}
          onChange={onChangeInternal}
          onSearch={handleSearch}
          prefixCls="AutocompleteMultiSelect"
          autoClearSearchValue={false}
          showSearch
          filterOption={false}
          notFoundContent={null}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          dropdownAlign={{ overflow: { adjustY: 0 } }}
          removeIcon
        >
          {options}
        </RCSelect>
      </div>
    </FormLabel>
  );
};
