import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Column as RdgColumn } from "react-data-grid";

import { NotesBlueIcon } from "../../../../assets";
import {
  LocalStorageConstants,
  OrganisationTypeConstants,
  ProjectActivitiesConstants,
  ProjectStatusConstants,
} from "../../../../constants";
import { InteractionObjectType, InteractionType } from "../../../../constants/interaction";
import { CursorChangeProps, ResultType, SelectData, SortCriteria } from "../../../../models";
import {
  getOrganisationDetails,
  searchActivities,
  SearchProjectTrackerRequest,
  SearchProjectTrackerResponse,
} from "../../../../service/query";
import { searchInteractions, searchProjectTracker } from "../../../../service/query/QueryService.full";
import { FilterGroupOperator, FilterGroups, ResultData, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import {
  flattenObject,
  isEmptyObject,
  jsonReviver,
  recursivelyValidateAndTransformDateToString,
} from "../../../../utils";
import { getProjectDetailsByUuid } from "../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
  ProfileMenuItemType,
} from "../../../../widget";
import { EditColumns, IconCellFormatterData } from "../../../../widget/data/DataGrid/models";

interface UseProjectsReturnData {
  columns: DataGridColumnDefinition[];
  quickFiltersSelectData: SelectData;
  tagsFilterSelectData: SelectData;
  actionsMenuOptionsList: ProfileMenuItemType[];
  isLoading: boolean;
  refresh?: boolean;
  hasData: boolean;
  exportTable?: boolean;
  exportFileName: string;
  filterExportRows: (columns: ResultData[]) => ResultData[];
  filterExportColumns: (columns: RdgColumn<ResultData, unknown>[]) => RdgColumn<ResultData, unknown>[];

  projectTrackerLocalStorageItemName: string;
  defaultEditColumns: EditColumns;
  notesProjectUuid?: string;

  showActionsMenu: boolean;
  setShowActionsMenu: Dispatch<SetStateAction<boolean>>;
  showEditColumnsPanel: boolean;
  setShowEditColumnsPanel: Dispatch<SetStateAction<boolean>>;
  showNotesPanel: boolean;
  closeNotesPanel: () => void;

  onQuickFilter: (quickFilterKey: string) => Promise<void>;
  onTagsFilter: (quickFilterKeys: string[]) => Promise<void>;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

const PAGE_SIZE = 10;

const columns: DataGridColumnDefinition[] = [
  {
    name: "Name",
    key: "link",
    dataType: "string",
    formatter: "link",
    filterable: true,
    sortable: true,
    minWidth: 156,
  },
  {
    name: "Developer",
    key: "developer.displayName",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 148,
  },
  { key: "areaNetHa", name: "Net ha", dataType: "number", filterable: true, sortable: true, minWidth: 96 },
  {
    name: "tCO2e",
    key: "tco2e",
    dataType: "number",
    filterable: true,
    sortable: true,
    minWidth: 96,
  },
  {
    name: "Phase",
    key: "cachedPhase",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 132,
  },
  {
    name: "Registration",
    key: "registrationDate",
    dataType: "Date",
    formatter: "dateOnly",
    filterable: false,
    sortable: true,
    minWidth: 140,
  },
  {
    name: "Implementation",
    key: "implementationDate",
    dataType: "Date",
    formatter: "dateOnly",
    filterable: false,
    sortable: true,
    minWidth: 140,
  },
  {
    name: "Start",
    key: "startDate",
    dataType: "Date",
    formatter: "dateOnly",
    filterable: false,
    sortable: true,
    minWidth: 140,
  },
  {
    name: "Verification",
    key: "verificationDate",
    dataType: "Date",
    formatter: "dateOnly",
    filterable: false,
    sortable: true,
    minWidth: 140,
  },
  {
    name: "Validation",
    key: "validationDate",
    dataType: "Date",
    formatter: "dateOnly",
    filterable: false,
    sortable: true,
    minWidth: 140,
  },
  {
    name: "Public listing score",
    key: "projectListingScore",
    dataType: "string",
    formatter: "projectListingScore",
    filterable: false,
    sortable: false,
    minWidth: 168,
    alignment: "left",
  },
  {
    name: "Validator",
    key: "validator.displayName",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 148,
  },
  {
    name: "Status",
    key: "status",
    dataType: "string",
    formatter: "projectStatusPill",
    filterable: true,
    sortable: true,
    minWidth: 200,
  },
  {
    name: "Standard",
    key: "standard.displayName",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 148,
  },
  {
    name: "Registry ID",
    key: "projectReference.externalReference",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 148,
  },
  {
    name: "Group",
    key: "group.groupName",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 168,
  },
  {
    name: "Project type",
    key: "projectType.displayName",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    sortable: true,
    minWidth: 168,
  },
  {
    name: "VCUs",
    key: "cachedVcuQuantity",
    dataType: "number",
    filterable: true,
    sortable: true,
    minWidth: 100,
  },
  {
    name: "PIUs",
    key: "cachedPiuQuantity",
    dataType: "number",
    filterable: true,
    sortable: true,
    minWidth: 100,
  },
  {
    name: "Notes",
    key: "notesButton",
    dataType: "string",
    formatter: "icon",
    alignment: "center",
    filterable: false,
    sortable: false,
    minWidth: 66,
  },
];

const defaultEditColumns: EditColumns = {
  link: { show: true, index: 0 },
  "developer.displayName": { show: true, index: 1 },
  areaNetHa: { show: true, index: 2 },
  tco2e: { show: true, index: 3 },
  cachedPhase: { show: true, index: 4 },
  // nextMilestone: {show: true, index: i}
  // deadline: {show: true, index: i}
  registrationDate: { show: true, index: 5 },
  implementationDate: { show: true, index: 6 },
  startDate: { show: true, index: 6 },
  verificationDate: { show: true, index: 7 },
  validationDate: { show: true, index: 8 },
  projectListingScore: { show: true, index: 9 },
  "validator.displayName": { show: true, index: 10 },
  status: { show: false, index: 11 },
  "standard.displayName": { show: false, index: 12 },
  "projectReference.externalReference": { show: false, index: 13 },
  "group.groupName": { show: false, index: 14 },
  "projectType.displayName": { show: false, index: 15 },
  cachedPiuQuantity: { show: false, index: 16 },
  cachedVcuQuantity: { show: false, index: 17 },
  notesButton: { show: true, index: 18 },
};

enum QuickFilterKeys {
  GROUPED_PROJECTS = "groupedProjects",
  UPCOMING_VALIDATION = "upcomingValidation",
  ACTIVITY_IN_REVIEW = "activityInReview",
  ACTIVITY_IN_PROGRESS = "activityInProgress",
}

const quickFiltersSelectData = [
  { key: QuickFilterKeys.GROUPED_PROJECTS, value: "Grouped projects" },
  { key: QuickFilterKeys.UPCOMING_VALIDATION, value: "Upcoming validation" },
  { key: QuickFilterKeys.ACTIVITY_IN_REVIEW, value: "Activity in review" },
  { key: QuickFilterKeys.ACTIVITY_IN_PROGRESS, value: "Activity in progress" },
];

const filterExportRows = (rows: ResultData[]): ResultData[] => {
  // Clone rows and Covert from date to string in dd/mm/yyyy format
  const rowsClone = recursivelyValidateAndTransformDateToString(JSON.parse(JSON.stringify(rows), jsonReviver))
    .data as ResultData[];
  rowsClone.forEach((row) => {
    // eslint-disable-next-line no-param-reassign
    if (row.notesButton !== undefined) delete row.notesButton;
    // eslint-disable-next-line no-param-reassign
    if (row.projectListingScore !== undefined) delete row.projectListingScore;
  });
  return rowsClone;
};
const filterExportColumns = (rdgColumns: RdgColumn<ResultData, unknown>[]): RdgColumn<ResultData, unknown>[] => {
  return rdgColumns.filter((column) => column.key !== "notesButton" && column.key !== "projectListingScore");
};

export const useProjectTracker = (): UseProjectsReturnData => {
  const { currentOrganisationUuid, user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [hasData, setHasData] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [quickFilterCriteria, setQuickFilterCriteria] = useState<any>();
  const [quickFilterSortCriteria, setQuickFilterSortCriteria] = useState<SortCriteria>();
  const [quickFilterGroupOperator, setQuickFilterGroupOperator] = useState<FilterGroupOperator>("and");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tagsFilterCriteria, setTagsFilterCriteria] = useState<any>();
  const [tagsFilterSelectData, setTagsFilterSelectData] = useState<SelectData>([]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [showEditColumnsPanel, setShowEditColumnsPanel] = useState(false);
  const [showNotesPanel, setShowNotesPanel] = useState(false);

  const [exportTable, setExportTable] = useState<boolean>();
  const [organisationDisplayName, setOrganisationDisplayName] = useState<string>();

  const [notesProjectUuid, setNotesProjectUuid] = useState<string>();

  const actionsMenuOptionsList: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Edit columns",
      action: () => setShowEditColumnsPanel(true),
    },
    {
      id: 2,
      value: "Export table",
      action: () => setExportTable(!exportTable),
    },
  ];

  const closeNotesPanel = (): void => {
    setNotesProjectUuid(undefined);
    setShowNotesPanel(false);
  };

  const formatData = useCallback(
    (responseData: SearchProjectTrackerResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = {
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, OrganisationTypeConstants.DEVELOPMENT_MANAGER),
        } as DataGridLinkCellFormatterData;

        result.notesButton = {
          action: () => {
            setNotesProjectUuid(el.uuid);
            setShowNotesPanel(true);
          },
          icon: <NotesBlueIcon />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.link) {
      filterCriteria.displayName = filterCriteria.link;
      filterCriteria.link = undefined;
    }

    if (quickFilterSortCriteria) sorting.push(quickFilterSortCriteria);

    const filterGroupCriteria = { ...quickFilterCriteria };
    let filterGroupOperator = quickFilterGroupOperator;

    // If we filter by tags and we already have a quick filter, we need to select projectUuids from tags AND existing projectUuids
    if (tagsFilterCriteria?.uuid?.value !== undefined) {
      filterGroupOperator = "and";

      const quickFilterProjectUuids =
        filterGroupCriteria?.uuid?.value?.length > 0 ? new Set(filterGroupCriteria.uuid.value) : undefined;

      filterGroupCriteria.uuid = {
        operator: "in",
        value:
          quickFilterProjectUuids !== undefined
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              tagsFilterCriteria.uuid.value.filter((x: any) => quickFilterProjectUuids?.has(x))
            : tagsFilterCriteria.uuid.value,
      };
    }

    const filterGroup: FilterGroups<SearchProjectTrackerRequest["filter"]> = {
      filterGroups: filterGroupCriteria
        ? [{ operator: filterGroupOperator, filter: { results: filterGroupCriteria } }]
        : [],
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: PAGE_SIZE,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    await searchProjectTracker({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: PAGE_SIZE,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key !== "link" ? s.key : "displayName",
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
      filterGroups: filterGroup.filterGroups,
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: PAGE_SIZE,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setHasData(data.resultData.length > 0);
        setIsLoading(false);
      });

    return data;
  };

  const onQuickFilter = async (quickFilterKey: string): Promise<void> => {
    const filterCriteria = dataGridMapFilterCriteria([]);
    const searchActivitiesFilterCriteria = dataGridMapFilterCriteria([]);

    let sortCriteria: SortCriteria | undefined;
    let filterGroupOperator: FilterGroupOperator = "and";

    switch (quickFilterKey) {
      case QuickFilterKeys.GROUPED_PROJECTS:
        filterCriteria.group = {
          groupUuid: {
            operator: "in",
            value: [null],
          },
        };
        break;
      case QuickFilterKeys.UPCOMING_VALIDATION:
        filterCriteria.status = {
          operator: "eq",
          value: ProjectStatusConstants.UNDER_DEVELOPMENT,
        };
        filterCriteria.validationDate = {
          operator: "neq",
          value: null,
        };
        sortCriteria = { key: "validationDate", direction: "desc" };
        break;
      case QuickFilterKeys.ACTIVITY_IN_REVIEW:
        searchActivitiesFilterCriteria.status = {
          operator: "in",
          value: ProjectActivitiesConstants.INREVIEW_STATUSES,
        };
        break;
      case QuickFilterKeys.ACTIVITY_IN_PROGRESS:
        searchActivitiesFilterCriteria.status = {
          operator: "in",
          value: ProjectActivitiesConstants.INPROGESS_STATUSES,
        };
        break;
      default:
    }

    // For "Activity in review" & "Activity in progress" filters
    if (!isEmptyObject(searchActivitiesFilterCriteria)) {
      filterGroupOperator = "or";

      await searchActivities({
        paging: {
          beforeCursor: null,
          afterCursor: null,
          limit: 50,
        },
        filter: { results: searchActivitiesFilterCriteria },
      }).then((response) => {
        filterCriteria.uuid = {
          operator: "in",
          value: response.data?.results.filter((x) => x.project?.uuid != null).map((x) => x.project?.uuid),
        };
        filterCriteria.group = {
          groupUuid: {
            operator: "in",
            value: response.data?.results.filter((x) => x.group?.uuid != null).map((x) => x.group?.uuid),
          },
        };
      });
    }

    setQuickFilterCriteria(() => filterCriteria);
    setQuickFilterSortCriteria(() => sortCriteria);
    setQuickFilterGroupOperator(() => filterGroupOperator);

    setRefresh((prevRefresh) => !prevRefresh);
  };

  // On tags filter searchInteractions by tag uuids, then add objectInteraction.objectUuid's to projectTracker filter criteria
  const onTagsFilter = async (tagsFilterKeys: string[]): Promise<void> => {
    const filterCriteria = dataGridMapFilterCriteria([]);
    const searchInteractionsFilterCriteria = dataGridMapFilterCriteria([]);

    searchInteractionsFilterCriteria.uuid = {
      operator: "in",
      value: tagsFilterKeys,
    };

    await searchInteractions({
      paging: {
        beforeCursor: null,
        afterCursor: null,
        limit: 50,
      },
      filter: { results: searchInteractionsFilterCriteria },
    }).then((response) => {
      if (response.data?.results != null && response.data.results.length > 0) {
        filterCriteria.uuid = {
          operator: "in",
          value: response.data.results
            .filter((x) => x.objectInteractions.length > 0)
            .map((x) => x.objectInteractions.map((y) => y.objectUuid))
            .flat(),
        };
      }
    });

    setTagsFilterCriteria(() => filterCriteria);
    setRefresh((prevRefresh) => !prevRefresh);
  };

  // Initially populate tags filter
  const getOrganisationTags = useCallback(
    async (organisationUuid: string): Promise<void> => {
      const filterCriteria = dataGridMapFilterCriteria([]);
      filterCriteria.type = {
        operator: "eq",
        value: InteractionType.TAG,
      };
      filterCriteria.objectType = {
        operator: "eq",
        value: InteractionObjectType.ORGANISATION,
      };
      filterCriteria.objectUuid = {
        operator: "eq",
        value: organisationUuid,
      };
      await searchInteractions({
        paging: {
          beforeCursor: null,
          afterCursor: null,
          limit: 50,
        },
        filter: { results: filterCriteria },
      }).then((response) => {
        if (response.data?.results != null && response.data.results.length > 0) {
          setTagsFilterSelectData(
            response.data.results.map((x) => ({
              key: x.uuid,
              value: JSON.parse(x.data, jsonReviver).name,
            })) as SelectData
          );
        }
      });
    },
    [currentOrganisationUuid]
  );

  const getOrganisationDisplayName = useCallback(
    async (organisationUuid: string) => {
      await getOrganisationDetails({ organisationUuid }).then((res) => {
        if (res.status === Status.Success && res.data) {
          setOrganisationDisplayName(res.data.displayName ?? undefined);
        }
      });
    },
    [currentOrganisationUuid]
  );

  useEffect(() => {
    if (currentOrganisationUuid) getOrganisationDisplayName(currentOrganisationUuid);
  }, [currentOrganisationUuid]);

  useEffect(() => {
    if (currentOrganisationUuid) getOrganisationTags(currentOrganisationUuid);
  }, [currentOrganisationUuid]);

  return {
    columns,
    isLoading,
    quickFiltersSelectData,
    tagsFilterSelectData,
    refresh,
    hasData,
    actionsMenuOptionsList,
    exportTable,
    exportFileName: `${organisationDisplayName ? `${organisationDisplayName}_` : ""}Project_tracker_`.replaceAll(
      " ",
      "_"
    ),
    filterExportRows,
    filterExportColumns,
    projectTrackerLocalStorageItemName: `${user?.userUuid ? `${user?.userUuid}_` : ""}${LocalStorageConstants.PROJECT_TRACKER_PREFERENCES}`,
    defaultEditColumns,
    notesProjectUuid,

    showActionsMenu,
    setShowActionsMenu,
    showEditColumnsPanel,
    setShowEditColumnsPanel,
    showNotesPanel,
    closeNotesPanel,

    onQuickFilter,
    onTagsFilter,
    onChange,
  };
};
