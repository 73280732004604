import { logError } from "./error";
import { errorCodeFormatRegex } from "./Shared";

const validationErrorDictionary: { [key: string]: string } = {
  ERROR_ACCESS_DENIED: "You do not have access to this",
  ERROR_APPLICATION: "The application has encountered an unexpected error. Please try again later.",
  ERROR_AUTHENTICATION_REQUIRED: "Please login to your account to continue",
  ERROR_CONTENT_SCHEMA_VALIDATION: "The content you've submitted is invalid",
  ERROR_COUNTRY_NAME_NOT_FOUND: "This country could not be found.",
  ERROR_DOCUMENT_NOT_VISIBLE: "This document is not visible to the public",
  ERROR_NOT_VALID_JSON: "ERROR_NOT_VALID_JSON",
  ERROR_NO_RECORD_FOUND: "The data you requested has not been found",
  ERROR_NO_ROWS_UPDATED: "The data you are trying to update is out of date. Please refresh the page and try again",
  ERROR_PROJECT_DETAILS_DATA_NOT_EDITABLE: "This data is not editable",
  ERROR_PROJECT_FIELDS_NOT_EDITABLE: "These fields are not editable",
  ERROR_PROJECT_ISSUANCE_DATA_NOT_EDITABLE: "This project issuance data is not editable",
  ERROR_PROJECT_STANDARD_SPECIFIC_DATA_FIELDS_NOT_EDITABLE: "This data is not editable",
  VALIDATION_ADDRESS_CITY_REQUIRED: "City is a required field",
  VALIDATION_ADDRESS_COUNTRY_ISO: "Country is not invalid",
  "VALIDATION_ADDRESS_COUNTRY_ISO3166-1": "Country is not invalid",
  VALIDATION_ADDRESS_LINE: "Address line 1 is not valid",
  VALIDATION_ADDRESS_LINE1_REQUIRED: "Address line 1 is a required field",
  VALIDATION_ADDRESS_POSTAL_CODE_REQUIRED: "Postcode is a required field",
  VALIDATION_ADDRESS_POSTAL_CODE_UK_VALID: "Postcode is not valid",
  VALIDATION_AGGREGATE_INVALID: "VALIDATION_AGGREGATE_INVALID",
  VALIDATION_AGGREGATION_COUNT_AGGREGATE_INVALID: "VALIDATION_AGGREGATION_COUNT_AGGREGATE_INVALID",
  VALIDATION_AGGREGATION_INVALID: "VALIDATION_AGGREGATION_INVALID",
  VALIDATION_AGGREGATION_SUM_AGGREGATE_INVALID: "VALIDATION_AGGREGATION_SUM_AGGREGATE_INVALID",
  VALIDATION_ATTRIBUTE_NOT_DEFAULT: "{property} is not valid",
  VALIDATION_ATTRIBUTE_REGEX: "{property} is the wrong format",
  VALIDATION_AVATAR_FILE_CONTENTS_MAX_SIZE: "File must be less than {maximumSize}",
  VALIDATION_CONFLICTING_CONTACT_FLAGS: "Email redirecting must be disabled in order to disable public interest emails",
  VALIDATION_CURRENT_PASSWORD_INVALID: "Password is incorrect",
  VALIDATION_DATA_ACCESS_DENIED: "You do not have permission to perform that action",
  VALIDATION_DATE_ONLY_VALID: "Date is not valid",
  VALIDATION_DATE_TIME_VALID: "Date and time are not valid",
  VALIDATION_FILE_MAXNUMBER: "Maximum number of files exceeded",
  VALIDATION_FILE_MUST_BE_IMAGE: "Only .jpeg or .webp files are supported",
  VALIDATION_FILTERBY_INVALID: "VALIDATION_FILTERBY_INVALID",
  VALIDATION_FILTEROPERATOR_INVALID: "VALIDATION_FILTEROPERATOR_INVALID",
  VALIDATION_FILTERVALUE_ORGANISATION_UUID_INVALID: "VALIDATION_FILTERVALUE_ORGANISATION_UUID_INVALID",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_ACCOUNT_LOCKED: "You can't reset your password because your account is locked",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_ALREADY_ACCEPTED:
    "This password reset link has already been used. Please request a new one",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_EXPIRED: "This password reset link has expired. Please request a new one",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_INVALID: "This password reset link is not valid. Please request a new one",
  VALIDATION_INVALID_CREDENTIALS: "Email and password combination is incorrect",
  VALIDATION_INVITATION_TOKEN_ALREADY_ACCEPTED: "This invite has already been accepted. Please request a new one",
  VALIDATION_INVITATION_TOKEN_EXPIRED: "This invite has expired. Please request a new one",
  VALIDATION_INVITATION_TOKEN_INVALID: "This invite is invalid. Please request a new one",
  VALIDATION_INVITATION_TOKEN_NOT_FOUND: "This invite is invalid. Please request a new one",
  VALIDATION_LISTING_FILE_CONTENTS_MAX_SIZE: "File must be less than 1mb",
  VALIDATION_LOGON_NO_ACTIVE_LINKED_ORGANISATIONS: "Your account must be linked to an organisation for access",
  VALIDATION_MEDIA_FILE_KEY_NOT_VALID: "VALIDATION_MEDIA_FILE_KEY_NOT_VALID",
  VALIDATION_MEDIA_FILE_MAXIMUM_SIZE: "File must be less than 10mb",
  VALIDATION_MIME_TYPE_NOT_SUPPORTED: "Only .jpeg, .png, .gif, .webp or .pdf files are supported",
  VALIDATION_ORGANISATION_ADDRESS_TYPE_VALID: "Address is not valid",
  VALIDATION_ORGANISATION_EMBEDDING_KEY_VALID: "VALIDATION_ORGANISATION_EMBEDDING_KEY_VALID",
  VALIDATION_ORGANISATION_EMBEDDING_VALUE_VALID_HEX: "Colour code is not valid",
  VALIDATION_ORGANISATION_FILE_CONTENTS_MAX_SIZE: "File must be less than 550kb",
  VALIDATION_ORGANISATION_FILE_CONTENTS_REQUIRED: "Icon for your organisation is required",
  VALIDATION_ORGANISATION_FILE_TYPE_VALID: "VALIDATION_ORGANISATION_FILE_TYPE_VALID",
  VALIDATION_ORGANISATION_USER_MUST_HAVE_OWNER:
    "This action can't be performed as the organisation must have an active owner",
  VALIDATION_ORGANISATION_UUID_REQUIRED: "VALIDATION_ORGANISATION_UUID_REQUIRED",
  VALIDATION_PASSWORD_ACCOUNT_LOCKED:
    "You entered your password wrong too many times so your account has been temporarily locked",
  VALIDATION_PASSWORD_COMMONLY_USED:
    "This password is commonly used so is vulnerable to hackers. Please use a different password",
  VALIDATION_PASSWORD_CONFIRMATION_MISMATCH: "Passwords do not match",
  VALIDATION_PASSWORD_FORMAT_CONTIGUOUS_CHARACTERS:
    "Password should not contain 3 or more equal or sequential contiguous characters (e.g. 123, aaa)",
  VALIDATION_PASSWORD_FORMAT_USERNAME: "Password should not contain your email address",
  VALIDATION_PHONE_UK_LENGTH_RANGE: "Phone number isn't the correct length",
  VALIDATION_PHONE_UK_NO_LEADING_0: "Phone number is invalid",
  VALIDATION_PREFERENCE_KEY_IS_NOT_ALLOWED: "VALIDATION_PREFERENCE_KEY_IS_NOT_ALLOWED",
  VALIDATION_PREFERENCE_VALUE_IS_NOT_HEX_COLOR: "Colour code is not valid",
  VALIDATION_PROJECT_ACCESS_DENIED: "You don't have permission to update those details",
  VALIDATION_PROJECT_ISSUANCES_MISMATCHING_PROJECT: "VALIDATION_PROJECT_ISSUANCES_MISMATCHING_PROJECT",
  VALIDATION_PROJECT_ISSUANCE_VINTAGE_START_BEFORE_END_DATE:
    "The vintage start date must be before the vintage end date. Please amend these dates to continue",
  VALIDATION_QUANTITY_TYPE_INVALID: "Number of units is invalid",
  VALIDATION_REFRESH_TOKEN_INVALID: "VALIDATION_REFRESH_TOKEN_INVALID",
  VALIDATION_REGEX: "Value in invalid",
  VALIDATION_ROLE_VALID: "Please choose a valid role",
  VALIDATION_SIGNATURE_NOT_VALID: "File is the wrong format",
  VALIDATION_UI_MAXIMUM_FILE_SIZE: "File must be less than {maximumSize}",
  VALIDATION_UI_MAXIMUM_NUMBER_OF_FILES: "Maximum number of files exceeded",
  VALIDATION_URL_INVALID: "URL is invalid",
  VALIDATION_USERNAME_ALREADY_IN_USE:
    "This email address is already in use. Try logging in to your account or use another email address",
  VALIDATION_FORMAT_EMAIL: "Email address is invalid",
  VALIDATION_SEEKING_FUNDING_STATUS: "Only draft or pre-development projects can list as seeking funding",
  VALIDATION_WCC_PLANTING_AREA_NOT_MATCH:
    "The values entered in the planting section must add up to match the total area of the project",
  VALIDATION_VALIDATOR_ORGANISATION_NOT_VALID: "This validator isn't valid",
  VALIDATION_COORDINATES_LATITUDE_INVALID: "This latitude is invalid",
  VALIDATION_COORDINATES_LONGITUDE_INVALID: "This longitude is invalid",
  ERROR_SOURCE_PROJECT_CANNOT_BE_MERGED: "{SourceProjectName} can't be merged",
  ERROR_TARGET_PROJECT_CANNOT_BE_MERGED:
    "{SourceProjectName} can't merge with {TargetProjectName}. Only projects that are still under development can be merged with.",
  VALIDATION_PROJECT_EXTERNAL_REFERENCE_NOT_FOUND:
    "Registry ID can't be found. Please ensure that it's correct and you have access to this project",
  ERROR_SOURCE_PROJECT_STATUS_CANNOT_BE_UPDATED: "Only draft projects can be published",
  ERROR_INVALID_TARGET_STATUS: "This project can't be published",
  ERROR_SOURCE_PROJECT_CANNOT_BE_DELETED:
    "This project can't be deleted. Only projects that are draft or pre-development can be deleted",
  VALIDATION_PC_PEATLAND_AREA_NOT_MATCH:
    "The values entered in the peatland type section must add up to match the total area of the project",
  VALIDATION_MERGE_PROJECT_CODE_MUST_MATCH: "A project can only be merged with a project targeting the same code",
  VALIDATION_DEVELOPER_PROJECT_INVITATION_INVALID_ORGANISATION: "The developer is not valid",
  VALIDATION_INVALID_USER_PERMISSION: "You don't have permission to view this",
  VALIDATION_INVALID_TARGET_ROLE: "You must select a valid access level",
  VALIDATION_DRAFT_OUT_OF_DATE: "Draft cannot be saved as more recent version has been published",
  VALIDATION_INVALID_DRAFT_AUTHOR: "You don't have access to this draft",
  VALIDATION_INVALID_ACTIVITY_DEFINITION_VERSION: "This activity is invalid",
  VALIDATION_EXPIRED_ACTIVITY_DEFINITION: "This activity is no longer valid",
  VALIDATION_INVALID_PROJECT: "This project is not valid",
  VALIDATION_ACTIVITY_NOT_SUPPORTED: "This standard is invalid",
  VALIDATION_ACTIVITY_VARIANT_REQUIRED: "Please ensure you fill all mandatory fields",
  VALIDATION_INVALID_ACTIVITY: "This activity is invalid",
  VALIDATION_NO_PUBLISHED_VERSION_AVAILABLE: "New draft cannot be created. Please edit the current draft",
  VALIDATION_ACTIVITY_DEFINITION_VERSION_OUT_OF_DATE: "This activity his no longer valid",
  VALIDATION_ACTIVITY_STATUS_INVALID: "This activities status is not valid",
  VALIDATION_INVALID_TRANSITION: "This activity cannot be updated to {targetStatus}",
  VALIDATION_INVALID_PROJECT_STATUS_FOR_TRANSITION: "This project cannot be updated until it is manually approved",
  VALIDATION_INVALID_ACTIVITION_DEFINITION_FILE_TYPE: "This file type is not valid",
  VALIDATION_ACTIVITY_DOCUMENT_VARIANT_MANDATORY: "Document variant is mandatory",
  VALIDATION_ATTRIBUTE_REQUIRED: "{property} is a required field",
  VALIDATION_ATTRIBUTE_MINIMUM_LENGTH: "{property} must be greater than {minLength} characters",
  VALIDATION_ATTRIBUTE_MAXIMUM_LENGTH: "{property} must be less than {maxLength} characters",
  VALIDATION_ATTRIBUTE_MINIMUM: "{property} must be greater than {min}",
  VALIDATION_ATTRIBUTE_MAXIMUM: "{property} must be less than {max}",
  VALIDATION_ATTRIBUTE_STRING_LENGTH: "{property} must be between {minLength} and {maxLength} characters",
  VALIDATION_ATTRIBUTE_RANGE: "{property} must be between {min} and {max}",
  VALIDATION_ACTIVITY_NOT_DRAFT: "This activity is not in draft so changes cannot be made", // Likely to change
  VALIDATION_DRAFT_VERSION_REQUIRED: "This activity is not in draft so changes cannot be made", // Likely to change
  ERROR_INVALID_DATA_PATH: "This data is invalid",
  VALIDATION_INVALID_PERMISSION: "You don't have permission to complete this action",
  VALIDATION_INVALID_ACTIVITY_HISTORY: "The value input does not match a valid activity",
  VALIDATION_VARIANT_ALREADY_USED: "This variant has already been used",
  VALIDATION_ACTIVITY_TYPE_NOT_AVAILABLE: "This activity cannot be carried out against this project",
  VALIDATION_ACTIVITY_ALREADY_ACTIVE:
    "the project cannot have more than one activity of the same kind at any one time with a status of {activityStatus}",
  VALIDATION_ACTIVITY_ALREADY_DELETED: "This activity has already been deleted",
  VALIDATION_INVALID_ACTIVITY_STATUS: "This activity cannot be deleted because the status is {activityStatus}",
  VALIDATION_ACTIVITY_DOCUMENT_DELETED: "This document has been deleted",
  VALIDATION_INVALID_PROJECT_STATUS: "This projects status is not valid",
  VALIDATION_INVALID_STATUS: "Activity status is not valid",
  VALIDATION_FILE_TYPE_NOT_SUPPORTED: "This file type is not supported",
  VALIDATION_ACTIVITY_FILE_CONTENTS_MAX_SIZE: "File must be less than 10mb",
  VALIDATION_ACTIVITY_FILE_INVALID_MIME_TYPE: "Only JPEG, PNG, GIF, WebP, Xls, Doc, PPT and PDF files are supported",
  VALIDATION_UNEXPECTED_DOCUMENT_TYPE: "This document type is invalid",
  VALIDATION_STANDARD_NOT_ACTIVE: "You cannot create a project with {standard} because they are inactive",
  VALIDATION_INVALID_INPUT_PARAMETERS: "Please make sure a filter is selected", // Likely to change
  // VALIDATION_INVALID_INPUT_PARAMETERS: "This {organisationType} is invalid",
  VALIDATION_INVALID_ORGANISATION: "This organisation is invalid",
  VALIDATION_DEVELOPER_HAS_ACCESS: "{targetOrganisation} already has an invite to {project name}",
  VALIDATION_ORGANISATION_ACCESS_DENIED: "You don't have permission to update those details",
  VALIDATION_DEVELOPER_PROJECT_INVITATION_REVOKED: "This invitation has been revoked",
  VALIDATION_DEVELOPER_PROJECT_INVITATION_EXPIRED: "This invitation has expired",
  VALIDATION_DEVELOPER_PROJECT_INVITATION_ALREADY_USED: "This invitation has already been used",
  VALIDATION_DEVELOPER_ALREADY_HAS_ACCESS: "The developer chosen already has access to this project",
  VALIDATION_PROJECT_WITHOUT_DEVELOPER: "This project does not have a listed developer",
  VALIDATION_DEVELOPER_PROJECT_INVITATION_INVALID_RESPONSE: "Please choose to accept or reject this invitation",
  VALIDATION_DOCUMENT_UPLOAD_VARIANT_REQUIRED: "Please enter additional details for {property}",
  VALIDATION_AVAILABLE_FOR_SALE_NO_UNITS:
    "This project doesn't have any units so cannot list units as available for sale",
  VALIDATION_SHOW_UNIT_PRICE_NO_UNITS: "This project doesn't have any units so a unit price cannot be shown",
  ERROR_PROJECT_IDENTIFIER_REQUIRED: "Project identifier required",
  VALIDATION_FORGOTTEN_PASSWORD_TOKEN_REVOKED: "This forgotten password request has been revoked",
  VALIDATION_INVITATION_TOKEN_REVOKED: "This invitation has been revoked",
  VALIDATION_PHONE_COUNTRY_CODE: "Must be a UK number",
  VALIDATION_EMAIL_DOES_NOT_MATCH_INVITATION: "This email address does not match the one on the invite",
  ERROR_PROJECT_CONTACT_OPT_OUT: "Contact opt out must not be set",
  ERROR_PROJECT_NOT_CONTACTABLE:
    "To allow users to register interest in this project you must provide a contact email address for your organisation under settings",
  ERROR_DEVELOPER_NOT_CONTACTABLE: "ERROR_DEVELOPER_NOT_CONTACTABLE",
  VALIDATION_MERGE_PROJECT_DEVELOPER_MUST_MATCH: "VALIDATION_MERGE_PROJECT_DEVELOPER_MUST_MATCH",
  VALIDATION_RESOURCE_TYPE_INVALID: "This is invalid",
  VALIDATION_STATS_METRIC_INVALID: "This is invalid",
  VALIDATION_STATS_OBJECT_TYPE_INVALID: "This is invalid",
  VALIDATION_STATS_PERIODICITY_INVALID: "This is invalid",
  VALIDATION_CANNOT_TRANSITION_A_DRAFT:
    "The activity your are attempting to submit is in draft. Please publish the draft using 'Save and Close' in order to submit",
  VALIDATION_DOWNLOAD_EXPIRED: "The link has expired. Please generate a new link to download this file",
  VALIDATION_INVALID_COdDE: "Please select a valid standard for the new project",
  VALIDATION_INVALID_PROJECT_TYPE: "Please select a valid project type for the new project",
  VALIDATION_VIRUS_DETECTED:
    "The file you uploaded seems to contain a virus. Please check and contact us if this is not the case",
  VALIDATION_DOC_COUNT_EXCEEDED:
    "You have exceeded the number of documents supported against this activity. Please contact Kana to resolve the issue",
  VALIDATION_USER_ALREADY_REGISTERED:
    "That user is already a member of another organisation. If you would like to grant them access to your projects, please use the project access feature.",
  VALIDATION_INVALID_CODE: "Please select a valid standard for the new project",

  VALIDATION_EITHER_GROUP_OR_PROJECT_DOCUMENT: "Document must be assigned to either a project or a group",
  VALIDATION_KANA_GENERATED_DOCUMENT_TYPE: "Cannot upload document types that are generated within Kana",
  // VALIDATION_FILE_CONTENTS_MAX_SIZE: "File must be less than {max_file_size}MB",
  VALIDATION_VARIANT_NOT_SUPPORTED: "This document type does not support having a variant",
  VALIDATION_TARGET_ORGANISATION_MUST_BE_SET:
    "When uploading a private document, at least one organisation must have access",
  VALIDATION_USER_TARGET_ORGANISATION_MUST_BE_SET:
    "When uploading a private document, the user's organisation must have access",
  VALIDATION_DEVELOPER_TARGET_ORGANISATION_MUST_BE_SET:
    "When uploading a private document, the project's developer organisation must have access",
  VALIDATION_VISIBILITY_ORGANISATIONS_NOT_SUPPORTED: "Public documents do not support organisational access",

  VALIDATION_VALUE_NOT_SUPPORTED: "The input value is not supported",
  ERROR_NO_ORGANISATION_PROJECT_ACCESS: "This organisation does not have a current relationship with this project",
  VALIDATION_INVALID_DOCUMENT_HISTORY: "This document version does not exist",
  VALIDATION_VERSION_DELETED: "This document version has been deleted",
  VALIDATION_MUST_HAVE_ORGANISATION_ACCESS: "Must have organisation document access to view this version",
  VALIDATION_CANNOT_DELETE_STANDARD_APPROVED_VERSION: "Documents from an external registry cannot be deleted",
  VALIDATION_MUST_HAVE_USER_TARGET_ORGANISATION_UUID:
    "When updating a private document, the user's organisation must be given access",
  VALIDATION_DOC_TYPE_MUST_MATCH_PROJECT_STANDARD: "The document type must come from the standard of the project",
  VALIDATION_VARIANT_AND_DOC_TYPE_MUST_MATCH: "The variant type must be a child of the document type",

  VALIDATION_MUST_BE_VALID_DOCUMENT: "The documentUuid to upload a version to must be valid",
  VALIDATION_MUST_HAVE_EMPTY_ATTRIBUTES:
    "When uploading a new document version, the document's attributes should not be passed",
  VALIDATION_NO_GROUP_PROJECTS: "There are no projects relating to this group, it is likely they were all removed",
  VALIDATION_MESSAGE_PUBLISHED: "Cannot edit or delete messages that have been published",
  VALIDATION_MESSAGE_ALREADY_READ: "Message already marked as read",
  VALIDATION_ONGOING_ACTIVITY_REVIEW:
    "Cannot edit VVB if there is an activity in review with a Kana Digital Verification Partner",
  VALIDATION_DUPLICATE_TAG: "A tag with this name already exists",
  VALIDATION_END_DATE_AFTER_START_DATE: "The {property} end date must be after the start date",
  VALIDATION_START_AND_END_DATE_REQUIRED: "Both {property} start and end dates are required",
  VALIDATION_KEY_DATE_NOT_BETWEEN_START_END_DATE: "{key} must be between the {phase} start and end dates",
};

export const getErrorMessageFromCode = (
  errorCode: string,
  parameters?: { [key: string]: string | undefined }
): string => {
  let message = validationErrorDictionary[errorCode];
  if (message === undefined) {
    message = errorCode;
    // log error only if the error code matches the error format, this way for activity definition errors we're not logging anything
    if (errorCode.match(errorCodeFormatRegex)) {
      logError({
        error: `Could not find an error message for code '${errorCode}'`,
      });
    }
  }
  if (message && parameters)
    Object.keys(parameters).forEach((key) => {
      if (key && parameters[key]) {
        let value = parameters[key]?.toString() || "";
        if (key === "property") {
          value = value.replace(/([A-Z][a-z]+)/g, " $1").toLowerCase();
        }
        message = message.replace(`{${key}}`, value);
      }
      message = message.trim();
      message = message.charAt(0).toUpperCase() + message.substring(1);
    });
  return message;
};
