import "./ScrollablePills.css";

import { PillData } from "../../../models";
import { TextLink } from "../../navigation";
import { Pill } from "..";

interface ScrollablePillsProps {
  pills: PillData[];
  linkText: string;
  linkOnClick: () => void;
}

export const ScrollablePills = ({ pills, linkText, linkOnClick }: ScrollablePillsProps): JSX.Element => {
  return (
    <div className="ScrollablePillsContainer">
      <TextLink onAction={linkOnClick}>
        <p className="body1">
          <b>{linkText}</b>
        </p>
      </TextLink>
      <div className="ScrollablePills">
        {pills.map((pill) => (
          <Pill key={pill.key} label={pill.label} variant="grey-alternate" />
        ))}
      </div>
    </div>
  );
};
