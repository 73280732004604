import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LocalStorageConstants, OrganisationTypeConstants } from "../../../../constants";
import { ProjectRoadmapData, SelectData } from "../../../../models";
import { searchProjectTracker } from "../../../../service/query";
import { ServiceError, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { jsonReviver, useCustomEventListener } from "../../../../utils";
import { getProjectRoadmapRoute } from "../../../../utils/routes";
import { dataGridMapFilterCriteria } from "../../../../widget";

interface UseProjectRoadmapPageReturnData {
  errors: ServiceError[] | undefined;
  data: ProjectRoadmapData[] | undefined;
  projectsMultiSelectData: SelectData | undefined;
  editDatesPanelData: ProjectRoadmapData | undefined;
  setEditDatesPanelData: Dispatch<SetStateAction<ProjectRoadmapData | undefined>>;
  roadmapProjectUuids: string[];
  setRoadmapProjectUuids: Dispatch<SetStateAction<string[]>>;
  showAddProjectsModal: boolean;
  setShowAddProjectsModal: Dispatch<SetStateAction<boolean>>;
  closeSelectProjectsModal: () => void;
  showEditDatesPanel: boolean;
  closeEditDatesPanel: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onSaveProjectsSelectData: () => void;
  fetchSelectData: (displayName?: string) => Promise<void>;
}

export const useProjectRoadmapPage = (): UseProjectRoadmapPageReturnData => {
  const { user } = useAuth();
  const projectRoadmapLocalStorageItemName = `${user?.userUuid ? `${user?.userUuid}_` : ""}${LocalStorageConstants.PROJECT_ROADMAP_PREFERENCES}`;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [errors, setErrors] = useState<ServiceError[]>();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState<ProjectRoadmapData[]>([]);
  const [projectsMultiSelectData, setProjectsMultiSelectData] = useState<SelectData>();

  const [roadmapProjectUuids, setRoadmapProjectUuids] = useState<string[]>([]);

  const [editDatesPanelData, setEditDatesPanelData] = useState<ProjectRoadmapData>();

  const [showEditDatesPanel, setShowEditDatesPanel] = useState(false);
  const [showAddProjectsModal, setShowAddProjectsModal] = useState(false);

  const closeEditDatesPanel = (): void => {
    setShowEditDatesPanel(false);
    navigate(getProjectRoadmapRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER), { replace: true });
  };

  const fetchSelectData = useCallback(async (displayName?: string) => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.dates = {
      operator: "neq",
      value: "{}",
    };
    if (displayName) {
      filterCriteria.displayName = {
        operator: "startsWith",
        value: displayName,
      };
    }

    const res = await searchProjectTracker({
      paging: {
        limit: 50,
        beforeCursor: null,
        afterCursor: null,
      },
      filter: { results: filterCriteria },
    });
    if (res.status !== Status.Success || res.data?.results !== undefined) {
      setProjectsMultiSelectData(
        res.data?.results.map((project) => ({
          key: project.uuid,
          value: project.displayName,
        })) ?? []
      );
    }
  }, []);

  const fetchRoadmapData = useCallback(
    async (uuids: string[]) => {
      setIsLoading(true);
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.uuid = {
        operator: "in",
        value: uuids,
      };

      const res = await searchProjectTracker({
        paging: {
          limit: 15,
          beforeCursor: null,
          afterCursor: null,
        },
        filter: { results: filterCriteria },
      });
      if (res.status !== Status.Success || res.data?.results !== undefined) {
        setData(
          res.data?.results.map(
            (project) =>
              ({
                uuid: project.uuid,
                displayName: project.displayName,
                rowVersion: project.rowVersion,
                currentStatus: project.status,
                dates: JSON.parse(project.dates, jsonReviver),
              }) as ProjectRoadmapData
          ) ?? []
        );
        if (res.data?.results.length === 0) {
          setIsLoading(false);
        }
      }
    },
    [roadmapProjectUuids]
  );

  const onSaveProjectsSelectData = (): void => {
    setErrors([]);
    if (roadmapProjectUuids && roadmapProjectUuids?.length > 15) {
      setErrors([{ code: "ERROR_15_PROJECTS_MAXIMUM", message: "Please select no more than 15 projects" }]);
      return;
    }

    localStorage.setItem(projectRoadmapLocalStorageItemName, JSON.stringify(roadmapProjectUuids));
    if (roadmapProjectUuids) fetchRoadmapData(roadmapProjectUuids);

    setShowAddProjectsModal(false);
  };

  // Get project uuids from local storage if they exist
  const fetchAndSetRoadmapProjectUuids = (): void => {
    const localStorageProjectsString = localStorage.getItem(projectRoadmapLocalStorageItemName);
    if (localStorageProjectsString) {
      const localStorageProjectsObject = JSON.parse(localStorageProjectsString);
      setRoadmapProjectUuids(localStorageProjectsObject);
      if (localStorageProjectsObject) {
        fetchRoadmapData(localStorageProjectsObject);
      }
    } else {
      setIsLoading(false);
    }
  };

  const closeSelectProjectsModal = (): void => {
    setShowAddProjectsModal(false);
    fetchAndSetRoadmapProjectUuids();
  };

  // Listen to the custom pushState change event we fire when the user selects EditDates & keep react-router in sync
  useCustomEventListener("pushState", () => {
    navigate(window.location.pathname + window.location.search, { replace: true });
  });

  useEffect(() => {
    const projectUuid = searchParams.get("projectUuid");
    if (projectUuid) {
      setEditDatesPanelData(data.filter((project) => project.uuid === projectUuid)[0]);
      setShowEditDatesPanel(true);
    }
  }, [searchParams, JSON.stringify(data)]);

  useEffect(() => {
    if (editDatesPanelData) {
      setData((projectRoadmapData) => {
        return projectRoadmapData.map((project) => {
          if (project.uuid === editDatesPanelData.uuid && project.rowVersion !== editDatesPanelData.rowVersion) {
            return {
              ...project,
              dates: editDatesPanelData.dates,
              rowVersion: editDatesPanelData.rowVersion,
            };
          }
          return project;
        });
      });
    }
  }, [JSON.stringify(editDatesPanelData)]);

  useEffect(() => {
    fetchAndSetRoadmapProjectUuids();
    fetchSelectData();
  }, []);

  return {
    errors,
    data,
    projectsMultiSelectData,

    editDatesPanelData,
    setEditDatesPanelData,
    showEditDatesPanel,
    closeEditDatesPanel,

    roadmapProjectUuids,
    setRoadmapProjectUuids,

    showAddProjectsModal,
    setShowAddProjectsModal,
    closeSelectProjectsModal,

    isLoading,
    setIsLoading,

    onSaveProjectsSelectData,
    fetchSelectData,
  };
};
