import { Dispatch, SetStateAction, useState } from "react";

import { DiscussionVisibilityConstants, OrganisationTypeConstants } from "../../../../../constants";
import { useActivityWizardContextSwitcher } from "../../../../../route/shared/utils/activities/useActivityWizardContextSwitcher";
import { createDiscussionThread } from "../../../../../service/interaction";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserFullName, useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../general";
import { ProfileMenuItemType } from "../../../../navigation";
import { Thread } from "../../../models";

export type NewThreadInformation = {
  componentKey: string | null;
  dataPath: string | null;
  messageObjectType: string;
  messageObjectUuid: string;
  messageSourceOrganisationUuid: string;
  messageType: string;
  threadObjectUuid: string;
};

interface useNewDiscussionProps {
  threads: Thread[];
  setThreads: Dispatch<SetStateAction<Thread[]>>;
  newThreadInformation: NewThreadInformation;
  internalTeamSelected: boolean;
  externalUserType: string;
  handleInternalTeamSelect: () => void;
  handleExternalSelect: () => void;
}

interface useNewDiscussionReturnData {
  errors: ServiceError[] | undefined;
  showMessageDropdown: boolean;
  messageDropdownOptions: ProfileMenuItemType[];
  setShowMessageDropdown: Dispatch<SetStateAction<boolean>>;
  newMessage: string;
  setNewMessage: Dispatch<SetStateAction<string>>;
  handleSendDiscussion: () => void;
  handleNewInternalDiscussion: () => void;
  firstStepCompleted: boolean;
  setFirstStepCompleted: Dispatch<SetStateAction<boolean>>;
  secondStepCompleted: boolean;
  isHandleSendDiscussionLoading: boolean;
}

export const useNewDiscussion = ({
  threads,
  setThreads,
  newThreadInformation,
  internalTeamSelected,
  externalUserType,
  handleInternalTeamSelect,
  handleExternalSelect,
}: useNewDiscussionProps): useNewDiscussionReturnData => {
  const { user, currentUserType } = useAuth();

  const { activityHistoryRowVersion, setActivityHistoryRowVersion } = useActivityWizardContextSwitcher();

  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [showMessageDropdown, setShowMessageDropdown] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>("");

  const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false);
  const [secondStepCompleted, setSecondStepCompleted] = useState<boolean>(false);
  const [isHandleSendDiscussionLoading, setIsHandleSendDiscussionLoading] = useState(false);

  const visibility = internalTeamSelected
    ? DiscussionVisibilityConstants.INTERNAL
    : DiscussionVisibilityConstants.EXTERNAL;

  const internalMessageTargetOrganisationRoles =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? ["Developer", "Guest - Read", "Guest - Write"]
      : ["Validator"];

  const messageDropdownOptions: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Internal Team",
      action: () => {
        handleInternalTeamSelect();
        setShowMessageDropdown(false);
        setFirstStepCompleted(true);
      },
    },
    {
      id: 2,
      value: externalUserType,
      action: () => {
        handleExternalSelect();
        setShowMessageDropdown(false);
        setFirstStepCompleted(true);
      },
    },
  ];

  const handleNewInternalDiscussion = (): void => {
    handleInternalTeamSelect();
    setFirstStepCompleted(true);
  };

  const handleSendDiscussion = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await createDiscussionThread({
      componentKey: newThreadInformation.componentKey,
      dataPath: newThreadInformation.dataPath,
      messageContent: newMessage,
      messageObjectType: newThreadInformation.messageObjectType,
      messageObjectUuid: newThreadInformation.messageObjectUuid,
      messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
      messageType: newThreadInformation.messageType,
      messageStatus:
        currentUserType === OrganisationTypeConstants.VERIFIER && internalTeamSelected ? "Published" : "Draft",
      threadObjectType: "Activity",
      threadObjectUuid: newThreadInformation.threadObjectUuid,
      threadType: "Discussion",
      visibility,
      messageTargetOrganisationRoles: internalTeamSelected
        ? internalMessageTargetOrganisationRoles
        : ["Developer", "Validator", "Guest - Read", "Guest - Write"],
    });

    if (res.status === Status.Success && res.data && user) {
      setThreads(
        threads.concat([
          {
            uuid: res.data.threadUuid,
            resolved: false,
            hasNonDeletedMessagesCreatedByOtherUsers: false,
            messages: [
              {
                uuid: res.data.messageUuid,
                content: newMessage,
                createdAt: new Date(),
                isRead: false,
                status:
                  currentUserType === OrganisationTypeConstants.VERIFIER && internalTeamSelected
                    ? "Published"
                    : "Draft",
                messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
                createdByUserUuid: user.userUuid,
                createdByUserFullName: getUserFullName(user.fullName, user.firstName, user.lastName),
                createdByUserAvatarUrl: user.avatar?.url,
                rowVersion: 1,
              },
            ],
          },
        ])
      );

      if (activityHistoryRowVersion && setActivityHistoryRowVersion)
        setActivityHistoryRowVersion(activityHistoryRowVersion + 1);

      setSecondStepCompleted(true);

      Toast.success({ message: "Discussion added" });
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSendDiscussionLoading);

  return {
    errors,
    showMessageDropdown,
    messageDropdownOptions,
    setShowMessageDropdown,
    newMessage,
    setNewMessage,
    handleSendDiscussion,
    handleNewInternalDiscussion,
    firstStepCompleted,
    setFirstStepCompleted,
    secondStepCompleted,
    isHandleSendDiscussionLoading,
  };
};
