import { ProjectRoadmapData } from "../../../../models";
import { calculateApproximateYearsAndMonthsBetween, convertDateToStringDDMMYYYY } from "../../../../utils";
import { ProjectRoadmapRowLabelData, ViewMode } from "../models";
import { ProjectRoadmapColourMap } from "../models/projectRoadmapConstants";

export const getFormatFromViewMode = (viewMode: ViewMode): string => {
  switch (viewMode) {
    case ViewMode.Months3:
      return "yyyy\nMMM-dd";
    case ViewMode.Months6:
    case ViewMode.Year:
      return "yyyy\nMMM";
    case ViewMode.Years3:
      return "yyyy\nQQ";
    case ViewMode.Years10:
    case ViewMode.Years20:
    default:
      return "yyyy";
  }
};

export const getProjectRoadmapRowLabelData = (data: ProjectRoadmapData[]): ProjectRoadmapRowLabelData => {
  return data.map((x) => ({
    displayName: x.displayName,
    uuid: x.uuid,
    status: x.currentStatus,
    additionalDates:
      x.dates.design?.registrationDate != null ||
      x.dates.implementation?.firstAssessmentDate != null ||
      x.dates.implementation?.implementationDate != null ||
      x.dates.implementation?.startDate != null ||
      x.dates.ongoingMonitoring?.secondAssessmentDate != null ||
      x.dates.ongoingMonitoring?.finalAssessmentDate != null,
  }));
};

const getCustomTooltipContent = (
  phase: string | undefined,
  project: string | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined,
  projectUuid: string | undefined
): string => {
  const duration =
    startDate && endDate && startDate.getTime() !== endDate.getTime()
      ? calculateApproximateYearsAndMonthsBetween(startDate, endDate)
      : undefined;

  return `<div class="CustomTooltip">
            <div class="TooltipHeader">
              <p class="body2">
                <b>${project}</b>  
              </p>
            </div>
            <div class="TooltipContent">
              <p class="body2">
                <b>${phase}</b>
              </p>
              ${
                !duration && !!startDate
                  ? `<p class="body2">
                ${convertDateToStringDDMMYYYY(startDate)}      
              </p>`
                  : ""
              }  
              ${
                !!duration && !!startDate && !!endDate
                  ? `<p class="body2">
                <b>Start/end:</b> ${convertDateToStringDDMMYYYY(startDate)} - ${convertDateToStringDDMMYYYY(endDate)}       
              </p>`
                  : ""
              }
              ${
                (!!duration?.years && duration?.years > 0) || (!!duration?.months && duration?.months > 0)
                  ? `<p class="body2">
                        <b>Duration:</b> ${duration.years > 0 ? `${duration.years} year${duration.years > 1 ? "s" : ""} ` : ""}${duration.months > 0 ? `${duration.months} month${duration.months > 1 ? "s" : ""}` : ""}
                      </p>`
                  : ""
              }
              ${
                projectUuid
                  ? `<div class="EditDatesButton" 
                          onclick="history.pushState(null,'','${window.location.pathname}?projectUuid=${projectUuid}');
                                   window.dispatchEvent(new Event('pushState'));"
                     >
                      <p class="body2"}>
                        Edit dates
                      </p>
                    </div>`
                  : ""
              }
            </div>
          </div>`;
};
// onclick=history.pushState(null,"","${projectUuid}")
// ${window.location.pathname}?projectUuid=${projectUuid}
export const getProjectRoadmapTimelineData = (data: ProjectRoadmapData[]): unknown[] => {
  const format: unknown[] = [
    [
      { type: "string", id: "Project" },
      { type: "string", id: "dummy bar label" },
      { type: "string", role: "tooltip" },
      { type: "string", role: "style" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ],
  ];

  const createRow = (
    displayName: string | undefined,
    phase: string,
    tooltip: string | undefined,
    colorMap: string,
    startDate: Date | undefined,
    endDate: Date | undefined
  ): (string | Date | undefined)[] | undefined =>
    startDate && endDate ? [displayName, phase, tooltip, colorMap, startDate, endDate] : undefined;

  const rows = data.map((x) =>
    [
      createRow(
        x.displayName,
        "Feasibility",
        getCustomTooltipContent(
          "Feasibility",
          x.displayName,
          x.dates.feasibility?.start,
          x.dates.feasibility?.end,
          x.uuid
        ),
        ProjectRoadmapColourMap.Feasibility,
        x.dates.feasibility?.start,
        x.dates.feasibility?.end
      ),
      createRow(
        x.displayName,
        "Design",
        getCustomTooltipContent("Design", x.displayName, x.dates.design?.start, x.dates.design?.end, x.uuid),
        ProjectRoadmapColourMap.Design,
        x.dates.design?.start,
        x.dates.design?.end
      ),
      createRow(
        x.displayName,
        "Registration date",
        getCustomTooltipContent(
          "Registration date",
          x.displayName,
          x.dates.design?.registrationDate,
          x.dates.design?.registrationDate,
          x.uuid
        ),
        ProjectRoadmapColourMap["Registration date"],
        x.dates.design?.registrationDate,
        x.dates.design?.registrationDate
      ),
      createRow(
        x.displayName,
        "Implementation",
        getCustomTooltipContent(
          "Implementation",
          x.displayName,
          x.dates.implementation?.start,
          x.dates.implementation?.end,
          x.uuid
        ),
        ProjectRoadmapColourMap.Implementation,
        x.dates.implementation?.start,
        x.dates.implementation?.end
      ),
      createRow(
        x.displayName,
        "Start date",
        getCustomTooltipContent(
          "Start date",
          x.displayName,
          x.dates.implementation?.startDate,
          x.dates.implementation?.startDate,
          x.uuid
        ),
        ProjectRoadmapColourMap["Start date"],
        x.dates.implementation?.startDate,
        x.dates.implementation?.startDate
      ),
      createRow(
        x.displayName,
        "Implementation date",
        getCustomTooltipContent(
          "Implementation date",
          x.displayName,
          x.dates.implementation?.implementationDate,
          x.dates.implementation?.implementationDate,
          x.uuid
        ),
        ProjectRoadmapColourMap["Implementation date"],
        x.dates.implementation?.implementationDate,
        x.dates.implementation?.implementationDate
      ),
      createRow(
        x.displayName,
        "First assessment date",
        getCustomTooltipContent(
          "First assessment date",
          x.displayName,
          x.dates.implementation?.firstAssessmentDate,
          x.dates.implementation?.firstAssessmentDate,
          x.uuid
        ),
        ProjectRoadmapColourMap["First assessment date"],
        x.dates.implementation?.firstAssessmentDate,
        x.dates.implementation?.firstAssessmentDate
      ),
      createRow(
        x.displayName,
        "Ongoing monitoring",
        getCustomTooltipContent(
          "Ongoing monitoring",
          x.displayName,
          x.dates.ongoingMonitoring?.start,
          x.dates.ongoingMonitoring?.end,
          x.uuid
        ),
        ProjectRoadmapColourMap["Ongoing monitoring"],
        x.dates.ongoingMonitoring?.start,
        x.dates.ongoingMonitoring?.end
      ),
      createRow(
        x.displayName,
        "Second assessment date",
        getCustomTooltipContent(
          "Second assessment date",
          x.displayName,
          x.dates.ongoingMonitoring?.secondAssessmentDate,
          x.dates.ongoingMonitoring?.secondAssessmentDate,
          x.uuid
        ),
        ProjectRoadmapColourMap["Second assessment date"],
        x.dates.ongoingMonitoring?.secondAssessmentDate,
        x.dates.ongoingMonitoring?.secondAssessmentDate
      ),
      createRow(
        x.displayName,
        "Final assessment date",
        getCustomTooltipContent(
          "Final assessment date",
          x.displayName,
          x.dates.ongoingMonitoring?.finalAssessmentDate,
          x.dates.ongoingMonitoring?.finalAssessmentDate,
          x.uuid
        ),
        ProjectRoadmapColourMap["Final assessment date"],
        x.dates.ongoingMonitoring?.finalAssessmentDate,
        x.dates.ongoingMonitoring?.finalAssessmentDate
      ),
    ].filter(Boolean)
  );

  format.push(...rows.flat(1));

  return format;
};
