import "./MapControl.css";

import { Dispatch, SetStateAction, useState } from "react";

import { ArrowDownDarkIcon } from "../../../../assets";
import { SelectData } from "../../../../models/shared";
import { Button, FormLabel, Select, Toggle } from "../../../forms";

interface MapControlProps {
  is3d: boolean;
  onChange3dView: (value: boolean) => void;
  onChangeLayer: (value: string) => void;
  selectedLayer: MapLayerOptions;
  disableSelection?: boolean;
  cleanShape: () => void;
  setIsToDrawBoundary: Dispatch<SetStateAction<boolean>>;
  setIsToUploadManually: Dispatch<SetStateAction<boolean>>;
  setIsToSaveBoundary: Dispatch<SetStateAction<boolean>>;
}

export enum MapLayerOptions {
  BASE = "Site boundary",
  LAND_CLASSIFICATION = "Land Classification",
  VEG_LAI = "Canopy density",
  VEG_NDNI = "Canopy growth rate",
  VEG_PSRI = "Plant stress monitoring",
  VEG_NDVI = "Vegetation vigour",
  Water_NDMI = "Moisture monitoring",
  Water_MNDWI = "Water and flood monitoring",
  FIRE_RISK = "Fire Detection",
  TREE_CARBON = "Tree Carbon",
}

export const MapControl = ({
  is3d,
  onChange3dView,
  onChangeLayer,
  selectedLayer,
  disableSelection,
  cleanShape,
  setIsToDrawBoundary,
  setIsToUploadManually,
  setIsToSaveBoundary,
}: MapControlProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDrawOption, setSelectedDrawOption] = useState("");

  const toggleExpand = (): void => {
    setIsExpanded((prev) => !prev);
  };

  const selectData: SelectData = Object.values(MapLayerOptions).map((option) => ({
    key: option,
    value: option,
  }));

  const collapseDrawOptions = (): void => {
    setSelectedDrawOption("");
    setIsToDrawBoundary(false);
  };

  const collapseLayerOptions = (): void => {
    setIsExpanded(false);
  };

  return (
    <>
      <div className={`map-expandable-control ${isExpanded ? "expanded" : ""}`}>
        {/* Top-left icon to toggle options */}
        <button
          className="menu-toggle"
          onClick={() => {
            toggleExpand();
            collapseDrawOptions();
          }}
          aria-expanded={isExpanded}
          aria-label="Toggle options menu"
        >
          <span className="menu-icon">☰</span>
        </button>

        {/* Options Panel */}
        {isExpanded && (
          <div className="control-panel">
            <div className="control-section">
              <div className="control-toggle-view">
                <FormLabel label="Views" labelAlignment="left">
                  <Toggle label="Enable 3D terrain" onChange={onChange3dView} value={is3d} />
                </FormLabel>
              </div>
              <div className="control-divider" />
              <div className="control-selection-layers">
                <Select
                  className="layer-select"
                  labelAlignment="left"
                  label="Layers"
                  data={selectData}
                  value={selectedLayer}
                  onChange={onChangeLayer}
                  disabled={disableSelection}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="extendable-draw-menu">
        <Button
          className="menu-header"
          size="small"
          text="Edit location"
          onClick={() => {
            collapseLayerOptions();
            setSelectedDrawOption((prev) => {
              if (prev) {
                setIsToDrawBoundary(false);
                return "";
              }
              return "edit";
            });

            setIsToUploadManually(false);
          }}
          variant="primary"
          icon={<ArrowDownDarkIcon />}
        />
        <div className={`menu-options ${selectedDrawOption ? "expanded" : ""}`}>
          {selectedDrawOption === "edit" && (
            <>
              <div className="menu-option">
                <Button
                  text="Upload shape file"
                  fullWidth
                  size="small"
                  onClick={() => {
                    setIsToUploadManually(true);
                  }}
                />
              </div>
              <div className="menu-option">
                <Button
                  size="small"
                  fullWidth
                  text="Draw project boundary"
                  onClick={() => {
                    cleanShape();
                    setIsToDrawBoundary(true);
                    setSelectedDrawOption("draw");
                  }}
                />
              </div>
            </>
          )}
          {selectedDrawOption === "draw" && (
            <>
              <div className="menu-option">
                <Button
                  size="small"
                  fullWidth
                  text="Save and Submit"
                  onClick={() => {
                    setSelectedDrawOption("");
                    setIsToSaveBoundary(true);
                  }}
                />
              </div>
              <div className="menu-option">
                <Button
                  size="small"
                  fullWidth
                  text="Clear"
                  onClick={() => {
                    cleanShape();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
