import { ProjectDates, SelectData } from "../../../models";
import { convertDateToStringDDMMYYYY } from "../../../utils";
import {
  Column,
  DatePicker,
  Divider,
  InformationBox,
  KanaTooltip,
  ReadonlyText,
  Row,
  Select,
  TextInput,
} from "../../../widget";
import { StaticDates } from "../models";

interface DateSectionProps {
  completed?: boolean; // This will be introduced with workflows and tasks
  dates: ProjectDates;
  handleUpdateProjectDates: (date: Date | number | undefined, key: string) => void;
}
const START_LABEL = "Phase start date";
const END_LABEL = "Phase end date";
export const FeasibilitySection = ({
  completed = false,
  dates,
  handleUpdateProjectDates,
}: DateSectionProps): JSX.Element => {
  const START_TOOLTIP_TEXT = "When the idea of the project is first created";
  const END_TOOLTIP_TEXT = "When the idea of the project is first created";

  return (
    <>
      <Row spacingV="m">
        <Column span={12}>
          <h2>Feasibility</h2>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={12}>
          <p className="body1">Evaluating the financial feasibility of a project before registration</p>
        </Column>
      </Row>
      {completed ? (
        <>
          <Row spacingV="xl">
            <Column span="4_half">
              <ReadonlyText
                label={START_LABEL}
                labelJustify="SpaceBetween"
                value={dates.feasibility?.start ? convertDateToStringDDMMYYYY(dates.feasibility?.start) : "DD/MM/YYYY"}
                tooltipText={START_TOOLTIP_TEXT}
                tooltipHeader=""
              />
            </Column>
            <Column offset="1_half" span="4_half">
              <ReadonlyText
                label={END_LABEL}
                labelJustify="SpaceBetween"
                value={dates.feasibility?.end ? convertDateToStringDDMMYYYY(dates.feasibility?.end) : "DD/MM/YYYY"}
                tooltipText={END_TOOLTIP_TEXT}
                tooltipHeader=""
              />
            </Column>
          </Row>
          <Row spacingV="xl">
            <Column span={12}>
              <InformationBox variant="success" title="You have completed this phase" />
            </Column>
          </Row>
        </>
      ) : (
        <Row spacingV="xl">
          <Column span="4_half">
            <DatePicker
              label={START_LABEL}
              value={dates.feasibility?.start}
              onChange={(date) => handleUpdateProjectDates(date, "feasibility.start")}
              tooltip={<KanaTooltip tooltipHeader="" tooltipText="When the idea of the project is first created" />}
            />
          </Column>
          <Column offset="1_half" span="4_half">
            <DatePicker
              label={END_LABEL}
              value={dates.feasibility?.end}
              onChange={(date) => handleUpdateProjectDates(date, "feasibility.end")}
              tooltip={
                <KanaTooltip
                  tooltipHeader=""
                  tooltipText="When the project is deemed feasible and the design process begins"
                />
              }
            />
          </Column>
        </Row>
      )}
      <Row spacingV="xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
    </>
  );
};

interface DesignSectionProps extends DateSectionProps {
  staticDates: StaticDates | undefined;
}
export const DesignSection = ({
  completed = false,
  dates,
  staticDates,
  handleUpdateProjectDates,
}: DesignSectionProps): JSX.Element => {
  const START_TOOLTIP_TEXT = "When the project is deemed feasible and the design process begins";
  const END_TOOLTIP_TEXT =
    "When the design plans of the project are implemented, usually marking the beginning of site preparation";
  const REGISTRATION_DATE_TOOLTIP_TEXT = "The date the project was registered with the external registry";
  return (
    <>
      <Row spacingV="m">
        <Column span={12}>
          <h2>Design</h2>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={12}>
          <p className="body1">Designing how the project will be delivered according to the chosen methodology</p>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span="4_half">
          <ReadonlyText
            label={START_LABEL}
            labelJustify="SpaceBetween"
            value={staticDates?.designStart ? convertDateToStringDDMMYYYY(staticDates?.designStart) : "DD/MM/YYYY"}
            tooltipText={START_TOOLTIP_TEXT}
            tooltipHeader=""
          />
        </Column>
        <Column offset="1_half" span="4_half">
          {completed ? (
            <ReadonlyText
              label={END_LABEL}
              labelJustify="SpaceBetween"
              value={dates.design?.end ? convertDateToStringDDMMYYYY(dates.design?.end) : "DD/MM/YYYY"}
              tooltipText={END_TOOLTIP_TEXT}
              tooltipHeader=""
            />
          ) : (
            <DatePicker
              label={END_LABEL}
              value={dates.design?.end}
              onChange={(date) => handleUpdateProjectDates(date, "design.end")}
              tooltip={<KanaTooltip tooltipHeader="" tooltipText={END_TOOLTIP_TEXT} />}
            />
          )}
        </Column>
      </Row>
      <Row spacingV="xl">
        <Column span="4_half">
          <DatePicker
            label="Registration date"
            value={dates.design?.registrationDate}
            onChange={(date) => handleUpdateProjectDates(date, "design.registrationDate")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={REGISTRATION_DATE_TOOLTIP_TEXT} />}
          />
        </Column>
      </Row>
      {completed && (
        <Row spacingV="xl">
          <Column span={12}>
            <InformationBox variant="success" title="You have completed this phase" />
          </Column>
        </Row>
      )}
      <Row spacingV="xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
    </>
  );
};

interface ImplementationSectionProps extends DateSectionProps {
  staticDates: StaticDates | undefined;
}
export const ImplementationSection = ({
  completed = false,
  dates,
  staticDates,
  handleUpdateProjectDates,
}: ImplementationSectionProps): JSX.Element => {
  const START_TOOLTIP_TEXT =
    "When the design plans of the project are implemented, usually marking the beginning of site preparation";
  const END_TOOLTIP_TEXT =
    "When the implementation of the project is completed, usually marked by successful validation";
  const IMPLEMENTATION_DATE_TOOLTIP_TEXT = "The date project activities started on site";
  const START_DATE_TOOLTIP_TEXT = "The date project activities are finished on site";
  const FIRST_ASSESSMENT_DATE_TOOLTIP_TEXT =
    "The first verification of the project, ensuring the activities on the project site meet the requirements of the standard";
  const DURATION_TOOLTIP_TEXT = "The total length of the project lifetime in years";

  return (
    <>
      <Row spacingV="m">
        <Column span={12}>
          <h2>Implementation</h2>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={12}>
          <p className="body1">
            Implementing the project design by carrying out the work on site in preparation for validation
          </p>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span="4_half">
          <ReadonlyText
            label={START_LABEL}
            labelJustify="SpaceBetween"
            value={
              staticDates?.implementationStart
                ? convertDateToStringDDMMYYYY(staticDates?.implementationStart)
                : "DD/MM/YYYY"
            }
            tooltipText={START_TOOLTIP_TEXT}
            tooltipHeader=""
          />
        </Column>
        <Column offset="1_half" span="4_half">
          {completed ? (
            <ReadonlyText
              label={END_LABEL}
              labelJustify="SpaceBetween"
              value={dates.implementation?.end ? convertDateToStringDDMMYYYY(dates.implementation?.end) : "DD/MM/YYYY"}
              tooltipText={END_TOOLTIP_TEXT}
              tooltipHeader=""
            />
          ) : (
            <DatePicker
              label={END_LABEL}
              value={dates.implementation?.end}
              onChange={(date) => handleUpdateProjectDates(date, "implementation.end")}
              tooltip={<KanaTooltip tooltipHeader="" tooltipText={END_TOOLTIP_TEXT} />}
            />
          )}
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span="4_half">
          <DatePicker
            label="Implementation date"
            value={dates.implementation?.implementationDate}
            onChange={(date) => handleUpdateProjectDates(date, "implementation.implementationDate")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={IMPLEMENTATION_DATE_TOOLTIP_TEXT} />}
          />
        </Column>
        <Column offset="1_half" span="4_half">
          <DatePicker
            label="Start date"
            value={dates.implementation?.startDate}
            onChange={(date) => handleUpdateProjectDates(date, "implementation.startDate")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={START_DATE_TOOLTIP_TEXT} />}
          />
        </Column>
      </Row>
      <Row spacingV="xl">
        <Column span="4_half">
          <DatePicker
            label="First assessment date"
            value={dates.implementation?.firstAssessmentDate}
            onChange={(date) => handleUpdateProjectDates(date, "implementation.firstAssessmentDate")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={FIRST_ASSESSMENT_DATE_TOOLTIP_TEXT} />}
          />
        </Column>
        <Column offset="1_half" span="4_half">
          <TextInput
            label="Project duration"
            name="Project duration"
            value={dates.duration?.duration?.toString()}
            onChange={(date) => handleUpdateProjectDates(Number(date), "duration.duration")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={DURATION_TOOLTIP_TEXT} />}
          />
        </Column>
      </Row>
      {completed && (
        <Row spacingV="xl">
          <Column span={12}>
            <InformationBox variant="success" title="You have completed this phase" />
          </Column>
        </Row>
      )}
      <Row spacingV="xl">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
    </>
  );
};

interface OngoingMonitoringSectionProps extends DateSectionProps {
  staticDates: StaticDates | undefined;
  subsequentFrequencySelectData: SelectData;
}
export const OngoingMonitoringSection = ({
  completed = false,
  dates,
  staticDates,
  subsequentFrequencySelectData,
  handleUpdateProjectDates,
}: OngoingMonitoringSectionProps): JSX.Element => {
  const START_TOOLTIP_TEXT = "The beginning of the ongoing monitoring period, marked by the first validation";
  const END_TOOLTIP_TEXT =
    "This is the project's end date, usually calculated by adding the project duration to the project start date";
  const SECOND_ASSESSMENT_DATE_TOOLTIP_TEXT =
    "The date of the next project assessment after validation, usually called 'Verification'";
  const FINAL_ASSESSMENT_DATE_TOOLTIP_TEXT =
    "The date of the final project assessment, making the last vintage of the project's units";
  const SUBSEQUENT_FREQUENCY_TOOLTIP_TEXT =
    "The frequency of the project assessment dates happening after the second assessment";

  return (
    <>
      <Row spacingV="m">
        <Column span={12}>
          <h2>Ongoing monitoring</h2>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span={12}>
          <p className="body1">
            The periodic monitoring and reporting until the project end date to verify the project is delivering as
            planned
          </p>
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span="4_half">
          <ReadonlyText
            label={START_LABEL}
            labelJustify="SpaceBetween"
            value={
              staticDates?.ongoingMonitoringStart
                ? convertDateToStringDDMMYYYY(staticDates?.ongoingMonitoringStart)
                : "DD/MM/YYYY"
            }
            tooltipText={START_TOOLTIP_TEXT}
            tooltipHeader=""
          />
        </Column>
        <Column offset="1_half" span="4_half">
          {completed ? (
            <ReadonlyText
              label={END_LABEL}
              labelJustify="SpaceBetween"
              value={
                dates.ongoingMonitoring?.end ? convertDateToStringDDMMYYYY(dates.ongoingMonitoring?.end) : "DD/MM/YYYY"
              }
              tooltipText={END_TOOLTIP_TEXT}
              tooltipHeader=""
            />
          ) : (
            <DatePicker
              label={END_LABEL}
              value={dates.ongoingMonitoring?.end}
              onChange={(date) => handleUpdateProjectDates(date, "ongoingMonitoring.end")}
              tooltip={<KanaTooltip tooltipHeader="" tooltipText={END_TOOLTIP_TEXT} />}
            />
          )}
        </Column>
      </Row>
      <Row spacingV="l">
        <Column span="4_half">
          <DatePicker
            label="Second assessment date"
            value={dates.ongoingMonitoring?.secondAssessmentDate}
            onChange={(date) => handleUpdateProjectDates(date, "ongoingMonitoring.secondAssessmentDate")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={SECOND_ASSESSMENT_DATE_TOOLTIP_TEXT} />}
          />
        </Column>
        <Column offset="1_half" span="4_half">
          <DatePicker
            label="Final assessment date"
            value={dates.ongoingMonitoring?.finalAssessmentDate}
            onChange={(date) => handleUpdateProjectDates(date, "ongoingMonitoring.finalAssessmentDate")}
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={FINAL_ASSESSMENT_DATE_TOOLTIP_TEXT} />}
          />
        </Column>
      </Row>
      <Row spacingV="xl">
        <Column span="4_half">
          <Select
            label="Subsequent frequency"
            value={
              dates.ongoingMonitoring?.subsequentFrequency
                ? (subsequentFrequencySelectData.filter(
                    (e) => e.key === dates.ongoingMonitoring?.subsequentFrequency
                  )[0].value as string)
                : undefined
            }
            data={subsequentFrequencySelectData}
            onChange={(value) =>
              handleUpdateProjectDates(value === "5-yearly" ? 5 : 10, "ongoingMonitoring.subsequentFrequency")
            }
            tooltip={<KanaTooltip tooltipHeader="" tooltipText={SUBSEQUENT_FREQUENCY_TOOLTIP_TEXT} />}
          />
        </Column>
      </Row>
      {completed && (
        <Row spacingV="xl">
          <Column span={12}>
            <InformationBox variant="success" title="You have completed this phase" />
          </Column>
        </Row>
      )}
    </>
  );
};
