import "./UploadLocationDocumentModal.css";

import { ServiceError } from "../../../../../../../service/Shared";
import { ActionButtons, Column, ErrorBox, Modal, Row, SingleFileUpload, TextInput } from "../../../../../../../widget";
import { useUploadLocationDocumentModal } from "./useUploadLocationDocumentModal";

interface UploadLocationDocumentModalProps {
  show: boolean;
  closeModal: () => void;
  saveBoundaryFile: (boundaryFile: File) => void;
}

export const UploadLocationDocumentModal = ({
  show,
  closeModal,
  saveBoundaryFile,
}: UploadLocationDocumentModalProps): JSX.Element => {
  const {
    fileUploadRef,
    maxFileSize,
    selectedDocumentType,
    selectedDocumentVariant,
    isHandleSubmitLoading,
    setFiles,
    handleCancel,
    handleSubmit,
    errors,
  } = useUploadLocationDocumentModal({
    closeModal,
    saveBoundaryFile,
  });

  return (
    <div className="UploadLocationDocumentModal">
      <Modal show={show} title="Upload document" onClose={handleCancel}>
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error: ServiceError) => error.message)}
              />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              <TextInput name="Document type" label="Document type" disabled value={selectedDocumentType} />
            </Column>
          </Row>
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              <TextInput name="Variant" label="Variant" disabled value={selectedDocumentVariant} />
            </Column>
          </Row>
          <Row spacingV="l" justify="center">
            <Column span={6} hasNoPadding>
              <div className="ModalFileUpload">
                <SingleFileUpload
                  ref={fileUploadRef}
                  label="Upload document"
                  maxFileSize={maxFileSize}
                  onChange={(e) => setFiles([e])}
                />
              </div>
            </Column>
          </Row>
          <Row justify="center">
            <Column span={6} hasNoPadding>
              <ActionButtons
                submitButtonLabel="Upload document"
                isLoading={isHandleSubmitLoading}
                onCancel={handleCancel}
                fullWidth
              />
            </Column>
          </Row>
        </form>
      </Modal>
    </div>
  );
};
