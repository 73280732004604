import { useCallback, useEffect, useMemo, useState } from "react";

import { UserType } from "../../../constants";
import { TabsList } from "../../../models";
import { getProjectAggregate } from "../../../service/query";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { getProjectRoadmapRoute, getProjectTrackerRoute } from "../../../utils/routes";
import { ChartData } from "../../../widget";

interface UseProjectsReturnData {
  tabsList: TabsList;
  phaseChartData: ChartData;
  isLoading: boolean;
  currentUserType: UserType;
}
export const useProjects = (): UseProjectsReturnData => {
  const { currentUserType, currentDeveloperUuid, currentDevelopmentManagerUuid } = useAuth();

  const [phaseChartData, setPhaseChartData] = useState<ChartData>({});
  const [isLoading, setIsLoading] = useState(true);

  const tabsList = [
    {
      label: "Project tracker",
      route: getProjectTrackerRoute(currentUserType),
    },
    {
      label: "Project roadmap",
      route: getProjectRoadmapRoute(currentUserType),
    },
  ];

  const { filterBy, filterValue } = useMemo(() => {
    if (currentDeveloperUuid) {
      return {
        filterBy: "developer",
        filterValue: currentDeveloperUuid,
      };
    }
    if (currentDevelopmentManagerUuid) {
      return {
        filterBy: "developmentManager",
        filterValue: currentDevelopmentManagerUuid,
      };
    }
    throw new Error("Organisation not currently linked to a developer or development manager");
  }, [currentDeveloperUuid, currentDevelopmentManagerUuid]);

  const fetchData = useCallback(async () => {
    const phaseAggregateResponse = await getProjectAggregate({
      filterBy,
      filterOperator: "eq",
      filterValue,
      groupBy: "cachedPhase",
      aggregation: "count",
      aggregate: "id",
    });

    if (phaseAggregateResponse.status === Status.Success && phaseAggregateResponse.data) {
      const phaseAggregateData = phaseAggregateResponse.data.reduce<ChartData>((previous, current) => {
        // eslint-disable-next-line no-param-reassign
        previous[current.key] = current.value;
        return previous;
      }, {});

      // If no data initialise with 0
      phaseAggregateData.Feasibility = phaseAggregateData.Feasibility ?? 0;
      phaseAggregateData.Design = phaseAggregateData.Design ?? 0;
      phaseAggregateData.Implementation = phaseAggregateData.Implementation ?? 0;
      phaseAggregateData["Ongoing monitoring"] = phaseAggregateData["Ongoing Monitoring"] ?? 0;

      // Remove old Ongoing Monitoring property
      if (phaseAggregateData["Ongoing Monitoring"]) {
        delete phaseAggregateData["Ongoing Monitoring"];
      }
      // Remove unwanted completed property
      if (phaseAggregateData.Completed) {
        delete phaseAggregateData.Completed;
      }
      // Rename null -> "Missing dates"
      if (phaseAggregateData.null) {
        phaseAggregateData["Missing dates"] = phaseAggregateData.null;
        delete phaseAggregateData.null;
      }

      setPhaseChartData(phaseAggregateData);
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => {
      setIsLoading(false);
    });
  }, [fetchData]);

  return {
    tabsList,
    phaseChartData,
    isLoading,
    currentUserType,
  };
};
