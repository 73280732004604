import { Dispatch, FormEvent, ForwardedRef, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { FileUploadHandle } from "../../../../../../../models";
import {
  GetProjectDetailsResponse,
  searchProjectDocumentTypes,
  searchProjectDocumentTypeVariants,
} from "../../../../../../../service/query";
import { ServiceError } from "../../../../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../../../../utils";
import { dataGridMapFilterCriteria } from "../../../../../../../widget";
import { ProjectContext } from "../../../ProjectContext";

interface UseUploadLocationDocumentModalProps {
  closeModal: () => void;
  saveBoundaryFile: (boundaryFile: File) => void;
}

interface UseUploadLocationDocumentModalReturnData {
  fileUploadRef: ForwardedRef<FileUploadHandle>;
  projectDetails: GetProjectDetailsResponse | undefined;
  maxFileSize?: number;
  selectedDocumentVariant?: string;
  selectedDocumentType?: string;
  isHandleSubmitLoading: boolean;
  setFiles: Dispatch<SetStateAction<File[] | undefined>>;
  handleCancel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  errors: ServiceError[];
}

export const useUploadLocationDocumentModal = ({
  closeModal,
  saveBoundaryFile,
}: UseUploadLocationDocumentModalProps): UseUploadLocationDocumentModalReturnData => {
  const fileUploadRef = useRef<FileUploadHandle>(null);

  const { projectDetails } = useContext(ProjectContext);
  const [errors, setErrors] = useState<ServiceError[]>([]);
  const maxFileSize = 20; // 20mb

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>();
  const [selectedDocumentVariant, setSelectedDocumentVariant] = useState<string>();
  const [files, setFiles] = useState<File[]>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleCancel = (): void => {
    if (fileUploadRef.current) fileUploadRef.current.clearInput();
    setSelectedDocumentType(undefined);
    setSelectedDocumentVariant(undefined);
    setFiles(undefined);
    setErrors([]);
    closeModal();
  };

  useEffect(() => {
    let filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.results = {
      name: {
        operator: "eq",
        value: "Map of Site",
      },
      standard: {
        standardUuid: {
          operator: "in",
          value:
            projectDetails !== undefined && projectDetails?.standard !== undefined
              ? `${projectDetails?.standard.uuid}, null`
              : null,
        },
      },
      isGenerated: {
        operator: "eq",
        value: false,
      },
    };

    searchProjectDocumentTypes({
      paging: { beforeCursor: null, afterCursor: null, limit: 50 },
      filter: filterCriteria,
    }).then((response) => {
      if (response.data?.results && response.data?.results.length > 0) {
        setSelectedDocumentType(response.data.results[0].name);
        filterCriteria = dataGridMapFilterCriteria([]);

        filterCriteria.results = {
          name: {
            operator: "eq",
            value: "Boundary",
          },
          typeUuid: {
            operator: "eq",
            value: response.data.results[0].uuid,
          },
        };

        searchProjectDocumentTypeVariants({
          paging: { beforeCursor: null, afterCursor: null, limit: 50 },
          filter: filterCriteria,
        }).then((res) => {
          if (res.data?.results && res.data?.results.length > 0) {
            setSelectedDocumentVariant(res.data.results[0].name);
          }
        });
      }
    });
  }, []);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    if (files === undefined) {
      setErrors([
        {
          code: "MANDATORY_FIELDS",
          message: `Please complete the mandatory fields: File`,
        },
      ]);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    saveBoundaryFile(files![0]);
  }, setIsHandleSubmitLoading);

  return {
    fileUploadRef,
    projectDetails,
    maxFileSize,
    selectedDocumentVariant,
    selectedDocumentType,
    isHandleSubmitLoading,
    setFiles,
    handleCancel,
    handleSubmit,
    errors,
  };
};
