import "./SelectProjectsModal.css";

import { Dispatch, SetStateAction, useMemo } from "react";

import { SelectData } from "../../../../../../models";
import { ActionButtons, Column, KanaTooltip, Modal, Row } from "../../../../../../widget";
import { AutocompleteMultiSelect } from "../../../../../../widget/forms/AutocompleteMultiSelect";

interface SelectProjectModalProps {
  roadmapProjectUuids: string[];
  setRoadmapProjectUuids: Dispatch<SetStateAction<string[]>>;
  projectsMultiSelectData: SelectData | undefined;
  onSaveProjectsSelectData: () => void;
  show: boolean;
  closePanel: () => void;
  fetchSelectData: (displayName?: string) => Promise<void>;
}
export const SelectProjectsModal = ({
  roadmapProjectUuids,
  setRoadmapProjectUuids,
  projectsMultiSelectData,
  onSaveProjectsSelectData,
  show,
  closePanel,
  fetchSelectData,
}: SelectProjectModalProps): JSX.Element => {
  const projectsMultiSelectDataOrder = useMemo<string[]>(() => {
    const notSelectedProjects =
      projectsMultiSelectData
        ?.filter((project) => !roadmapProjectUuids.includes(project.key as string))
        .map((project) => project.key as string) ?? [];

    return roadmapProjectUuids.concat(notSelectedProjects);
  }, [projectsMultiSelectData, roadmapProjectUuids]);

  return (
    <Modal onClose={closePanel} show={show} title="Select projects to add to roadmap">
      <form className="SelectProjectsModalForm" onSubmit={() => onSaveProjectsSelectData()}>
        <Row spacingV="xl" justify="center">
          <Column span={8}>
            <p className="body1">
              To populate the project roadmap, select up to 15 projects from the dropdown below. Only projects with an
              assigned workflow will appear on the roadmap. To assign workflows, visit the workflows section.
            </p>
          </Column>
        </Row>
        <Row spacingV="xl" justify="center">
          <Column span={6}>
            <AutocompleteMultiSelect
              label="Add projects"
              placeholder="Search projects"
              className="EditProjectsDropdown"
              tooltip={
                <KanaTooltip
                  tooltipHeader=""
                  tooltipText="If you can't see your project here, it doesn't have a workflow assigned. You can assign a workflow here TODO [link to workflows section]."
                />
              }
              values={roadmapProjectUuids}
              data={projectsMultiSelectData ?? []}
              order={projectsMultiSelectDataOrder}
              onChange={(values) => setRoadmapProjectUuids(values)}
              onTypeSearch={fetchSelectData}
            />
          </Column>
        </Row>
        <Row spacingV="xl" justify="center">
          <Column span={6}>
            <ActionButtons onCancel={closePanel} fullWidth />
          </Column>
        </Row>
      </form>
    </Modal>
  );
};
