import { createContext, useMemo, useState } from "react";

import { ArrowDownDarkIcon } from "../../../../assets";
import { DiscussionVisibilityConstants } from "../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../models";
import { deleteDiscussionThread, updateDiscussionThread } from "../../../../service/interaction";
import { searchDiscussionThreads, SearchDiscussionThreadsResponse } from "../../../../service/query";
import { ResultData, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { locationToNaturalLanguage } from "../../../../utils/activity/locationToNL";
import { getActivityReviewViewRoute, getActivityViewRoute } from "../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../widget";
import { AvatarCellFormatter } from "../../../../widget/data/DataGrid/components/CellFormatters";
import { IconCellFormatterData } from "../../../../widget/data/DataGrid/models";
import { useActivityDashboardContextSwitcher } from "../../utils/activities/useActivityDashboardContextSwitcher";
import { UnreadDiscussionCellFormatter } from "./components/UnreadDiscussionCellFormatter";
import { DiscussionThreadActionCellFormatter } from "./discussion-table/components";

interface ActivityDiscussionProviderProps {
  children?: JSX.Element | JSX.Element[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
  {
    key: "isUnread",
    name: "",
    dataType: "boolean",
    formatter: "custom",
    customCellFormatter: UnreadDiscussionCellFormatter,
    minWidth: 0,
  },
  {
    key: "dropdown",
    name: "",
    dataType: "string",
    formatter: "icon",
    alignment: "left",
    filterable: false,
    sortable: false,
    minWidth: 0,
  },
  {
    key: "location",
    name: "Location",
    dataType: "string",
    formatter: "link",
    filterable: true,
    minWidth: 204,
  },
  {
    key: "subject",
    name: "Subject",
    dataType: "string",
    formatter: "stringWithEllipsis",
    filterable: true,
    minWidth: 232,
  },
  {
    key: "createdAt",
    name: "Date Added",
    dataType: "Date",
    formatter: "dateOnly",
    alignment: "left",
    filterable: false,
  },
  {
    key: "lastUpdated",
    name: "Last updated",
    dataType: "Date",
    formatter: "dateOnly",
    alignment: "left",
    filterable: false,
  },
  {
    key: "avatar",
    name: "Raised by",
    dataType: "string",
    formatter: "custom",
    alignment: "center",
    customCellFormatter: AvatarCellFormatter,
    filterable: true,
  },
  {
    key: "visibility",
    name: "Visibility",
    dataType: "string",
    formatter: "stringWithEllipsis",
    alignment: "left",
    filterable: true,
  },
  {
    key: "actionsData",
    name: "Actions",
    dataType: "DiscussionsActionsMenuGridRow",
    formatter: "custom",
    filterable: false,
    sortable: false,
    alignment: "center",
    customCellFormatter: DiscussionThreadActionCellFormatter,
  },
];

type ResultDataFormat = SearchDiscussionThreadsResponse["results"][0];

interface ActivityDiscussionContextType {
  activityUuid: string | undefined;
  refreshActivities: boolean;
  triggerPagePrevious?: boolean;
  isDataLoading: boolean;
  isConfirmDeleteDiscussionLoading: boolean;
  discussionTableVisible: boolean;
  showDeleteDiscussionModal: boolean;
  selectedResolveViewOption: string;
  selectedThreadDetails: ResultData | undefined;
  columns: DataGridColumnDefinition[];

  setRefreshActivities: (arg: boolean) => void;
  hideTableShowDetails: () => void;
  showTableHideDetails: () => void;
  confirmDeleteDiscussionCB?: () => void;
  refreshDiscussionTables: () => void;
  hideDeleteDiscussionModal: () => void;
  setSelectedResolveViewOption: (arg: string) => void;
  selectTableRowAndHideDetails: (arg: unknown) => void;
  setSelectedThreadDetails: (details: ResultData | undefined) => void;
  onRequestFetchDiscussions: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const ActivityDiscussionContext = createContext<ActivityDiscussionContextType>(
  {} as ActivityDiscussionContextType
);

let deleteThreadFunctionCallback: (uuid: string) => void;
let confirmDeleteThreadFunctionCallback: (uuid: string) => void;
let resolveThreadFunctionCallback: (uuid: string) => void;

let refreshDiscussion: () => void;

export const ActivityDiscussionProvider = ({ children }: ActivityDiscussionProviderProps): JSX.Element => {
  const { user, currentUserType } = useAuth();
  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);

  const { activityUuid, isActivityReview, queryParams, currentOrganisationUuid, refreshResolvedDiscussionsKPI } =
    useActivityDashboardContextSwitcher();
  const [refreshActivities, setRefreshActivities] = useState<boolean>(false);

  const [showDeleteDiscussionModal, setShowDeleteDiscussionModal] = useState<boolean>(false);
  const [confirmDeleteDiscussionCB, setConfirmDeleteDiscussionCB] = useState<() => void>();

  const [triggerPagePrevious, setTriggerPagePrevious] = useState<boolean | undefined>();
  const [displayedDiscussionCount, setDisplayedDiscussionCount] = useState<number>(0);

  const [discussionTableVisible, setDiscussionTableVisible] = useState(true);
  const [selectedThreadDetails, setSelectedThreadDetails] = useState<ResultData | undefined>();
  const [selectedResolveViewOption, setSelectedResolveViewOption] = useState("Unresolved");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isConfirmDeleteDiscussionLoading, setIsConfirmDeleteDiscussionLoading] = useState<boolean>(false);

  const pageSize = 10;

  const hideTableShowDetails = (): void => setDiscussionTableVisible(false);
  const showTableHideDetails = (): void => setDiscussionTableVisible(true);

  const hideDeleteDiscussionModal = (): void => setShowDeleteDiscussionModal(false);

  const refreshDiscussionTables = (): void => {
    setRefreshActivities(!refreshActivities);
  };
  refreshDiscussion = refreshDiscussionTables;

  const previousPage = (): void => {
    setTriggerPagePrevious(!triggerPagePrevious);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectTableRowAndHideDetails = (selectedRow: any): void => {
    setSelectedThreadDetails(selectedRow);
    hideTableShowDetails();
    refreshDiscussionTables();
  };

  const resolveThread = (threadUuid: string): void => {
    showTableHideDetails();
    updateDiscussionThread({
      threadUuid,
      isResolved: true,
      rowVersion: 1,
    })
      .then((res) => {
        setTimeout(() => {
          refreshDiscussion();
        }, 500);

        if (res.status === Status.Success) {
          refreshDiscussionTables();
          if (displayedDiscussionCount <= 1) {
            previousPage();
          }
          Toast.success({ message: `Discussion resolved` });
        }

        if (res.status === Status.Error && res.errors) {
          res.errors.forEach((error) => {
            Toast.error({ message: `Could not resolve discussion: ${error.message}` });
          });
        }
      })
      .finally(() => {
        refreshDiscussion();
        refreshResolvedDiscussionsKPI();
      });
  };
  resolveThreadFunctionCallback = resolveThread;

  const confirmDeleteThread = useIsLoadingWrapper(async (threadUuid: string): Promise<void> => {
    showTableHideDetails();
    await deleteDiscussionThread({
      threadUuid,
    })
      .then((res) => {
        if (res.status === Status.Success) {
          setSelectedThreadDetails(undefined);
          refreshDiscussionTables();
          if (displayedDiscussionCount <= 1) {
            previousPage();
          }
          Toast.success({ message: `Discussion deleted` });
        }
        if (res.status === Status.Error && res.errors) {
          res.errors.forEach((error) => {
            switch (error.code) {
              case "VALIDATION_THREAD_IN_USE":
                Toast.error({ message: `This discussion has been updated by another user` });
                return;
              case "VALIDATION_INVALID_THREAD_AUTHOR":
                Toast.error({ message: `Only the author of this thread has permission to delete it` });
                return;
              default:
                Toast.error({ message: `Could not delete discussion: ${error.message}` });
                break;
            }
          });
        }
      })
      .finally(() => {
        hideDeleteDiscussionModal();
        refreshDiscussionTables();
        refreshResolvedDiscussionsKPI();
      });
  }, setIsConfirmDeleteDiscussionLoading);
  confirmDeleteThreadFunctionCallback = confirmDeleteThread;

  const deleteThread = (threadUuid: string): void => {
    setConfirmDeleteDiscussionCB(() => () => confirmDeleteThreadFunctionCallback(threadUuid));
    setShowDeleteDiscussionModal(true);
  };
  deleteThreadFunctionCallback = deleteThread;

  const onRequestFetchDiscussions = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);

    if (filterCriteria.avatar) {
      filterCriteria.createdByUser = {
        fullName: filterCriteria.avatar,
      };
      delete filterCriteria.avatar;
    }

    switch (selectedResolveViewOption) {
      case "Unresolved":
        filterCriteria.isResolved = { operator: "eq", value: false };
        break;
      case "Resolved":
        filterCriteria.isResolved = { operator: "eq", value: true };
        break;
      default:
        break;
    }

    sorting.forEach((s) => {
      switch (s.key) {
        case "avatar":
          // eslint-disable-next-line no-param-reassign
          s.key = "createdByUser.fullName";
          break;
        default:
          break;
      }
    });

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const formatResultsData = (responseData: SearchDiscussionThreadsResponse | undefined): ResultData[] => {
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseData?.results?.map((thread: ResultDataFormat): any => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const messages = (thread.messages ?? []) as any[];
          const lastMessage = messages[messages.length - 1];

          const createdByUserUuid = thread.messages[0].createdByUser.uuid;

          const fullName: string = thread?.createdByUser?.fullName ?? "";
          const locationText = locationToNaturalLanguage(thread.location);

          const publishedMessagesAgainstWizard = thread.messages.filter(
            (tm) => tm.status === "Published" && tm.objectType === "Activity History"
          );

          const latestMessagesActivityHistory =
            publishedMessagesAgainstWizard.length === 0
              ? null
              : publishedMessagesAgainstWizard.reduce((prev, current) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  prev && prev.objectVersionNumber! > current.objectVersionNumber! ? prev : current
                );

          const dropdown = {
            action: () => undefined,
            icon: <ArrowDownDarkIcon />,
          } as IconCellFormatterData;

          const location =
            thread.location === "General"
              ? ({
                  text: locationText,
                  target: "_blank",
                  title: locationText,
                } as DataGridLinkCellFormatterData)
              : ({
                  text: locationText,
                  to: isActivityReview
                    ? getActivityReviewViewRoute(
                        latestMessagesActivityHistory?.objectUuid ?? thread.currentActivityHistoryUuid,
                        `location=${thread.location}&${queryParams !== undefined ? queryParams : ""}`
                      )
                    : getActivityViewRoute(
                        latestMessagesActivityHistory?.objectUuid ?? thread.currentActivityHistoryUuid,
                        currentUserType,
                        `location=${thread.location}&${queryParams !== undefined ? queryParams : ""}`
                      ),
                  target: "_blank",
                  title: locationText,
                } as DataGridLinkCellFormatterData);

          return {
            createdAt: thread.createdAt,
            lastUpdated: lastMessage.createdAt,
            location,
            avatar: { fullName },
            subject: messages[0].content ?? "",
            dropdown,
            isUnread:
              messages.filter(
                (m) =>
                  (thread.visibility === DiscussionVisibilityConstants.EXTERNAL
                    ? currentOrganisationUuid !== m.sourceOrganisationUuid
                    : m.createdByUser.uuid !== userUuid) && !m.isRead
              ).length > 0,
            visibility: thread.visibility,
            uuid: thread.uuid,
            actionsData: {
              onResolve: () => resolveThreadFunctionCallback(thread.uuid),
              showResolveOption:
                !thread.isResolved && createdByUserUuid === userUuid && !!messages.every((m) => m.isRead),
              onDelete: () => deleteThreadFunctionCallback(thread.uuid),
              showDeleteOption:
                createdByUserUuid === userUuid && !!messages.every((m) => m.createdByUser.uuid === userUuid),
              isResolved: thread.isResolved,
            },
            messages,
          };
        }) ?? []
      );
    };

    await searchDiscussionThreads({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: pageSize,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
      isDraft: true,
      objectUuid: activityUuid ?? null,
      objectType: "Activity",
    })
      .then(async (response) => {
        const resultData = formatResultsData(response.data);
        data = {
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
          resultData,
        };
        setSelectedThreadDetails(resultData.find((el) => el.uuid === selectedThreadDetails?.uuid));
      })
      .finally(() => {
        setIsDataLoading(false);
      });

    setDisplayedDiscussionCount(data.resultData.length);
    return data;
  };

  const memoisedValue = useMemo(
    () => ({
      activityUuid,
      refreshActivities,
      discussionTableVisible,
      selectedThreadDetails,
      columns,
      selectedResolveViewOption,
      isDataLoading,
      isConfirmDeleteDiscussionLoading,
      triggerPagePrevious,
      showDeleteDiscussionModal,
      hideDeleteDiscussionModal,

      setRefreshActivities,
      hideTableShowDetails,
      showTableHideDetails,
      setSelectedThreadDetails,
      confirmDeleteDiscussionCB,
      onRequestFetchDiscussions,
      setSelectedResolveViewOption,
      refreshDiscussionTables,
      selectTableRowAndHideDetails,
    }),
    [
      activityUuid,
      isDataLoading,
      isConfirmDeleteDiscussionLoading,
      refreshActivities,
      discussionTableVisible,
      selectedThreadDetails,
      triggerPagePrevious,
      showDeleteDiscussionModal,
    ]
  );

  return <ActivityDiscussionContext.Provider value={memoisedValue}>{children}</ActivityDiscussionContext.Provider>;
};
