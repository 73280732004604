import { BeforePhaseRingIcon, CompletedPhaseTickIcon, DuringPhaseRingIcon } from "../../../../../../assets";
import { convertDateToStringDDMMYYYY } from "../../../../../../utils";

interface PhaseSectionProps {
  phase: string;
  start?: Date;
  end?: Date;
}
export const PhaseSection = ({ phase, start, end }: PhaseSectionProps): JSX.Element | undefined => {
  if (!start || !end) return undefined;

  const currentTime = new Date().getTime();
  let phaseState = "Completed";
  if (currentTime < start.getTime()) {
    phaseState = "Before";
  } else if (start.getTime() < currentTime && currentTime < end.getTime()) {
    phaseState = "During";
  }

  return (
    <div className={`PhaseSection ${phaseState}`}>
      {phaseState === "Before" && <BeforePhaseRingIcon />}
      {phaseState === "During" && <DuringPhaseRingIcon />}
      {phaseState === "Completed" && <CompletedPhaseTickIcon />}
      <p className="body2">{phase}</p>
      <p className="body5">{`${convertDateToStringDDMMYYYY(start)} - ${convertDateToStringDDMMYYYY(end)}`}</p>
    </div>
  );
};
