import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import { LocationData } from "../../../../../models";
import { uploadProjectLocation } from "../../../../../service/project/ProjectService";
import { GetProjectDetailsResponse } from "../../../../../service/query";
import { ServiceError, Status } from "../../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../../service/ValidationErrorFormatter";
import { MapLayerOptions, MapOpenLayerGisFileProps, Toast } from "../../../../../widget";
import { ProjectContext } from "../ProjectContext";

interface UseLocationReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  locationData: LocationData;
  selectedLayer: MapLayerOptions;
  gisFile: MapOpenLayerGisFileProps;
  is3d: boolean;
  setIs3d: Dispatch<SetStateAction<boolean>>;
  changeSelectedLayer: (newSelectedLayer: MapLayerOptions) => void;
  isToDrawBoundary: boolean;
  setIsToDrawBoundary: Dispatch<SetStateAction<boolean>>;
  isToUploadManually: boolean;
  setIsToUploadManually: Dispatch<SetStateAction<boolean>>;
  isToSaveBoundary: boolean;
  setIsToSaveBoundary: Dispatch<SetStateAction<boolean>>;
  saveBoundaryFile: (boundaryJson?: string, boundaryFile?: File) => void;
  clearDrawBoundaryToggle: boolean;
  cleanShape: () => void;
  errors: ServiceError[];
}

export const useLocation = (): UseLocationReturnData => {
  const { projectDetails, shouldRefreshProjectDetails } = useContext(ProjectContext);
  const [errors, setErrors] = useState<ServiceError[]>([]);

  const [selectedLayer, setSelectedLayer] = useState(MapLayerOptions.BASE);
  const [is3d, setIs3d] = useState(false);
  const [isToDrawBoundary, setIsToDrawBoundary] = useState(false);
  const [isToUploadManually, setIsToUploadManually] = useState(false);
  const [clearDrawBoundaryToggle, setClearDrawBoundaryToggle] = useState(false);
  const [isToSaveBoundary, setIsToSaveBoundary] = useState(false);
  const [gisFile, setGisFile] = useState<MapOpenLayerGisFileProps>();

  const locationData = useMemo(() => {
    const res =
      projectDetails?.uuid === "171c26f0-d5ce-11ec-9d64-0242ac120002"
        ? {
            addressCountryCode: projectDetails?.addressCountryCode,
            gridReference: projectDetails?.locationGridReference,
            latitude: "55.574",
            longitude: "-2.948",
            locationUuid: projectDetails?.projectLocation?.uuid || null,
            locationRowVersion: projectDetails?.projectLocation?.rowVersion || null,
            boundaryData: projectDetails?.projectLocation?.boundaryData || null,
            shapeFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/Broadmeadows.json",
          }
        : {
            addressCountryCode: projectDetails?.addressCountryCode,
            gridReference: projectDetails?.locationGridReference,
            latitude: projectDetails?.locationCoordinates?.split(",")[0] || null,
            longitude: projectDetails?.locationCoordinates?.split(",")[1] || null,
            locationUuid: projectDetails?.projectLocation?.uuid || null,
            locationRowVersion: projectDetails?.projectLocation?.rowVersion || null,
            boundaryData: projectDetails?.projectLocation?.boundaryData || null,
            shapeFileUrl: projectDetails?.projectLocation?.locationFileUrl || undefined,
          };

    setGisFile({
      projectShape: {
        geoJsonFileUrl: res.shapeFileUrl,
        geoJsonContent: res.boundaryData,
        fillColor: "#FF865D",
      },
      vectorFile: undefined,
      rasterLayer: undefined,
    });

    return res;
  }, [projectDetails?.projectLocation?.boundaryData, projectDetails?.projectLocation?.locationFileUrl]);

  const cleanShape = (): void => {
    setClearDrawBoundaryToggle((pre) => {
      return !pre;
    });
  };

  const changeSelectedLayer = (newSelectedLayer: MapLayerOptions): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newGisFile = gisFile!;
    switch (newSelectedLayer) {
      case MapLayerOptions.VEG_LAI:
        newGisFile.mapContext = {
          mapContextHead: "Canopy density (LAI)",
          mapContextContent:
            "Quantifies the total leaf area per area unit to show canopy structure, growth and density. Values ~0 indicate little to no vegetation, whereas 4-6 represents dense forest with high biomass. ",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/veg_LAI_23.tif",
              date: "20230815",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/veg_LAI_24.tif",
              date: "20241029",
            },
          ],
          rasterMinMax: {
            minValue: 0,
            maxValue: 6,
          },
          rasterFillColours: [
            {
              Colour: "#FCE359",
              Text: "Low vegetation",
            },
            {
              Colour: "#78BB96",
            },
            {
              Colour: "#28245C",
              Text: "Dense canopy",
            },
          ],
        };
        break;
      case MapLayerOptions.VEG_NDNI:
        newGisFile.mapContext = {
          mapContextHead: "Canopy growth rate (NDNI)",
          mapContextContent:
            "Indicates canopy nitrogen concentration, important for plant growth and productivity. Suited to green vegetation and can be used to highlight vegetation with rapid growth. Values from 0 to 1, with most green vegetation having NDNI values 0.02 to 0.1",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/VEG_NDNI_23.tif",
              date: "20230415",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/VEG_NDNI_24.tif",
              date: "20240529",
            },
          ],
          rasterMinMax: {
            minValue: -1,
            maxValue: 1,
          },
          rasterFillColours: [
            {
              Colour: "#FFFFFF",
              Text: "Low nitrogen",
            },
            {
              Colour: "#DC72B8",
              Text: "High nitrogen",
            },
          ],
        };
        break;
      case MapLayerOptions.VEG_PSRI:
        newGisFile.mapContext = {
          mapContextHead: "Plant stress monitoring (PSRI)",
          mapContextContent:
            "Detects plant stress and senescence by measuring chlorophyll degradation and carotenoid increase. Typical healthy value: -0.1 to 0.2. Greater values indicate stressed vegetation that could be due to drought, pests or nutrient deficiencies.",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/VEG_PSRI_23.tif",
              date: "20230515",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/VEG_PSRI_24.tif",
              date: "20240829",
            },
          ],
          rasterMinMax: {
            minValue: -1,
            maxValue: 1,
          },
          rasterFillColours: [
            {
              Colour: "#FFFFFF",
              Text: "Healthy vegetation",
            },
            {
              Colour: "#78BB96",
            },
            {
              Colour: "#FCE359",
              Text: " High stress/senescing veg",
            },
          ],
        };
        break;
      case MapLayerOptions.VEG_NDVI:
        newGisFile.mapContext = {
          mapContextHead: "Vegetation vigour (NDVI)",
          mapContextContent:
            "Provides a broad indication of vegetation health / greenness. Typical values: 0.2 to 0.8, lower values indicate soil / non-vegetation, whilst higher values from 0.2 to 1 indicate medium or thick vegetation.",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/VEG_NDVI_23.tif",
              date: "20230515",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/VEG_NDVI_24.tif",
              date: "20240829",
            },
          ],
          rasterMinMax: {
            minValue: -1,
            maxValue: 1,
          },
          rasterFillColours: [
            {
              Colour: "#FFFFFF",
              Text: "Non-vegetation",
            },
            {
              Colour: "#968735",
              Text: "Healthy vegetation",
            },
          ],
        };
        break;
      case MapLayerOptions.Water_NDMI:
        newGisFile.mapContext = {
          mapContextHead: "Moisture monitoring (NDMI)",
          mapContextContent:
            "A remote sensing index used to measure vegetation moisture levels and topsoil moisture content. It is useful for understanding water availability and plant health. Values -1 to 0 indicate low moisture conditions, whereas ~0.4 to 1 indicate high moisture conditions.",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/Water_NDMI_23.tif",
              date: "20230315",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/Water_NDMI_24.tif",
              date: "20240229",
            },
          ],
          rasterMinMax: {
            minValue: -1,
            maxValue: 1,
          },
          rasterFillColours: [
            {
              Colour: "#E1F3F8",
              Text: "Dry soil / veg",
            },
            {
              Colour: "#396877",
              Text: "Moist vegetation",
            },
          ],
        };
        break;
      case MapLayerOptions.Water_MNDWI:
        newGisFile.mapContext = {
          mapContextHead: "Water and flood monitoring (MNDWI)",
          mapContextContent:
            "Uses infrared wavelengths to identify and monitor the presence of surface water bodies. Values of -1 to 0 indicate non-water surfaces, whereas ~0.3 to 1 indicate water bodies. ",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/Water_MNDWI_23.tif",
              date: "20230615",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/Water_MNDWI_24.tif",
              date: "20240729",
            },
          ],
          rasterMinMax: {
            minValue: -1,
            maxValue: 1,
          },
          rasterFillColours: [
            {
              Colour: "#E0E4F8",
              Text: "Drier areas",
            },
            {
              Colour: "#364074",
              Text: "Water bodies",
            },
          ],
        };
        break;
      case MapLayerOptions.FIRE_RISK:
        newGisFile.mapContext = {
          mapContextHead: "Fire risk monitoring",
          mapContextContent:
            "Combining fuel load, which detects combustible materials inside the site area, and  normalised burn ratio, which detects burn severity, to provide a fire risk score for the site location. Values < 1 indicating low risk of burning, with values > 1 indicating increasing risk of burning. ",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/FIRE_RISK_23.tif",
              date: "20230615",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/FIRE_RISK_24.tif",
              date: "20240729",
            },
          ],
          rasterMinMax: {
            minValue: 0,
            maxValue: 2,
          },
          rasterFillColours: [
            {
              Colour: "#FBE9EA",
              Text: "Low fire risk",
            },
            {
              Colour: "#D71F2A",
              Text: "High fire risk",
            },
          ],
        };
        break;
      case MapLayerOptions.LAND_CLASSIFICATION:
        newGisFile.mapContext = {
          mapContextHead: "Land classifier",
          mapContextContent:
            "Combines a multi-spectral classification of land use with human settlement data to assess the site and detect different features, such as trees, buildings and water bodies. Retrieved on a yearly basis, this service allows you to see the site develop over time. The provided legend shows the different features detected.",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl:
                "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/LAND_CLASSIFICATION_24.tif",
              date: "20230815",
            },
            {
              geoTiffFileUrl:
                "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/LAND_CLASSIFICATION_24.tif",
              date: "20240629",
            },
          ],
          rasterMinMax: {
            minValue: 0,
            maxValue: 6,
          },
          rasterFillColours: [
            {
              Colour: "#87CEE4",
              Text: "Water",
            },
            {
              Colour: "#5F9375",
              Text: "Tree",
            },
            {
              Colour: "#BACF77",
              Text: "Vegetation",
            },
            {
              Colour: "#FFFFFF",
              Text: "Non-vegetation",
            },
            {
              Colour: "#565962",
              Text: "Building",
            },
          ],
        };
        break;
      case MapLayerOptions.TREE_CARBON:
        newGisFile.mapContext = {
          mapContextHead: "Carbon mass calculation (IPCC)",
          mapContextContent:
            "Estimates the amount of carbon present in trees detected at the site. These values can be compared with carbon calculations to determine if the project is broadly on track with its targets. Low values,< 40 tC/ha, indicate young trees, whereas older trees will tend to have values > 150 tC/ha",
        };
        newGisFile.rasterLayer = {
          rasterFiles: [
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/TREE_CARBON_23_.tif",
              date: "20230315",
            },
            {
              geoTiffFileUrl: "https://kqaapiv11full.blob.core.windows.net/media-files-public/gis/TREE_CARBON_24_.tif",
              date: "20241129",
            },
          ],
          rasterMinMax: {
            noDataValue: 0,
            minValue: 0.0001,
            maxValue: 0.1,
          },
          rasterFillColours: [
            {
              Colour: "#FFFFFF",
              Text: "Low carbon present",
            },
            {
              Colour: "#FDB55B",
            },
            {
              Colour: "#4F291C",
              Text: "High carbon content",
            },
          ],
        };
        break;
      default:
        break;
    }
    setGisFile(newGisFile);
    setSelectedLayer(newSelectedLayer);
  };

  const saveBoundaryFile = (boundaryJson?: string, boundaryFile?: File): void => {
    let inputFile: File;
    if (boundaryFile === undefined) {
      const blob = new Blob([boundaryJson || "{}"], { type: "text/plain" });
      const now = new Date();
      const timestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(
        now.getDate()
      ).padStart(2, "0")}-${String(now.getHours()).padStart(2, "0")}-${String(now.getMinutes()).padStart(2, "0")}`;
      inputFile = new File([blob], `${projectDetails?.displayName}_BoundaryGeoJson_${timestamp}.json`, {
        type: "text/plain",
      });
    } else {
      inputFile = boundaryFile;
    }
    uploadProjectLocation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      file: inputFile!,
      projectLocationUuid: locationData.locationUuid,
      projectUuid: projectDetails?.uuid || "",
      rowVersion: locationData.locationRowVersion,
    }).then((response) => {
      if (response.status === Status.Success) {
        Toast.success({ message: "Location file uploaded successfully" });
        shouldRefreshProjectDetails();
        setIsToDrawBoundary(false);
      }
      if (response.status === Status.Error && response.errors) {
        setErrors(
          response.errors.map((error) => {
            return { ...error, message: getErrorMessageFromCode(error.code) };
          })
        );
      }
    });
  };

  return {
    projectDetails,
    locationData,
    selectedLayer,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    gisFile: gisFile!,
    is3d,
    setIs3d,
    changeSelectedLayer,
    isToDrawBoundary,
    setIsToDrawBoundary,
    isToUploadManually,
    setIsToUploadManually,
    isToSaveBoundary,
    setIsToSaveBoundary,
    saveBoundaryFile,
    clearDrawBoundaryToggle,
    cleanShape,
    errors,
  };
};
