import "./ProjectRoadmapRowLabels.css";

import { forwardRef } from "react";

import { OrganisationTypeConstants } from "../../../../../constants";
import { getProjectDetailsByUuid } from "../../../../../utils/routes";
import { ProjectRoadmapRowLabelData } from "../../models";

interface ProjectRoadmapRowLabelsProps {
  data: ProjectRoadmapRowLabelData;
}

export const ProjectRoadmapRowLabels = forwardRef<HTMLDivElement, ProjectRoadmapRowLabelsProps>(
  ({ data }, forwardedRef): JSX.Element => {
    return (
      <div ref={forwardedRef} className="ProjectRoadmapRowLabels">
        <div className="RowLabel Header">
          <h3>Project</h3>
        </div>
        {data.map((x) => (
          <div key={x?.uuid}>
            <div className={`RowLabel ${!x.additionalDates ? "Small" : ""}`}>
              <a
                className="body2"
                href={getProjectDetailsByUuid(x.uuid ?? "", OrganisationTypeConstants.DEVELOPMENT_MANAGER)}
              >
                <b>{x.displayName}</b>
              </a>
              <p className="body3">{x.status}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
);
