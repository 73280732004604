import "./PhaseLifecycle.css";

import { CalandarWhiteIcon } from "../../../../assets";
import { ProjectDates } from "../../../../models";
import { Button } from "../../../forms";
import { PhaseSection } from "./components";

interface PhaseLifecycleProps {
  dates: ProjectDates | undefined;
  handleEditDatesClick: () => void;
}

export const PhaseLifecycle = ({ dates, handleEditDatesClick }: PhaseLifecycleProps): JSX.Element => {
  const isEmpty =
    !dates ||
    (!dates.feasibility?.start &&
      !dates.feasibility?.end &&
      !dates.design?.start &&
      !dates.design?.end &&
      !dates.implementation?.start &&
      !dates.implementation?.end &&
      !dates.ongoingMonitoring?.start &&
      !dates.ongoingMonitoring?.end);
  return (
    <div className="PhaseLifecycle">
      <div className="PhaseLifecycleHeader">
        <h3>Phase lifecycle</h3>
        {!isEmpty && (
          <Button
            text="Edit dates"
            variant="reversedPrimary"
            size="small"
            textUnderline
            textBold
            hasNoPadding
            onClick={handleEditDatesClick}
          />
        )}
      </div>
      {isEmpty ? (
        <div className="EmptyPhaseLifecycleContainer">
          <div className="EmptyPhaseLifecycle">
            <p className="body01">
              Add phase dates to visualise your project&apos;s lifecycle, from feasibility to ongoing monitoring
            </p>
            <Button
              text="Add Phase Dates"
              icon={<CalandarWhiteIcon />}
              onClick={handleEditDatesClick}
              iconPosition="left"
            />
          </div>
        </div>
      ) : (
        <div className="PhaseLifecycleSteps">
          <PhaseSection phase="Feasibility" start={dates?.feasibility?.start} end={dates?.feasibility?.end} />
          <PhaseSection phase="Design" start={dates?.design?.start} end={dates?.design?.end} />
          <PhaseSection phase="Implementation" start={dates?.implementation?.start} end={dates?.implementation?.end} />
          <PhaseSection
            phase="Ongoing monitoring"
            start={dates?.ongoingMonitoring?.start}
            end={dates?.ongoingMonitoring?.end}
          />
        </div>
      )}
    </div>
  );
};
