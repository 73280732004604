import "./TagsPanel.css";

import { ReactElement } from "react";

import { PlusIcon } from "../../assets";
import { ActionButtons, Button, Checkbox, Column, Divider, ErrorBox, FlyoutPanel, Row, TextInput } from "../../widget";
import { ManageTags, TagProjects } from "./components";
import { useTagsPanel } from "./useTagsPanel";

interface TagsPanelProps {
  projectUuid: string;
  show: boolean;
  onClose: () => void;
  shouldRefreshTags: () => void;
}

export const TagsPanel = ({ projectUuid, show, onClose, shouldRefreshTags }: TagsPanelProps): JSX.Element => {
  const {
    currentTags,
    newTags,
    errors,

    isHandleSubmitLoading,
    handleSubmit,

    showManageTagsPanel,
    handleShowManageTagsPanel,

    showTagProjectsPanelUuid,

    handleCurrentCheckboxChange,
    handleNewCheckboxChange,
    handleNewTextboxChange,
    handleAddNewTag,
    handleClosePanel,
  } = useTagsPanel({
    projectUuid,
    onClose,
    shouldRefreshTags,
  });

  const content = (): ReactElement => {
    if (showTagProjectsPanelUuid !== undefined) return <TagProjects onClose={() => handleClosePanel()} />;
    if (showManageTagsPanel) return <ManageTags onClose={handleClosePanel} />;

    return (
      <div className="TagsPanelContent">
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={12}>
              <ErrorBox text={errors.map((error) => error.message)} />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          {!!currentTags && currentTags?.length > 0 ? (
            currentTags?.map((tag, index) => (
              <div key={tag.uuid}>
                <Row spacingV="mm">
                  <Column span={12}>
                    <div className="TagNameAndCheckbox">
                      <Checkbox
                        checked={tag.checked}
                        onChange={(e) => handleCurrentCheckboxChange(e.target.checked, tag.uuid)}
                      />
                      <p className="body1Light">{tag.data.name}</p>
                    </div>
                  </Column>
                </Row>
                <Row spacingV={index === currentTags.length - 1 ? "s" : "mm"}>
                  <Column span={12}>
                    <Divider type="horizontal" />
                  </Column>
                </Row>
              </div>
            ))
          ) : (
            <Row spacingV="ll">
              <Column span={12}>
                <p className="body1 NoTags">Your organisation doesn&apos;t have any tags yet</p>
              </Column>
            </Row>
          )}
          {newTags.length > 0 &&
            newTags.map((tag) => (
              <div key={tag.id}>
                <Row spacingV="s">
                  <Column span={12}>
                    <div className="TagNameAndCheckbox">
                      <Checkbox
                        checked={tag.checked}
                        onChange={(e) => handleNewCheckboxChange(e.target.checked, tag.id)}
                      />
                      <TextInput name={tag.name} onChange={(e) => handleNewTextboxChange(e, tag.id)} />
                    </div>
                  </Column>
                </Row>
                <Row spacingV="s">
                  <Column span={12}>
                    <Divider type="horizontal" />
                  </Column>
                </Row>
              </div>
            ))}
          <Row spacingV="ml">
            <Column span={12}>
              <Button
                className="AddTagButton"
                text="Add a new tag"
                variant="reversedPrimary"
                onClick={handleAddNewTag}
                textUnderline
                textBold
                hasNoPadding
                icon={<PlusIcon />}
                iconPosition="left"
              />
            </Column>
          </Row>
          <ActionButtons onCancel={handleClosePanel} isLoading={isHandleSubmitLoading} />
        </form>
      </div>
    );
  };

  const buttons = (): ReactElement | undefined => {
    return !showManageTagsPanel && showTagProjectsPanelUuid === undefined ? (
      <Button
        className="ManageTagsButton"
        text="Manage tags"
        variant="reversedPrimary"
        size="small"
        textBold
        textUnderline
        hasNoPadding
        onClick={handleShowManageTagsPanel}
      />
    ) : undefined;
  };

  return (
    <FlyoutPanel
      show={show}
      onClose={handleClosePanel}
      size="ExtraSmall"
      header={(() => {
        if (showTagProjectsPanelUuid !== undefined) return "Select projects to tag";
        if (showManageTagsPanel) return "Manage all tags";
        return "Project tags";
      })()}
      buttonsChildren={buttons()}
      contentChildren={content()}
    />
  );
};
