import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { InteractionObjectType, InteractionType } from "../../constants";
import { InteractionNoteData } from "../../models";
import { deleteInteraction } from "../../service/interaction";
import { searchInteractions } from "../../service/query";
import { Status } from "../../service/Shared";
import { getErrorMessageFromCode } from "../../service/ValidationErrorFormatter";
import { jsonReviver, useIsLoadingWrapper } from "../../utils";
import { dataGridMapFilterCriteria, Toast } from "../../widget";
import { InteractionNote } from "./models";

interface UseNotesPanelProps {
  projectUuid: string;
  onClose: () => void;
}

interface UseNotesPanelReturnData {
  notes: InteractionNote[] | undefined;
  currentNote: InteractionNote | undefined;
  isOnConfirmDeleteNoteLoading: boolean;
  showEditNotePanel: boolean;
  showNewNotePanel: boolean;
  showDeleteNoteModal: boolean;
  setShowDeleteNoteModal: Dispatch<SetStateAction<boolean>>;
  setShowNewNotePanel: Dispatch<SetStateAction<boolean>>;
  onClickEditNote: (note: InteractionNote) => void;
  onConfirmDeleteNote: () => Promise<void>;
  closePanel: () => void;
}

export const useNotesPanel = ({ projectUuid, onClose }: UseNotesPanelProps): UseNotesPanelReturnData => {
  const [notes, setNotes] = useState<InteractionNote[]>();
  const [currentNote, setCurrentNote] = useState<InteractionNote>();

  const [refresh, setRefresh] = useState(false);

  const [showNewNotePanel, setShowNewNotePanel] = useState(false);
  const [showEditNotePanel, setShowEditNotePanel] = useState(false);
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);

  const [isOnConfirmDeleteNoteLoading, setIsOnConfirmDeleteNoteLoading] = useState(false);

  const onClickEditNote = (note: InteractionNote): void => {
    setCurrentNote(note);
    setShowEditNotePanel(true);
  };

  const closePanel = (): void => {
    if (showNewNotePanel) {
      setRefresh(!refresh);
      setShowNewNotePanel(false);
    } else if (showDeleteNoteModal && showEditNotePanel) {
      setRefresh(!refresh);
      setShowEditNotePanel(false);
      setShowDeleteNoteModal(false);
    } else if (showEditNotePanel) {
      setRefresh(!refresh);
      setShowEditNotePanel(false);
    } else {
      onClose();
    }
    setCurrentNote(undefined);
  };

  const onConfirmDeleteNote = async (): Promise<void> => {
    if (currentNote) {
      const res = await deleteInteraction({
        interactionUuids: [currentNote?.uuid],
      });

      if (res.status === Status.Success) {
        Toast.success({ message: "Note deleted successfully" });
        closePanel();
      } else if (res.status === Status.Error && res.errors && res.errors?.length) {
        Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
      }
    }
  };

  const getAndSetProjectNotes = useIsLoadingWrapper(async (uuid: string): Promise<void> => {
    const filterCriteria = dataGridMapFilterCriteria([]);
    filterCriteria.type = {
      operator: "eq",
      value: InteractionType.NOTE,
    };
    filterCriteria.objectType = {
      operator: "eq",
      value: InteractionObjectType.PROJECT,
    };
    filterCriteria.objectUuid = {
      operator: "eq",
      value: uuid,
    };

    const res = await searchInteractions({
      paging: {
        beforeCursor: null,
        afterCursor: null,
        limit: 50,
      },
      filter: { results: filterCriteria },
    });

    if (res.status === Status.Success && res.data?.results != null) {
      setNotes(
        res.data.results.map((x) => ({
          uuid: x.uuid ?? "",
          createdByUserFullName: x.createdByUser.fullName ?? "",
          createdAt: x.createdAt,
          data: JSON.parse(x.data, jsonReviver) as InteractionNoteData,
          rowVersion: x.rowVersion,
        }))
      );
    }
  }, setIsOnConfirmDeleteNoteLoading);

  useEffect(() => {
    if (projectUuid) {
      getAndSetProjectNotes(projectUuid);
    }
  }, [projectUuid, refresh]);

  return {
    notes,
    currentNote,
    isOnConfirmDeleteNoteLoading,
    showEditNotePanel,
    showNewNotePanel,
    showDeleteNoteModal,
    setShowDeleteNoteModal,
    setShowNewNotePanel,
    onConfirmDeleteNote,
    onClickEditNote,
    closePanel,
  };
};
