import "./ProjectTracker.css";

import { ArrowDownDarkIcon } from "../../../../assets";
import { NotesPanel } from "../../../../templates";
import {
  Button,
  Column,
  CursorDataGrid,
  MultiSelect,
  ProfileMenu,
  ProfileMenuItem,
  Row,
  Select,
} from "../../../../widget";
import { useProjectTracker } from "./useProjectTracker";

const ProjectTracker = (): JSX.Element => {
  const {
    columns,
    isLoading,
    quickFiltersSelectData,
    tagsFilterSelectData,
    refresh,
    hasData,
    actionsMenuOptionsList,
    exportTable,
    exportFileName,
    filterExportRows,
    filterExportColumns,
    projectTrackerLocalStorageItemName,
    defaultEditColumns,
    notesProjectUuid,

    showActionsMenu,
    setShowActionsMenu,
    showEditColumnsPanel,
    setShowEditColumnsPanel,
    showNotesPanel,
    closeNotesPanel,

    onQuickFilter,
    onTagsFilter,
    onChange,
  } = useProjectTracker();

  return (
    <div className="ProjectTrackerContainer">
      <Row>
        <Column span={6}>
          <h2>Project tracker</h2>
        </Column>
        <Column span={6}>
          <Row className="ProjectTrackerButtons" justify="end">
            <div>
              <Button
                text="Actions"
                variant="tertiary"
                icon={<ArrowDownDarkIcon className="ArrowDownDark" />}
                onClick={() => setShowActionsMenu(true)}
              />
              <ProfileMenu show={showActionsMenu} onClose={() => setShowActionsMenu(false)} position={{ top: "0" }}>
                {actionsMenuOptionsList.map((el) => (
                  <ProfileMenuItem
                    key={el.id}
                    profileMenuItem={el}
                    onClick={() => {
                      if (el.action) {
                        el.action();
                      }
                      setShowActionsMenu(false);
                    }}
                  />
                ))}
              </ProfileMenu>
            </div>
            {/* <Button text="New project" size="small" onClick={() => setShowCreateProjectModal(true)} /> */}
          </Row>
        </Column>
      </Row>
      <div className="ProjectTrackerFilters">
        <h3>Filter by</h3>
        <MultiSelect
          label=" "
          className="TagsFilter"
          placeholder="Tags"
          data={tagsFilterSelectData}
          onChange={(keys) => onTagsFilter(keys)}
        />
        <Select
          label=" "
          className="QuickFilter"
          placeholder="Quick filters"
          data={quickFiltersSelectData}
          onChange={(keys) => onQuickFilter(keys)}
        />
      </div>

      <Row spacingV="xl">
        <Column span={12}>
          <div className={`ProjectTrackerDataGrid ${hasData ? "" : "NoData"}`}>
            <CursorDataGrid
              columns={columns}
              clientSide={false}
              filterable
              filterPosition="right"
              onChange={onChange}
              data={[]}
              refresh={refresh}
              noDataMessage="No projects to display"
              dataIsLoading={isLoading}
              infiniteScroll
              onExternalExport={exportTable}
              exportFileName={exportFileName}
              filterExportRows={filterExportRows}
              filterExportColumns={filterExportColumns}
              showTotalCount={false}
              showEditColumnsPanel={showEditColumnsPanel}
              closeEditColumnsPanel={() => setShowEditColumnsPanel(false)}
              editColumnsLocalStorageItemName={projectTrackerLocalStorageItemName}
              editColumnsText="Select the columns to display in the tracker. Drag headings to rearrange their left-to-right order."
              defaultEditColumns={defaultEditColumns}
            />
          </div>
        </Column>
      </Row>
      {notesProjectUuid && (
        <NotesPanel projectUuid={notesProjectUuid} show={showNotesPanel} onClose={() => closeNotesPanel()} />
      )}
    </div>
  );
};

export default ProjectTracker;
