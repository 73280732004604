import "./EditNote.css";

import { ActionButtons, Column, ErrorBox, Row, TextArea, TextInput } from "../../../../widget";
import { InteractionNote } from "../../models";
import { useEditNote } from "./useEditNote";

interface EditNoteProps {
  note: InteractionNote;
  onClose: () => void;
}

export const EditNote = ({ note, onClose }: EditNoteProps): JSX.Element => {
  const { title, setTitle, content, setContent, isHandleSubmitLoading, handleSubmit, errors } = useEditNote({
    note,
    onClose,
  });

  return (
    <div className="EditNote">
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="l">
          <Column span={12}>
            <TextInput label="Title" name="Title" value={title} onChange={setTitle} />
          </Column>
        </Row>
        <Row spacingV="l">
          <Column span={12}>
            <TextArea
              label="Content"
              name="Content"
              value={content}
              onChange={setContent}
              maxLength={500}
              displayCharacterCount
            />
          </Column>
        </Row>
        <Row spacingV="l">
          <Column span={12}>
            <ActionButtons onCancel={onClose} isLoading={isHandleSubmitLoading} />
          </Column>
        </Row>
      </form>
    </div>
  );
};
