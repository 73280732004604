import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from "react";

import { ProjectDates, ProjectRoadmapData, SelectData } from "../../models";
import { updateProjectDetails } from "../../service/project";
import { ServiceError, Status } from "../../service/Shared";
import {
  addDaysToDate,
  jsonReviver,
  recursivelyValidateAndTransformDateToString,
  setValueFromFlattenedKey,
} from "../../utils";
import { Toast } from "../../widget";

interface StaticDates {
  designStart: Date | undefined;
  implementationStart: Date | undefined;
  ongoingMonitoringStart: Date | undefined;
}

interface UseEditDatesPanelProps {
  data: ProjectRoadmapData;
  setData: Dispatch<SetStateAction<ProjectRoadmapData | undefined>>;
  onClose: () => void;
}

interface UseEditDatesPanelReturnData {
  dates: ProjectDates;
  staticDates: StaticDates | undefined;
  subsequentFrequencySelectData: SelectData;
  errors: ServiceError[] | undefined;
  handleClosePanel: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  handleUpdateProjectDates: (date: Date | number | undefined, key: string) => void;
}

const subsequentFrequencySelectData = [
  { value: "5-yearly", key: 5 },
  { value: "10-yearly", key: 10 },
] as SelectData;

export const useEditDatesPanel = ({ data, setData, onClose }: UseEditDatesPanelProps): UseEditDatesPanelReturnData => {
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [staticDates, setStaticDates] = useState<StaticDates>();

  const [dates, setDates] = useState<ProjectDates>(data.dates);

  const handleClosePanel = (): void => {
    setErrors(undefined);
    onClose();
  };

  const handleUpdateProjectDates = (date: Date | number | undefined, key: string): ProjectDates => {
    const updatedDates = setValueFromFlattenedKey(key, date, dates) as ProjectDates;

    setDates(() => updatedDates);
    return updatedDates;
  };

  const maybeUpdateDatesWithStaticDates = (): ProjectDates => {
    const datesDeepCopy = JSON.parse(JSON.stringify(dates), jsonReviver) as ProjectDates;

    if (
      datesDeepCopy.design?.end !== undefined &&
      staticDates?.designStart?.getTime() !== datesDeepCopy.design?.start?.getTime()
    ) {
      datesDeepCopy.design.start = staticDates?.designStart;
    }
    if (
      datesDeepCopy.implementation?.end !== undefined &&
      staticDates?.implementationStart?.getTime() !== datesDeepCopy.implementation?.start?.getTime()
    ) {
      datesDeepCopy.implementation.start = staticDates?.implementationStart;
    }
    if (
      datesDeepCopy.ongoingMonitoring?.end !== undefined &&
      staticDates?.ongoingMonitoringStart?.getTime() !== datesDeepCopy.ongoingMonitoring?.start?.getTime()
    ) {
      datesDeepCopy.ongoingMonitoring.start = staticDates?.ongoingMonitoringStart;
    }

    return datesDeepCopy;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors(undefined);

    const updatedDates = maybeUpdateDatesWithStaticDates();
    const transformedDates = recursivelyValidateAndTransformDateToString(updatedDates);

    if (transformedDates.errors.length > 0) {
      setErrors(transformedDates.errors);
      return;
    }

    const updateProjectDetailsRes = await updateProjectDetails({
      projectUuid: data.uuid,
      dates: JSON.stringify(transformedDates.data),
      rowVersion: data.rowVersion,
      addressCountryCode: null,
      displayName: null,
      locationCoordinates: null,
      locationGridReference: null,
      areaNetHa: null,
      validatorUuid: null,
    });

    if (updateProjectDetailsRes.status === Status.Success && updateProjectDetailsRes.data) {
      setData({
        ...data,
        dates: updatedDates,
        rowVersion: updateProjectDetailsRes.data.rowVersion,
      });

      Toast.success({ message: "Project dates successfully updated" });
      handleClosePanel();
    }
    if (updateProjectDetailsRes.status === Status.Error) {
      setErrors(updateProjectDetailsRes.errors);
    }
  };

  const updateStaticDates = useCallback(
    (newDates: ProjectDates) => {
      setStaticDates({
        designStart:
          (newDates.feasibility?.end ? addDaysToDate(newDates.feasibility?.end, 1) : undefined) ??
          newDates.design?.start,
        implementationStart:
          (newDates.design?.end ? addDaysToDate(newDates.design?.end, 1) : undefined) ?? newDates.implementation?.start,
        ongoingMonitoringStart:
          (newDates.implementation?.end ? addDaysToDate(newDates.implementation?.end, 1) : undefined) ??
          newDates.ongoingMonitoring?.start,
      });
    },
    [dates]
  );

  useEffect(() => {
    updateStaticDates(dates);
  }, [JSON.stringify(dates)]);

  // Update dates when uuid changes
  useEffect(() => {
    setDates(data.dates);
  }, [data.uuid]);

  return {
    dates,
    staticDates,
    subsequentFrequencySelectData,
    errors,
    handleClosePanel,
    handleSubmit,
    handleUpdateProjectDates,
  };
};
