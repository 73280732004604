import "./Projects.css";

import { DeveloperSingleCardWithTabsTemplate } from "../../../templates";
import { Column, KanaTooltip, Row } from "../../../widget";
import { DashboardPieCard } from "../components";
import { useProjects } from "./useProjects";

const Projects = (): JSX.Element => {
  const { tabsList, phaseChartData, isLoading, currentUserType } = useProjects();

  const kpis = (): JSX.Element | undefined => {
    return !isLoading ? (
      <div className="ProjectsPageKpiContainer">
        <Row>
          <Column span="5_half">
            <DashboardPieCard
              title="Project phase"
              data={phaseChartData}
              tooltip={
                <KanaTooltip
                  tooltipHeader="Project phase"
                  tooltipText="Project dates can be updated on the project overview page"
                />
              }
              isGradient
              colours="roadmap"
            />
          </Column>
        </Row>
      </div>
    ) : undefined;
  };

  return DeveloperSingleCardWithTabsTemplate({
    activeElement: "Projects",
    pageTitle: "Projects",
    tabsList,
    titlePill: "",
    developer: undefined,
    developerIcon: undefined,
    workflow: kpis(),
    backButtonText: undefined,
    handleBackClick: undefined,
    currentUserType,
  });
};

export default Projects;
