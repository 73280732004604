import "./MultiSelect.css";

import RCSelect, { Option } from "rc-select";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { CheckedBoxBlueIcon, DropdownLightIcon, UncheckedBoxLightIcon } from "../../../assets";
import { SelectData } from "../../../models";
import { FormLabel } from "../FormLabel";

export interface MultiSelectProps {
  label?: string;
  allowLabelDangerousHtml?: boolean;
  initialKey?: string[] | null;
  values?: string[] | null;
  placeholder?: string;
  className?: string;
  dropdownMatchSelectWidth?: boolean;
  data: SelectData;
  order?: string[];
  onChange: (values: string[]) => void;
  tooltip?: ReactNode;
}

export const MultiSelect = ({
  label,
  allowLabelDangerousHtml,
  initialKey,
  values,
  placeholder = "Please select",
  className,
  dropdownMatchSelectWidth,
  data,
  order,
  onChange,
  tooltip,
}: MultiSelectProps): JSX.Element => {
  const [selectedValues, setSelectedValues] = useState(values);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  const onChangeInternal = (vals: string[]): void => {
    setSelectedValues(vals);
    onChange(vals);
  };

  const orderedData = useMemo<SelectData>(() => {
    if (order) {
      const updatedData: SelectData = [];

      order?.forEach((ord) => {
        const option = data.find((d) => d.key === ord);

        if (option) {
          updatedData.push(option);
        }
      });

      return updatedData;
    }

    return data;
  }, [order, data]);

  const options = orderedData?.map((kvp) => (
    <Option key={kvp.key} value={kvp.key}>
      {selectedValues?.includes(kvp.key as string) ? <CheckedBoxBlueIcon /> : <UncheckedBoxLightIcon />}
      {kvp.value}
    </Option>
  ));

  return (
    <FormLabel label={label} tooltip={tooltip} tooltipJustify="left" allowDangerousHtml={allowLabelDangerousHtml}>
      <div className={`${className || ""} form_field`}>
        <RCSelect
          mode="multiple"
          defaultValue={initialKey}
          value={selectedValues}
          suffixIcon={<DropdownLightIcon />}
          placeholder={placeholder || undefined}
          onChange={onChangeInternal}
          prefixCls="MultiSelect"
          filterOption={false}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          dropdownAlign={{ overflow: { adjustY: 0 } }}
          removeIcon
        >
          {options}
        </RCSelect>
      </div>
    </FormLabel>
  );
};
