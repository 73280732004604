import "./TagProjects.css";

import { ActionButtons, Checkbox, Column, Divider, ErrorBox, Row } from "../../../../widget";
import { useTagProjects } from "./useTagProjects";

interface TagProjectsProps {
  onClose: () => void;
}
export const TagProjects = ({ onClose }: TagProjectsProps): JSX.Element => {
  const { currentTagProjects, errors, isHandleSubmitLoading, handleSubmit, handleTagProjectCheckboxChange } =
    useTagProjects({ onClose });
  return (
    <div className="TagProjects">
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        {!!currentTagProjects && currentTagProjects?.length > 0 ? (
          currentTagProjects?.map((tagProject) => (
            <div key={tagProject.projectUuid}>
              <Row spacingV="mm">
                <Column span={12}>
                  <div className="ProjectNameAndCheckbox">
                    <Checkbox
                      checked={tagProject.checked}
                      onChange={(e) => handleTagProjectCheckboxChange(e.target.checked, tagProject.projectUuid)}
                    />
                    <p className="body1Light">{tagProject.projectName}</p>
                  </div>
                </Column>
              </Row>
              <Row spacingV="mm">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
            </div>
          ))
        ) : (
          <Row spacingV="ll">
            <Column span={12}>
              <p className="body1 NoTags">No projects to display</p>
            </Column>
          </Row>
        )}
        <ActionButtons onCancel={onClose} isLoading={isHandleSubmitLoading} />
      </form>
    </div>
  );
};
