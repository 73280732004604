// TODO temporarily disable eslint on this file until v1.0 Unwired is complete/services are used
/* eslint @typescript-eslint/no-unused-vars: "off" */
/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint @typescript-eslint/no-non-null-assertion: "off" */
// ProjectService
import { jsonReviver, recursivelyValidateAndTransformDateToString } from "../../utils";
import { axios } from "../axios";
import { BaseResponse, BaseResponseWithMultiple, BaseResponseWithSingle, Status } from "../Shared";
import {
  AddOrRemoveProjectsFromGroupRequest,
  CreateProjectRequest,
  CreateProjectResponse,
  DeleteListingFileRequest,
  DeleteProjectDocumentVersionRequest,
  DeleteProjectRequest,
  DownloadProjectDocumentRequest,
  GetProjectListingPreviewRequest,
  GetProjectListingPreviewResponse,
  MergeProjectsRequest,
  MergeProjectsResponse,
  SaveProjectListingPreviewRequest,
  SaveProjectListingPreviewResponse,
  SetProjectIssuancesRequest,
  SetProjectIssuancesResponse,
  UpdateIssuancePricesRequest,
  UpdateIssuancePricesResponse,
  UpdateProjectDetailsRequest,
  UpdateProjectDetailsResponse,
  UpdateProjectDocumentRequest,
  UpdateProjectDocumentResponse,
  UpdateProjectDocumentVersionRequest,
  UpdateProjectDocumentVersionResponse,
  UpdateProjectGroupRequest,
  UpdateProjectGroupResponse,
  UpdateProjectListingRequest,
  UpdateProjectListingResponse,
  UpdateProjectStatusRequest,
  UpdateProjectStatusResponse,
  UpdateStandardSpecificDataRequest,
  UpdateStandardSpecificDataResponse,
  UploadListingFileRequest,
  UploadListingFileResponse,
  UploadProjectDocumentRequest,
  UploadProjectDocumentResponse,
  UploadProjectLocationRequest,
  UploadProjectLocationResponse,
} from "./Types";

export const uploadListingFile = async (
  req: UploadListingFileRequest
): Promise<BaseResponseWithSingle<UploadListingFileResponse>> => {
  const formData = new FormData();

  formData.append("File", req.file);
  formData.append("ProjectUuid", req.projectUuid);

  return axios
    .request<BaseResponseWithSingle<UploadListingFileResponse>>({
      method: "post",
      url: "project/listing/file",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateIssuancePrices = async (
  req: UpdateIssuancePricesRequest[]
): Promise<BaseResponseWithSingle<UpdateIssuancePricesResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateIssuancePricesResponse>>({
      method: "post",
      url: "project/issuances/prices",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectDetails = async (
  req: UpdateProjectDetailsRequest
): Promise<BaseResponseWithSingle<UpdateProjectDetailsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectDetailsResponse>>({
      method: "put",
      url: "project/details",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectListing = async (
  req: UpdateProjectListingRequest
): Promise<BaseResponseWithSingle<UpdateProjectListingResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectListingResponse>>({
      method: "put",
      url: "project/listing",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteListingFile = async (req: DeleteListingFileRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "project/listing/file",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const getProjectListingPreview = async (
  req: GetProjectListingPreviewRequest
): Promise<BaseResponseWithSingle<GetProjectListingPreviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<GetProjectListingPreviewResponse>>({
      method: "get",
      url: `project/listing/preview?PreviewUuid=${req.previewUuid}`,
    })
    .then((response) => {
      if (response.data.data?.content) {
        response.data.data.content = JSON.parse(response.data.data.content, jsonReviver);
      }
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const saveProjectListingPreview = async (
  req: SaveProjectListingPreviewRequest
): Promise<BaseResponseWithSingle<SaveProjectListingPreviewResponse>> => {
  return axios
    .request<BaseResponseWithSingle<SaveProjectListingPreviewResponse>>({
      method: "post",
      url: "project/listing/preview",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const setProjectIssuances = async (
  req: SetProjectIssuancesRequest
): Promise<BaseResponseWithMultiple<SetProjectIssuancesResponse>> => {
  return axios
    .request<BaseResponseWithMultiple<SetProjectIssuancesResponse>>({
      method: "post",
      url: "project/issuances",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const createProject = async (
  req: CreateProjectRequest
): Promise<BaseResponseWithSingle<CreateProjectResponse>> => {
  return axios
    .request<BaseResponseWithSingle<CreateProjectResponse>>({
      method: "post",
      url: "project",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const mergeProjects = async (
  req: MergeProjectsRequest
): Promise<BaseResponseWithSingle<MergeProjectsResponse>> => {
  return axios
    .request<BaseResponseWithSingle<MergeProjectsResponse>>({
      method: "post",
      url: "project/merge",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectStatus = async (
  req: UpdateProjectStatusRequest
): Promise<BaseResponseWithSingle<UpdateProjectStatusResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectStatusResponse>>({
      method: "put",
      url: "project/status",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteProject = async (req: DeleteProjectRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "project",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const deleteProjectDocumentVersion = async (req: DeleteProjectDocumentVersionRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "delete",
      url: "project/document/version/delete",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectDocument = async (
  req: UpdateProjectDocumentRequest
): Promise<BaseResponseWithSingle<UpdateProjectDocumentResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectDocumentResponse>>({
      method: "patch",
      url: "project/document/update",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectDocumentVersion = async (
  req: UpdateProjectDocumentVersionRequest
): Promise<BaseResponseWithSingle<UpdateProjectDocumentVersionResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectDocumentVersionResponse>>({
      method: "patch",
      url: "project/document/version/update",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const uploadProjectDocument = async (
  req: UploadProjectDocumentRequest
): Promise<BaseResponseWithSingle<UploadProjectDocumentResponse>> => {
  const formData = new FormData();

  formData.append("Visibility", req.visibility);

  if (req.projectDocumentUuid !== null) formData.append("ProjectDocumentUuid", req.projectDocumentUuid);
  if (req.documentTypeUuid !== null) formData.append("DocumentTypeUuid", req.documentTypeUuid);
  if (req.documentTypeVariantUuid !== null) formData.append("DocumentTypeVariantUuid", req.documentTypeVariantUuid);
  if (req.documentCustomVariant !== null) formData.append("DocumentCustomVariant", req.documentCustomVariant);
  if (req.groupUuid !== null) formData.append("GroupUuid", req.groupUuid);
  if (req.projectUuid !== null) formData.append("ProjectUuid", req.projectUuid);
  if (req.targetOrganisationUuids !== null)
    for (let i = 0; i < req.targetOrganisationUuids.length!; i++) {
      formData.append("TargetOrganisationUuids", req.targetOrganisationUuids[i]);
    }
  for (let i = 0; i < req.files.length; i++) {
    formData.append("Files", req.files[i].file, req.files[i].file.name);
  }

  return axios
    .request<BaseResponseWithSingle<UploadProjectDocumentResponse>>({
      method: "post",
      url: "project/document/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const uploadProjectLocation = async (
  req: UploadProjectLocationRequest
): Promise<BaseResponseWithSingle<UploadProjectLocationResponse>> => {
  const formData = new FormData();
  formData.append("ProjectUuid", req.projectUuid);
  formData.append("File", req.file, req.file.name);

  if (req.rowVersion !== null) formData.append("RowVersion", req.rowVersion.toString());
  if (req.projectLocationUuid !== null) formData.append("ProjectLocationUuid", req.projectLocationUuid);
  if (req.rowVersion !== null) formData.append("RowVersion", req.rowVersion.toString());

  return axios
    .request<BaseResponseWithSingle<UploadProjectLocationResponse>>({
      method: "post",
      url: "project/location/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error?.errors || [],
      };
    });
};

export const downloadProjectDocument = async (
  req: DownloadProjectDocumentRequest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<BaseResponseWithSingle<any>> => {
  const url = `project/document/download?ProjectDocumentHistoryUuid=${req.projectDocumentHistoryUuid}`;
  return axios
    .request({
      method: "get",
      url,
      data: req,
      responseType: "blob",
      timeout: 120000,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error?.errors || [],
      };
    });
};

export const updateStandardSpecificData = async (
  req: UpdateStandardSpecificDataRequest
): Promise<BaseResponseWithSingle<UpdateStandardSpecificDataResponse>> => {
  const transformedData = recursivelyValidateAndTransformDateToString(req.standardSpecificData);

  if (transformedData.errors.length) {
    return {
      status: Status.Error,
      errors: transformedData.errors || [],
    };
  }

  req.standardSpecificData = JSON.stringify(transformedData.data);

  return axios
    .request<BaseResponseWithSingle<UpdateStandardSpecificDataResponse>>({
      method: "put",
      url: "project/standard-specific-data",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const updateProjectGroup = async (
  req: UpdateProjectGroupRequest
): Promise<BaseResponseWithSingle<UpdateProjectGroupResponse>> => {
  return axios
    .request<BaseResponseWithSingle<UpdateProjectGroupResponse>>({
      method: "put",
      url: "project/group",
      data: req,
    })
    .then((response) => {
      return {
        status: Status.Success,
        data: response.data.data,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};

export const addOrRemoveProjectsFromGroup = async (req: AddOrRemoveProjectsFromGroupRequest): Promise<BaseResponse> => {
  return axios
    .request<BaseResponse>({
      method: "post",
      url: "project/group/projects",
      data: req,
    })
    .then(() => {
      return {
        status: Status.Success,
      };
    })
    .catch((error) => {
      return {
        status: Status.Error,
        errors: error.errors || [],
      };
    });
};
