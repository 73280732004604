import { Dispatch, FormEvent, SetStateAction, useContext, useState } from "react";

import { InteractionObjectType, InteractionType } from "../../../../constants";
import { InteractionTagData } from "../../../../models";
import { createInteraction, deleteInteraction, updateInteraction } from "../../../../service/interaction";
import { CreateInteractionResponse, UpdateInteractionResponse } from "../../../../service/interaction/Types";
import { BaseResponse, BaseResponseWithSingle, ServiceError, Status } from "../../../../service/Shared";
import { useAuth } from "../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../utils";
import { ProfileMenuItemType, Toast } from "../../../../widget";
import { InteractionTag, NewTag } from "../../models";
import { TagsContext } from "../../TagsContext";

interface UseManageTagsProps {
  onClose: () => void;
}

interface UseManageTagsReturnData {
  errors: ServiceError[] | undefined;
  tagOptionsList: ProfileMenuItemType[];
  showTagOptionsListUuid: string | undefined;
  setShowTagOptionsListUuid: Dispatch<SetStateAction<string | undefined>>;

  currentTags: InteractionTag[] | undefined;
  newTags: NewTag[];
  handleNewTextboxChange: (text: string, tagId: number) => void;
  handleCurrentTextboxChange: (text: string, tagUuid: string) => void;
  handleAddNewTag: () => void;

  isHandleSubmitLoading: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}
export const useManageTags = ({ onClose }: UseManageTagsProps): UseManageTagsReturnData => {
  const { currentOrganisationUuid } = useAuth();
  const {
    errors,
    setErrors,

    initTags,
    setInitTags,

    currentTags,
    setCurrentTags,

    newTags,
    setNewTags,
    handleAddNewTag,

    setShowTagProjectsPanelUuid,
    handleNewTextboxChange,
  } = useContext(TagsContext);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);
  const [showTagOptionsListUuid, setShowTagOptionsListUuid] = useState<string>();

  const handleCurrentTextboxChange = (text: string, tagUuid: string): void => {
    if (currentTags) {
      const updatedTags = currentTags.map((tag) => (tag.uuid === tagUuid ? { ...tag, data: { name: text } } : tag));

      setCurrentTags(updatedTags);
    }
  };

  const handleEditableTag = (tagUuid: string): void => {
    setCurrentTags(currentTags?.map((tag) => (tagUuid === tag.uuid ? { ...tag, editable: true } : tag)));
  };

  const handleDeleteTag = (tagUuid: string): void => {
    setCurrentTags(currentTags?.map((tag) => (tagUuid === tag.uuid ? { ...tag, deleted: true } : tag)));
  };

  const handleFilterDeletedTags = (): void => {
    setCurrentTags(currentTags?.filter((tag) => !tag.deleted));
  };

  const handleSetInitTagsToCurrentTags = (): void => {
    setInitTags(currentTags);
  };

  const handleAddNewTagsIntoInitAndCurrentTags = (filteredNewTags: NewTag[], uuids: string[]): void => {
    const newInteractionsTags = filteredNewTags.map(
      (newTag, index) =>
        ({
          uuid: uuids[index],
          data: { name: newTag.name },
          checked: false,
          rowVersion: 1,
        }) as InteractionTag
    );

    setCurrentTags((prevCurrentTags) => [...(prevCurrentTags ?? []), ...newInteractionsTags]);
    setInitTags((prevInitTags) => [...(prevInitTags ?? []), ...newInteractionsTags]);
    setNewTags([]);
  };

  const tagOptionsList: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "Edit tag",
      action: (args) => handleEditableTag(args?.uuid),
    },
    {
      id: 2,
      value: "Delete tag",
      action: (args) => handleDeleteTag(args?.uuid),
    },
    {
      id: 3,
      value: "Assign to project",
      action: (args) => setShowTagProjectsPanelUuid(args?.uuid),
    },
  ];

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    // i = 0: updateInteraction | i = 1: deleteInteraction | i = 2: createInteraction
    const promises: (
      | Promise<BaseResponse>
      | Promise<BaseResponseWithSingle<CreateInteractionResponse>>
      | Promise<BaseResponseWithSingle<UpdateInteractionResponse>>
      | null
    )[] = [null, null, null];

    // Check for edited tags, if any add updateInteraction to promises
    const editedTags =
      currentTags?.filter(
        (currentTag) =>
          currentTag.deleted !== true &&
          initTags?.some((initTag) => initTag.uuid === currentTag.uuid && initTag.data.name !== currentTag.data.name)
      ) ?? [];
    if (editedTags.length > 0) {
      promises[0] = updateInteraction({
        interactions: editedTags.map((tag) => ({
          data: JSON.stringify({ name: tag.data.name }),
          interactionUuid: tag.uuid,
          rowVersion: tag.rowVersion,
        })),
      });
    }

    // Check for deleted tags, if any add deletedInteraction to promises
    const deleteTagUuids =
      currentTags?.filter((currentTag) => currentTag.deleted === true).map((tag) => tag.uuid) ?? [];
    if (deleteTagUuids.length > 0) {
      promises[1] = deleteInteraction({
        interactionUuids: deleteTagUuids,
      });
    }

    const filteredNewTags = newTags.filter((tag) => tag.name.length > 0);
    // Check for new tags, if any add createInteraction to promises
    if (filteredNewTags.length > 0 && currentOrganisationUuid) {
      setNewTags(filteredNewTags);
      promises[2] = createInteraction({
        interactions: filteredNewTags.map((tag) => ({
          data: JSON.stringify({ name: tag.name } as InteractionTagData),
          objectType: InteractionObjectType.ORGANISATION,
          objectUuid: currentOrganisationUuid,
          type: InteractionType.TAG,
        })),
      });
    }

    // Await all promises in parallel
    // We can use Promise.all here as we still return fulfilled promises when the promise fails
    const results = await Promise.all(promises);

    const resultsHaveError = results.some((res) => res?.status === Status.Error);

    // Destructuring + type assertion
    const [updateInteractionRes, deleteInteractionRes, createInteractionRes] = results as [
      BaseResponseWithSingle<UpdateInteractionResponse> | null,
      BaseResponse | null,
      BaseResponseWithSingle<CreateInteractionResponse> | null,
    ];

    if (updateInteractionRes?.status === Status.Success) {
      Toast.success({ message: "Tags updated successfully" });
      // Reset changed tags if one of the other promises fails so we don't run this again
      if (resultsHaveError) {
        handleSetInitTagsToCurrentTags();
      }
    } else if (updateInteractionRes?.status === Status.Error) {
      setErrors(updateInteractionRes.errors);
    }

    if (deleteInteractionRes?.status === Status.Success) {
      Toast.success({ message: "Tags deleted successfully" });
      // Remove deleted tags if one of the other promises fails so we don't run this again
      if (resultsHaveError) {
        handleFilterDeletedTags();
      }
    } else if (deleteInteractionRes?.status === Status.Error) {
      setErrors((prevErrors) => [...(prevErrors ?? []), ...(deleteInteractionRes.errors ?? [])]);
    }

    if (createInteractionRes?.status === Status.Success) {
      Toast.success({ message: "Tags created successfully" });
      // Reset new tags if one of the other promises fails so we don't run this again
      if (resultsHaveError) {
        handleAddNewTagsIntoInitAndCurrentTags(filteredNewTags, createInteractionRes.data?.uuids ?? []);
      }
    } else if (createInteractionRes?.status === Status.Error) {
      setErrors((prevErrors) => [...(prevErrors ?? []), ...(createInteractionRes.errors ?? [])]);
    }

    if (!resultsHaveError) onClose();
  }, setIsHandleSubmitLoading);

  return {
    errors,
    tagOptionsList,
    showTagOptionsListUuid,
    setShowTagOptionsListUuid,

    currentTags,
    newTags,
    handleNewTextboxChange,
    handleCurrentTextboxChange,
    handleAddNewTag,
    isHandleSubmitLoading,
    handleSubmit,
  };
};
