import "./EditDatesPanel.css";

import { Dispatch, SetStateAction } from "react";

import { ProjectRoadmapData } from "../../models";
import { ActionButtons, Column, ErrorBox, FlyoutPanel, Row } from "../../widget";
import { DesignSection, FeasibilitySection, ImplementationSection, OngoingMonitoringSection } from "./components";
import { useEditDatesPanel } from "./useEditDatesPanel";

interface EditDatesPanelProps {
  data: ProjectRoadmapData;
  setData: Dispatch<SetStateAction<ProjectRoadmapData | undefined>>;
  show: boolean;
  onClose: () => void;
}

export const EditDatesPanel = ({ data, setData, show, onClose }: EditDatesPanelProps): JSX.Element => {
  const {
    dates,
    staticDates,
    subsequentFrequencySelectData,
    errors,
    handleClosePanel,
    handleSubmit,
    handleUpdateProjectDates,
  } = useEditDatesPanel({ data, setData, onClose });
  const content = (): JSX.Element => {
    return (
      <div className="EditDatesPanelContent">
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="between">
            <Column span={12}>
              <ErrorBox text={errors.map((error) => error.message)} />
            </Column>
          </Row>
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <FeasibilitySection dates={dates} handleUpdateProjectDates={handleUpdateProjectDates} />
          <DesignSection dates={dates} staticDates={staticDates} handleUpdateProjectDates={handleUpdateProjectDates} />
          <ImplementationSection
            dates={dates}
            staticDates={staticDates}
            handleUpdateProjectDates={handleUpdateProjectDates}
          />
          <OngoingMonitoringSection
            staticDates={staticDates}
            subsequentFrequencySelectData={subsequentFrequencySelectData}
            dates={dates}
            handleUpdateProjectDates={handleUpdateProjectDates}
          />
          <ActionButtons submitButtonLabel="Apply" onCancel={handleClosePanel} />
        </form>
      </div>
    );
  };

  return (
    <FlyoutPanel
      show={show}
      onClose={handleClosePanel}
      size="Large"
      header="Edit phase dates"
      buttonsChildren={undefined}
      contentChildren={content()}
    />
  );
};
