import "./NotesPanel.css";

import { ReactElement } from "react";

import { TrashDark } from "../../assets";
import { GenericModal } from "../../route/developer/components";
import { convertDateToStringDDMMYYYY } from "../../utils";
import { Button, Column, Divider, FlyoutPanel, Row } from "../../widget";
import { EditNote, NewNote } from "./components";
import { useNotesPanel } from "./useNotesPanel";

interface NotesPanelProps {
  projectUuid: string;
  show: boolean;
  onClose: () => void;
}

export const NotesPanel = ({ projectUuid, show, onClose }: NotesPanelProps): JSX.Element => {
  const {
    notes,
    currentNote,
    isOnConfirmDeleteNoteLoading,
    showEditNotePanel,
    showNewNotePanel,
    showDeleteNoteModal,
    setShowDeleteNoteModal,
    setShowNewNotePanel,
    onConfirmDeleteNote,
    onClickEditNote,
    closePanel,
  } = useNotesPanel({ projectUuid, onClose });

  const content = (): ReactElement => {
    if (showNewNotePanel) return <NewNote projectUuid={projectUuid} onClose={closePanel} />;
    if (showEditNotePanel && currentNote) return <EditNote note={currentNote} onClose={closePanel} />;

    return (
      <div className="NotesPanelContent">
        {!!notes && notes?.length > 0 ? (
          notes?.map((note) => (
            <div key={note.uuid}>
              <div className="NoteHeader">
                <p className="body01">{note.data.title}</p>
                <p className="body1 Date">{convertDateToStringDDMMYYYY(note.createdAt)}</p>
              </div>
              <Row spacingV="s">
                <Column span={12}>
                  <p className="body1Light NoteContent">{note.data.content}</p>
                </Column>
              </Row>
              <div className="NoteFooter">
                <Button
                  text="Edit"
                  variant="reversedPrimary"
                  textUnderline
                  size="small"
                  textBold
                  hasNoPadding
                  onClick={() => onClickEditNote(note)}
                />
              </div>
              <Row spacingV="ll">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
            </div>
          ))
        ) : (
          <Row spacingV="ll">
            <Column span={12}>
              <p className="body1 NoNotes">There are currently no notes for this project</p>
            </Column>
          </Row>
        )}
        <Button text="New note" variant="primary" onClick={() => setShowNewNotePanel(true)} />
      </div>
    );
  };

  const buttons = (): ReactElement | undefined => {
    return showEditNotePanel ? (
      <Button
        className="DeleteNoteButton"
        text="Delete"
        variant="reversedPrimary"
        size="small"
        icon={<TrashDark />}
        iconPosition="left"
        textBold
        hasNoPadding
        onClick={() => setShowDeleteNoteModal(true)}
      />
    ) : undefined;
  };

  return (
    <>
      <FlyoutPanel
        show={show}
        onClose={closePanel}
        size="ExtraSmall"
        header={(() => {
          if (showNewNotePanel) return "New note";
          if (showEditNotePanel) return "Edit note";
          return "Notes";
        })()}
        buttonsChildren={buttons()}
        contentChildren={content()}
      />
      <GenericModal
        show={showDeleteNoteModal}
        onConfirm={onConfirmDeleteNote}
        closeModal={() => setShowDeleteNoteModal(false)}
        data={{
          title: "Delete note?",
          text: ["This action cannot be undone"],
          confirmText: "Yes",
          cancelText: "No",
        }}
        isLoading={isOnConfirmDeleteNoteLoading}
      />
    </>
  );
};
