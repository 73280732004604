import "../styles/DataGrid.css";

import ReactDataGrid, { Column as RdgColumn, SortColumn } from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { Button, Toggle } from "../../../forms";
import { Column, Row } from "../../../layout";
import { CursorPagination } from "../../../wayfinding";
import { EditColumnsPanel } from "../components/EditColumnsPanel";
import { DataGridConstants } from "../constants";
import { FilterContext } from "../context";
import { ColumnDefinition, EditColumns, OnCursorChangeEventHandler } from "../models";
import { useComputeDataGridHeight } from "../utils";
import { useCursorDataGrid } from "./useCursorDataGrid";

type FilterPosition = "left" | "right";

export interface CursorDataGridProps {
  data: ResultData[];
  columns: ColumnDefinition[];
  // client-side filtering & sorting
  clientSide: boolean;
  // grid-level sorting enabled?
  sortable?: boolean;
  // grid-level filtering enabled?
  filterable?: boolean;
  // paging enabled?
  pageable?: boolean;
  // show total count enabled?
  showTotalCount?: boolean;
  /*
   Used for server-side sorting/filtering/paging.
   Only raised if `clientSide = false`.
   */
  onChange?: OnCursorChangeEventHandler;
  /*
    Configuration for the pagination
   */
  pagination?: {
    // mandatory for server-side
    totalCount?: number;
    pageSize?: number;
    startCursor?: string;
    endCursor?: string;
  };
  noDataMessage?: string;
  dataIsLoading?: boolean;
  defaultSortingCriteria?: SortColumn[];
  refresh?: boolean;
  triggerPagePrevious?: boolean;
  onRowClick?: (e: ResultData) => void;
  showFilterToggle?: boolean;
  filterPosition?: FilterPosition;
  // export table button
  exportable?: boolean;
  exportFileName?: string;
  onExternalExport?: boolean;
  filterExportRows?: (columns: ResultData[]) => ResultData[];
  filterExportColumns?: (columns: RdgColumn<ResultData, unknown>[]) => RdgColumn<ResultData, unknown>[];
  // pagination on vertical scroll
  infiniteScroll?: boolean;
  // change the order, enable & disable data grid columns, store in local storage
  showEditColumnsPanel?: boolean;
  closeEditColumnsPanel?: () => void;
  editColumnsLocalStorageItemName?: string;
  editColumnsText?: string;
  defaultEditColumns?: EditColumns;
}

export const CursorDataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  clientSide = false,
  pageable = true,
  showTotalCount = true,
  defaultSortingCriteria,
  onChange,
  pagination = { pageSize: 10 },
  noDataMessage,
  dataIsLoading,
  refresh,
  onRowClick,
  showFilterToggle,
  filterPosition = "left",
  triggerPagePrevious,
  onExternalExport,
  exportable = false,
  exportFileName,
  filterExportRows,
  filterExportColumns,
  infiniteScroll = false,
  showEditColumnsPanel = false,
  closeEditColumnsPanel,
  editColumnsLocalStorageItemName,
  editColumnsText = "Select the columns to display in the table. Drag headings to rearrange their left-to-right order.",
  defaultEditColumns,
}: CursorDataGridProps): JSX.Element => {
  const {
    resultData,
    filterCriteria,
    setFilterCriteria,
    filteredRdgColumns,
    sortColumns,
    onSort,
    onPrevious,
    onNext,
    hasNext,
    hasPrevious,
    totalCount,
    filtersEnabled,
    setFiltersEnabled,
    showFilterToggleSwitch,
    onExport,
    onScroll,
    onRefreshColumns,
  } = useCursorDataGrid({
    data,
    columns,
    filterable,
    sortable,
    clientSide,
    pageable,
    defaultSortingCriteria,
    onChange,
    pagination,
    refresh,
    showFilterToggle,
    triggerPagePrevious,
    onExternalExport,
    exportFileName,
    filterExportRows,
    filterExportColumns,
    infiniteScroll,
    editColumnsLocalStorageItemName,
  });

  const { computedHeight } = useComputeDataGridHeight(resultData.length, DataGridConstants.rowHeight);

  const exportButtons = (): JSX.Element => {
    const colCount = showFilterToggleSwitch ? 4 : 3;
    return (
      <Row spacingV="s" className="ExportButtonsContainer">
        <Column span={colCount}>
          <Button text="Export audit" onClick={onExport} />
        </Column>
      </Row>
    );
  };

  const filterButtons = (): JSX.Element => {
    // eslint-disable-next-line no-nested-ternary
    const colCount = filterPosition === "right" ? 12 : showFilterToggleSwitch ? 4 : 3;
    const colJustify = filterPosition === "right" ? "end" : undefined;
    const rowSpacing = filterPosition === "right" ? "xl" : "s";
    const labelAlignment = filterPosition === "right" ? "left" : "right";
    return (
      <Row spacingV={rowSpacing}>
        <Column span={colCount} className="FilterButtonsContainer" justify={colJustify}>
          {showFilterToggleSwitch && (
            <Toggle
              label={`${filtersEnabled ? "Hide filters" : "Show filters"}`}
              labelAlignment={labelAlignment}
              value={filtersEnabled}
              onChange={() => {
                if (filtersEnabled) {
                  setFilterCriteria([]);
                }
                setFiltersEnabled(!filtersEnabled);
              }}
            />
          )}
          {filterCriteria.length > 0 && (
            <button
              className="BtnLink"
              onClick={() => {
                setFilterCriteria([]);
              }}
            >
              Clear all filters
            </button>
          )}
        </Column>
      </Row>
    );
  };

  return (
    <div className={`DataGridContainer${onRowClick ? " ClickableRows" : ""}${infiniteScroll ? " InfiniteScroll" : ""}`}>
      {resultData && (!!resultData.length || (!resultData.length && !!filterCriteria.length)) ? (
        <>
          {exportable && exportButtons()}
          {filterable && filterButtons()}
          <div onScroll={onScroll} className={`DataGrid ${filtersEnabled ? "FiltersApplied" : ""}`}>
            <Row spacingV={pageable ? "ml" : undefined}>
              <Column span={12}>
                <FilterContext.Provider value={filterCriteria}>
                  <ReactDataGrid
                    style={{
                      height: computedHeight,
                    }}
                    rows={resultData}
                    columns={filteredRdgColumns}
                    defaultColumnOptions={{
                      sortable,
                    }}
                    className="rdg-light"
                    rowHeight={DataGridConstants.rowHeight}
                    headerRowHeight={filterable && filtersEnabled ? 2 * DataGridConstants.rowHeight : undefined}
                    sortColumns={sortColumns}
                    onSortColumnsChange={onSort}
                    onRowClick={onRowClick}
                  />
                </FilterContext.Provider>
              </Column>
            </Row>
            {showTotalCount && (
              <Row spacingV={pageable ? "ml" : undefined}>
                <div className="DataGridResultCount">
                  <span className="body2">{totalCount} results</span>
                </div>
              </Row>
            )}
            {!infiniteScroll && (
              <Row>
                <Column span={12}>
                  {pageable && (
                    <CursorPagination
                      onPrevious={onPrevious}
                      onNext={onNext}
                      hasNext={hasNext}
                      hasPrevious={hasPrevious}
                    />
                  )}
                </Column>
              </Row>
            )}
          </div>
        </>
      ) : (
        <p className="body2 NoData">{dataIsLoading ? "Loading..." : noDataMessage}</p>
      )}
      {closeEditColumnsPanel !== undefined && editColumnsLocalStorageItemName && (
        <EditColumnsPanel
          masterColumns={columns}
          editColumnsLocalStorageItemName={editColumnsLocalStorageItemName}
          editColumnsText={editColumnsText}
          defaultEditColumns={defaultEditColumns}
          show={showEditColumnsPanel}
          onClose={closeEditColumnsPanel}
          onRefreshColumns={onRefreshColumns}
        />
      )}
    </div>
  );
};
