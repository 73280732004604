import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { ChartWrapperOptions, GoogleVizEventName, ReactGoogleChartEvent } from "react-google-charts";

import { ProjectRoadmapData } from "../../../models";
import { ChartReferenceNodes, ProjectRoadmapRowLabelData, ViewMode } from "./models";
import {
  addMarkerAndSetScrollPosition,
  adjustTimeline,
  getFormatFromViewMode,
  getProjectRoadmapRowLabelData,
  getProjectRoadmapTimelineData,
  getTopLevelRoadmapSvg,
  onScroll,
  resetChartHeight,
  setBarMinWidth,
  updateTooltipPosition,
} from "./utils";

interface useProjectRoadmapReturnData {
  projectRoadmapRef: RefObject<HTMLDivElement>;
  projectRoadmapRowLabelsRef: RefObject<HTMLDivElement>;

  rowLabelData: ProjectRoadmapRowLabelData;
  chartData: unknown[];

  options: ChartWrapperOptions["options"];
  chartEvents: ReactGoogleChartEvent[];
  height?: number;
  viewMode: ViewMode;
  onChangeViewMode: (viewMode: ViewMode) => void;
  onScroll: () => void;
}

interface useProjectRoadmapProps {
  data: ProjectRoadmapData[];
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const useProjectRoadmap = ({ data, setIsLoading }: useProjectRoadmapProps): useProjectRoadmapReturnData => {
  const projectRoadmapRef = useRef<HTMLDivElement | null>(null);
  const projectRoadmapRowLabelsRef = useRef<HTMLDivElement | null>(null);

  const [chartData, setChartData] = useState(getProjectRoadmapTimelineData(data));
  const [rowLabelData, setRowLabelData] = useState(getProjectRoadmapRowLabelData(data));

  const [height, setHeight] = useState<number>();
  const [shouldResetChartHeight, setShouldResetChartHeight] = useState(true);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Years3);

  const onChangeViewMode = (newViewMode: ViewMode): void => {
    setIsLoading(true);
    setViewMode(newViewMode);
  };

  // Store reference to tooltip and timeline elements for easy access later
  const chartReferenceNodes: ChartReferenceNodes = {};

  const options: ChartWrapperOptions["options"] = {
    tooltip: { isHtml: true },
    timeline: {
      showRowLabels: false,
      showBarLabels: false,
      barLabelStyle: { fontSize: 17.622 }, // Change font size to change width of chart row
    },
    hAxis: {
      textStyle: {
        fontSize: 16,
      },
      format: getFormatFromViewMode(viewMode),
    },
    allowHtml: true,
    alternatingRowStyle: false,
    avoidOverlappingGridLines: false,
  };

  // Listen to when the charts "ready" event fires (after it's been rendered)
  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const chart = chartWrapper?.getChart() as any;
        google.visualization.events.addListener(chart, "onmouseover" as GoogleVizEventName, () => {
          setBarMinWidth(chartReferenceNodes);
        });
        google.visualization.events.addListener(chart, "onmouseout" as GoogleVizEventName, () => {
          setBarMinWidth(chartReferenceNodes);
        });
        // Callback to show tooltip on select based on https://stackoverflow.com/a/32260578 (not possible on timeline chart by default)
        google.visualization.events.addListener(chart, "select", () => {
          // Find the built in tooltip
          const tooltip = projectRoadmapRef.current?.querySelector(
            ".google-visualization-tooltip:not([clone])"
          ) as HTMLElement | null;

          // remove previous clone when there is any
          if (chart.tooltipClone && chart.tooltipClone.parentNode) {
            chart.tooltipClone.parentNode.removeChild(chart.tooltipClone);
          }

          // If chart height
          if (tooltip) {
            tooltip.style.height = height && height < 198 ? `${height}px` : "auto"; // set tooltip height;
          }

          // create a clone of the built-in tooltip
          chart.tooltipClone = tooltip?.cloneNode(true);
          chartReferenceNodes.tooltipClone = chart.tooltipClone; // store reference to clone

          // create a custom attribute to be able to distinguish
          // built-in tooltip and clone
          chart.tooltipClone.setAttribute("clone", true);

          // Update tooltip position
          updateTooltipPosition(projectRoadmapRef, chartReferenceNodes);

          chart.tooltipClone.style.pointerEvents = "auto";

          // inject clone into document
          tooltip?.parentNode?.insertBefore(chart.tooltipClone, chart.tooltip);

          setBarMinWidth(chartReferenceNodes);
        });

        // Get svg node
        const svgNode = getTopLevelRoadmapSvg(projectRoadmapRef);

        resetChartHeight(
          projectRoadmapRowLabelsRef,
          height,
          setHeight,
          shouldResetChartHeight,
          setShouldResetChartHeight,
          svgNode
        );
        addMarkerAndSetScrollPosition(projectRoadmapRef, viewMode, svgNode, height, data);
        adjustTimeline(projectRoadmapRef, chartReferenceNodes, svgNode);
        setBarMinWidth(chartReferenceNodes, svgNode);
        onScroll(projectRoadmapRef, chartReferenceNodes, height);

        setIsLoading(false);
      },
    },
  ];

  useEffect(() => {
    setShouldResetChartHeight(true); // reset chart height

    setChartData(getProjectRoadmapTimelineData(data));
    setRowLabelData(getProjectRoadmapRowLabelData(data));
  }, [JSON.stringify(data)]);

  return {
    chartData,
    rowLabelData,

    projectRoadmapRef,
    projectRoadmapRowLabelsRef,

    options,
    chartEvents,
    height,
    viewMode,
    onChangeViewMode,
    onScroll: () => onScroll(projectRoadmapRef, chartReferenceNodes, height),
  };
};
