import "./ProjectListingScore.css";

import {
  EyeGreyIcon,
  LongDescriptionIcon,
  PhotoDarkIcon,
  ProjectListingScore0,
  ProjectListingScore1,
  ProjectListingScore2,
  ProjectListingScore3,
  ProjectListingScore4,
  ProjectListingScore5,
  ShortDescriptionIcon,
  TagIconDarkIcon,
  TickGreenIcon,
} from "../../../../assets";
import { ProjectListingScore } from "../../../../models";
import { GenericTooltip } from "../../../forms";

interface ProjectListingScoreProps {
  projectListingScoreString: string;
}

const projectListingScoreKPI = (projectListingScoreCount: number): JSX.Element => {
  switch (projectListingScoreCount) {
    case 1:
      return <ProjectListingScore1 />;
    case 2:
      return <ProjectListingScore2 />;
    case 3:
      return <ProjectListingScore3 />;
    case 4:
      return <ProjectListingScore4 />;
    case 5:
      return <ProjectListingScore5 />;
    case 0:
    default:
      return <ProjectListingScore0 />;
  }
};

const projectListingScoreTooltip = (projectListingScore: ProjectListingScore): JSX.Element => {
  return (
    <div className="ProjectListingScoreTooltip">
      <div className="ProjectListingScoreTooltipSection">
        <EyeGreyIcon strokeWidth={1.4} width={16} height={16} />
        <p className="body2">Public visibility</p>
        {!projectListingScore.hidden && <TickGreenIcon className="TickIcon" />}
      </div>
      <div className="ProjectListingScoreTooltipSection">
        <PhotoDarkIcon />
        <p className="body2">Photos</p>
        {projectListingScore.images && <TickGreenIcon className="TickIcon" />}
      </div>
      <div className="ProjectListingScoreTooltipSection">
        <ShortDescriptionIcon />
        <p className="body2">Short description</p>
        {projectListingScore.shortDescription && <TickGreenIcon className="TickIcon" />}
      </div>
      <div className="ProjectListingScoreTooltipSection">
        <LongDescriptionIcon />
        <p className="body2">Long description</p>
        {projectListingScore.longDescription && <TickGreenIcon className="TickIcon" />}
      </div>
      <div className="ProjectListingScoreTooltipSection">
        <TagIconDarkIcon />
        <p className="body2">Unit availability</p>
        {projectListingScore.availableForSale && <TickGreenIcon className="TickIcon" />}
      </div>
    </div>
  );
};

export const ProjectListingScoreKPI = ({ projectListingScoreString }: ProjectListingScoreProps): JSX.Element => {
  const projectListingScore = JSON.parse(projectListingScoreString);

  return (
    <div className="ProjectListingScoreKPI">
      <GenericTooltip content={projectListingScoreTooltip(projectListingScore as ProjectListingScore)}>
        {projectListingScoreKPI(
          Object.keys(projectListingScore).filter((key) =>
            key === "hidden" ? !projectListingScore[key] : projectListingScore[key]
          ).length
        )}
      </GenericTooltip>
    </div>
  );
};
