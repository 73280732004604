export enum InteractionObjectType {
  PROJECT = "Project",
  ORGANISATION = "Organisation",
}

export enum ObjectInteractionObjectType {
  PROJECT = "Project",
  ORGANISATION = "Organisation",
}

export enum InteractionType {
  TAG = "Tag",
  NOTE = "Note",
}
