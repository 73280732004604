import "./ActivityOverviewPage.css";

import { OrganisationTypeConstants } from "../../../../constants";
import { FileType } from "../../../../models";
import { DeveloperSingleCardWithTabsTemplate, NotesPanel } from "../../../../templates";
import { VersionConflictModal } from "../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { checkCanManageActivity } from "../../../../utils";
import { Column, ProfileMenu, ProfileMenuItem, QuickActionsMenu, Row, Workflow } from "../../../../widget";
import { DashboardPieCard } from "../../components";
import { useActivityOverviewPage } from "./useActivityOverviewPage";

export const ActivityOverviewPage = (): JSX.Element => {
  const {
    setShowVersionConflictModal,
    setShowQuickActionMenu,
    setShowNotesPanel,
    shouldRefreshActivities,
    handleBackClick,
    isExternalActivity,
    tabsList,
    activityDetails,
    projectDetails,
    discussionsChartData,
    workflowActivity,
    showVersionConflictModal,
    draftActivityHistoryUuid,
    newVersionActivityHistory,
    hasManageProjectActivityPermission,
    currentUserType,
    backButtonText,
    pageTitle,
    showQuickActionMenu,
    quickActionsMenuOptionsList,
    showNotesPanel,
  } = useActivityOverviewPage();

  const kpis = (): JSX.Element => {
    return (
      <Row>
        <Column span="7_half">
          <Workflow
            activities={workflowActivity ? [workflowActivity] : []}
            status={workflowActivity?.status}
            canManageActivity={checkCanManageActivity(hasManageProjectActivityPermission, workflowActivity?.status)}
            isExternal={isExternalActivity()}
            location="Activity"
            shouldRefreshActivities={shouldRefreshActivities}
          />
        </Column>
        <Column span="4_half" style={{ paddingRight: 0 }}>
          <DashboardPieCard
            data={discussionsChartData}
            title="Discussions"
            colours="discussionsKPI"
            noDataMessage="No discussions"
            size={150}
            showValues={false}
          />
        </Column>
      </Row>
    );
  };

  const quickActions = (): JSX.Element | undefined => {
    return quickActionsMenuOptionsList.length > 0 ? (
      <div className="ActivityOverviewPageQuickActions">
        <QuickActionsMenu onClick={() => setShowQuickActionMenu(true)} />
        <ProfileMenu show={showQuickActionMenu} onClose={() => setShowQuickActionMenu(false)} position={{ top: "0" }}>
          {quickActionsMenuOptionsList.map((el) => (
            <ProfileMenuItem
              key={el.id}
              profileMenuItem={el}
              onClick={() => {
                if (el.action) {
                  el.action();
                }
                setShowQuickActionMenu(false);
              }}
            />
          ))}
        </ProfileMenu>
      </div>
    ) : undefined;
  };

  if (!projectDetails || !activityDetails) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <>
      {DeveloperSingleCardWithTabsTemplate({
        activeElement: "Activities",
        pageTitle,
        titlePill: projectDetails?.standard?.displayName,
        developer:
          currentUserType !== OrganisationTypeConstants.DEVELOPER ? projectDetails?.developer.displayName : undefined,
        developerIcon:
          currentUserType !== OrganisationTypeConstants.DEVELOPER
            ? projectDetails?.developer.files.find((f) => f.type === FileType.SmallLogo)?.file.url
            : undefined,
        tabsList,
        workflow: kpis(),
        backButtonText,
        quickActions: quickActions(),
        handleBackClick,
      })}
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
      {activityDetails.project?.uuid && (
        <NotesPanel
          projectUuid={activityDetails.project.uuid}
          show={showNotesPanel}
          onClose={() => setShowNotesPanel(false)}
        />
      )}
    </>
  );
};
