/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./Location.css";

import React from "react";

import { LocationPlaceholderIcon, PenEditButtonIcon, UploadLightIcon } from "../../../../../assets";
// eslint-disable-next-line unused-imports/no-unused-imports
import {
  Button,
  Column,
  ErrorBox,
  MapBox,
  MapControl,
  MapLayerOptions,
  MapOpenLayer,
  Row,
} from "../../../../../widget";
import { UploadLocationDocumentModal } from "./components/UploadDocumentModal";
import { useLocation } from "./useLocation";

const Location = (): JSX.Element => {
  const {
    locationData,
    selectedLayer,
    gisFile,
    is3d,
    setIs3d,
    changeSelectedLayer,
    isToDrawBoundary,
    setIsToDrawBoundary,
    isToUploadManually,
    setIsToUploadManually,
    saveBoundaryFile,
    isToSaveBoundary,
    setIsToSaveBoundary,
    clearDrawBoundaryToggle,
    cleanShape,
    errors,
  } = useLocation();

  return (
    <div className="ProjectTabSection">
      <Row spacingV="2xl">
        <Column span={12}>
          <h2>Location</h2>
        </Column>
      </Row>
      {errors && errors.length > 0 && (
        <Row spacingV="ll">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      {locationData.latitude && locationData.longitude ? (
        <>
          <Row spacingV="2xl" justify="between">
            <Column span={12}>
              <span>
                Use the mapping tool to explore your project location in detail. Toggle data layers from within the
                drop-down menu to gain insights into the site’s environmental conditions or progress. Note: only the
                base layer is available in the 3D terrain view.
              </span>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <div className="map-container">
                <MapControl
                  is3d={is3d}
                  onChange3dView={(e1) => {
                    setIs3d(e1);
                    if (e1) {
                      changeSelectedLayer(MapLayerOptions.BASE);
                    }
                  }}
                  onChangeLayer={(e2) => {
                    changeSelectedLayer(e2 as MapLayerOptions);
                  }}
                  disableSelection={selectedLayer === MapLayerOptions.BASE && is3d}
                  selectedLayer={selectedLayer}
                  cleanShape={cleanShape}
                  setIsToDrawBoundary={setIsToDrawBoundary}
                  setIsToUploadManually={setIsToUploadManually}
                  setIsToSaveBoundary={setIsToSaveBoundary}
                />
                {is3d === true ? (
                  <MapBox
                    projectShape={{
                      shapeFileUrl: gisFile.projectShape?.geoJsonFileUrl || "",
                      fillColor: "#FF865D",
                    }}
                    coordinate={{ lat: Number(locationData.latitude!), lng: Number(locationData.longitude!) }}
                    is3d={is3d}
                  />
                ) : (
                  <MapOpenLayer
                    coordinate={{ lat: Number(locationData.latitude!), lng: Number(locationData.longitude!) }}
                    gisFile={gisFile}
                    drawLayer={isToDrawBoundary}
                    exportBoundary={saveBoundaryFile}
                    clearDrawBoundaryToggle={clearDrawBoundaryToggle}
                    isToSaveBoundary={isToSaveBoundary}
                  />
                )}
              </div>
            </Column>
          </Row>
          <UploadLocationDocumentModal
            show={isToUploadManually}
            closeModal={() => setIsToUploadManually(false)}
            saveBoundaryFile={(file) => {
              saveBoundaryFile(undefined, file);
              setIsToUploadManually(false);
            }}
          />
        </>
      ) : (
        <>
          <Row spacingV="s" justify="center">
            <Column span={12}>
              <LocationPlaceholderIcon />
              <h2>Create your project boundary</h2>
            </Column>
          </Row>
          <Row spacingV="2xl" justify="center">
            <Column span={12}>
              <span>Add a shape file to plot your boundaries on the map and bring your project to life</span>
            </Column>
          </Row>
          <Row spacingV="2xl" className="ShapeBtnWrapperRow">
            <div className="ShapeBtnsWrapper">
              <div>
                <Button
                  className="UploadShapeBtn"
                  size="small"
                  text="Upload shape file"
                  icon={<UploadLightIcon />}
                  onClick={() => {
                    setIsToUploadManually(true);
                    setIsToDrawBoundary(false);
                  }}
                />
              </div>
              <div className="DrawShapeBtnWrapper">
                <Button
                  className="DrawShapeBtn"
                  size="small"
                  text="Draw project boundary"
                  variant="tertiary"
                  icon={<PenEditButtonIcon />}
                  onClick={() => {
                    setIsToUploadManually(false);
                    setIsToDrawBoundary(true);
                  }}
                />
              </div>
            </div>
          </Row>
        </>
      )}
    </div>
  );
};

export const LocationTab = React.memo(Location);
