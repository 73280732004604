import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { InteractionObjectType, InteractionType } from "../../../../constants";
import { InteractionNoteData } from "../../../../models";
import { createInteraction } from "../../../../service/interaction";
import { ServiceError, Status } from "../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";

interface UseNewNoteProps {
  projectUuid: string;
  onClose: () => void;
}

interface UseNewNoteReturnData {
  title: string | undefined;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
  content: string | undefined;
  setContent: Dispatch<SetStateAction<string | undefined>>;
  isHandleSubmitLoading: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  errors: ServiceError[] | undefined;
}

export const useNewNote = ({ projectUuid, onClose }: UseNewNoteProps): UseNewNoteReturnData => {
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<string>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    const res = await createInteraction({
      interactions: [
        {
          data: JSON.stringify({ title, content } as InteractionNoteData),
          objectType: InteractionObjectType.PROJECT,
          objectUuid: projectUuid,
          type: InteractionType.NOTE,
        },
      ],
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Note created successfully" });
      onClose();
    }
    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    title,
    setTitle,
    content,
    setContent,
    isHandleSubmitLoading,
    handleSubmit,
    errors,
  };
};
