import { useEffect, useState } from "react";

import { Toast } from "../../../../general";
import { ColumnDefinition, EditColumns } from "../../models";

interface EditColumnDefinition extends ColumnDefinition {
  show: boolean;
  index: number;
}

interface UseEditColumnsPanelProps {
  masterColumns: ColumnDefinition[];
  editColumnsLocalStorageItemName: string;
  defaultEditColumns?: EditColumns;
  onRefreshColumns: () => void;
}

interface UseEditColumnsReturnData {
  editColumns: EditColumnDefinition[];
  onCheckboxChange: (checked: boolean, columnKey: string) => void;
  onColumnDrop: (droppedOnColumnKey: string, draggedColumnKey: string) => void;
}

export const useEditColumnsPanel = ({
  masterColumns,
  editColumnsLocalStorageItemName,
  defaultEditColumns,
  onRefreshColumns,
}: UseEditColumnsPanelProps): UseEditColumnsReturnData => {
  const [editColumns, setEditColumns] = useState<EditColumnDefinition[]>([]);
  // For tracking local storage, so we don't need to keep fetching
  const [localStorageColumnsObject, setLocalStorageColumnsObject] = useState<EditColumns>();

  const setAndSortEditColumns = (columns: EditColumnDefinition[]): void =>
    setEditColumns([...columns].sort((a, b) => a.index - b.index));

  /**
   * Handles the change event for the checkbox associated with a column.
   * Updates local storage and refreshes the columns view.
   * @param isChecked - The new checked state of the checkbox.
   * @param columnKey - The key of the column whose checkbox state has changed.
   */
  const onCheckboxChange = (isChecked: boolean, columnKey: string): void => {
    if (localStorageColumnsObject) {
      const columnsObject = { ...localStorageColumnsObject } as EditColumns;
      columnsObject[columnKey].show = isChecked;
      localStorage.setItem(editColumnsLocalStorageItemName, JSON.stringify(columnsObject));

      setLocalStorageColumnsObject(columnsObject);
      setAndSortEditColumns(
        masterColumns.map((column) => ({
          ...column,
          show: columnsObject[column.key].show,
          index: columnsObject[column.key].index,
        }))
      );
      onRefreshColumns();
      Toast.success({ message: "Table columns updated" });
    }
  };

  /**
   * Update the local storage with the new order of columns after a drag and drop event
   * @param {string} droppedOnColumnKey - The key of the column that was dropped on
   * @param {string} draggedColumnKey - The key of the column that was dragged
   */
  const onColumnDrop = (droppedOnColumnKey: string, draggedColumnKey: string): void => {
    if (localStorageColumnsObject) {
      const columnsObject = { ...localStorageColumnsObject } as EditColumns;

      const draggedColumnIndex = columnsObject[draggedColumnKey].index;
      const droppedOnColumnIndex = columnsObject[droppedOnColumnKey].index;

      // Update the index of the dragged column
      columnsObject[draggedColumnKey].index = columnsObject[droppedOnColumnKey].index;

      // Update the index of the other columns
      Object.keys(columnsObject).forEach((columnKey) => {
        if (columnKey !== draggedColumnKey) {
          const columnIndex = columnsObject[columnKey].index;

          if (draggedColumnIndex > droppedOnColumnIndex) {
            // Dragging upwards
            if (columnIndex >= droppedOnColumnIndex && columnIndex < draggedColumnIndex) {
              columnsObject[columnKey].index += 1;
            }
          } else if (columnIndex <= droppedOnColumnIndex && columnIndex > draggedColumnIndex) {
            // Dragging downwards
            columnsObject[columnKey].index -= 1;
          }
        }
      });

      localStorage.setItem(editColumnsLocalStorageItemName, JSON.stringify(columnsObject));
      setLocalStorageColumnsObject(columnsObject);
      setAndSortEditColumns(
        masterColumns.map((column) => ({
          ...column,
          show: columnsObject[column.key].show,
          index: columnsObject[column.key].index,
        }))
      );
      onRefreshColumns();
      Toast.success({ message: "Table columns updated" });
    }
  };

  useEffect(() => {
    const localStorageColumns = localStorage.getItem(editColumnsLocalStorageItemName);
    // Initialize local storage with defaultEditColumns / masterColumns if not already set, else filter based on stored object
    if (!localStorageColumns) {
      const initialColumnsObject =
        defaultEditColumns ||
        (Object.fromEntries(masterColumns.map((column, index) => [column.key, { show: true, index }])) as EditColumns);

      localStorage.setItem(editColumnsLocalStorageItemName, JSON.stringify(initialColumnsObject));

      setLocalStorageColumnsObject(initialColumnsObject);
      setEditColumns(
        masterColumns.map((column) => ({
          ...column,
          show: initialColumnsObject[column.key].show,
          index: initialColumnsObject[column.key].index,
        }))
      );
    } else {
      let columnsObject = JSON.parse(localStorageColumns) as EditColumns;

      // Need to force refresh if user's local column storage is out of sync with masterColumns
      if (masterColumns.some((column) => !columnsObject[column.key])) {
        columnsObject =
          defaultEditColumns ||
          (Object.fromEntries(
            masterColumns.map((column, index) => [column.key, { show: true, index }])
          ) as EditColumns);

        localStorage.setItem(editColumnsLocalStorageItemName, JSON.stringify(columnsObject));
      }

      setLocalStorageColumnsObject(columnsObject);
      setAndSortEditColumns(
        masterColumns.map((column) => ({
          ...column,
          show: columnsObject[column.key].show,
          index: columnsObject[column.key].index,
        }))
      );
    }
    onRefreshColumns();
  }, []);

  return {
    onCheckboxChange,
    onColumnDrop,
    editColumns,
  };
};
