import "./NewNote.css";

import { ActionButtons, Column, ErrorBox, Row, TextArea, TextInput } from "../../../../widget";
import { useNewNote } from "./useNewNote";

interface NewNoteProps {
  projectUuid: string;
  onClose: () => void;
}

export const NewNote = ({ projectUuid, onClose }: NewNoteProps): JSX.Element => {
  const { title, setTitle, content, setContent, isHandleSubmitLoading, handleSubmit, errors } = useNewNote({
    projectUuid,
    onClose,
  });

  return (
    <div className="NewNote">
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="l">
          <Column span={12}>
            <TextInput label="Title" name="Title" value={title} onChange={setTitle} />
          </Column>
        </Row>
        <Row spacingV="l">
          <Column span={12}>
            <TextArea label="Content" name="Content" value={content ?? ""} onChange={setContent} maxLength={500} />
          </Column>
        </Row>
        <Row spacingV="l">
          <Column span={12}>
            <ActionButtons onCancel={onClose} isLoading={isHandleSubmitLoading} />
          </Column>
        </Row>
      </form>
    </div>
  );
};
