import { Dispatch, SetStateAction, useContext, useState } from "react";

import { DiscussionVisibilityConstants, OrganisationTypeConstants } from "../../../../../../constants";
import { createDiscussionThread } from "../../../../../../service/interaction";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import { useActivityDashboardContextSwitcher } from "../../../../utils/activities/useActivityDashboardContextSwitcher";
import { ActivityDiscussionContext } from "../../ActivityDiscussionContext";

interface UseNewDiscussionModalProps {
  closeModal: () => void;
  internalDiscussion?: boolean;
  setInternalDiscussion: Dispatch<SetStateAction<boolean | undefined>>;
}

interface UseNewDiscussionModalReturnData {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  externalUserType: string;
  errors: ServiceError[] | undefined;
  handleCloseModal: () => void;
  handleCreateThread: () => Promise<void>;
  isHandleCreateThreadLoading: boolean;
}

export const useNewDiscussionModal = ({
  closeModal,
  internalDiscussion,
  setInternalDiscussion,
}: UseNewDiscussionModalProps): UseNewDiscussionModalReturnData => {
  const { currentOrganisationUuid, currentUserType } = useAuth();
  const { activityUuid, refreshResolvedDiscussionsKPI } = useActivityDashboardContextSwitcher();
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleCreateThreadLoading, setIsHandleCreateThreadLoading] = useState(false);

  const externalUserType =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? OrganisationTypeConstants.VERIFIER
      : OrganisationTypeConstants.DEVELOPER;

  const { refreshDiscussionTables } = useContext(ActivityDiscussionContext);

  const handleCloseModal = (): void => {
    setMessage("");
    setErrors(undefined);
    setInternalDiscussion(undefined);
    closeModal();
  };

  const internalMessageTargetOrganisationRoles =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? ["Developer", "Guest - Read", "Guest - Write"]
      : ["Validator"];

  const handleCreateThread = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await createDiscussionThread({
      componentKey: null,
      dataPath: null,
      messageContent: message,
      messageObjectType: "Activity",
      messageObjectUuid: activityUuid ?? "",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      messageSourceOrganisationUuid: currentOrganisationUuid!,
      messageType: "Comment",
      messageStatus: "Published",
      threadObjectType: "Activity",
      threadObjectUuid: activityUuid ?? "",
      threadType: "Discussion",
      visibility: internalDiscussion ? DiscussionVisibilityConstants.INTERNAL : DiscussionVisibilityConstants.EXTERNAL,
      messageTargetOrganisationRoles: internalDiscussion
        ? internalMessageTargetOrganisationRoles
        : ["Developer", "Validator", "Guest - Read", "Guest - Write"],
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Discussion created" });
      refreshDiscussionTables();

      refreshResolvedDiscussionsKPI();
      handleCloseModal();
    }

    if (res.status === Status.Error && res.errors) {
      setErrors(res.errors);
    }
  }, setIsHandleCreateThreadLoading);

  return {
    message,
    setMessage,
    externalUserType,
    errors,
    handleCloseModal,
    handleCreateThread,
    isHandleCreateThreadLoading,
  };
};
