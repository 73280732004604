import { Option } from "rc-select";
import { useEffect, useMemo, useState } from "react";

import { CheckedBoxBlueIcon, UncheckedBoxLightIcon } from "../../../assets";
import { SelectData } from "../../../models";

interface useAutocompleteMultiSelectProps {
  values?: string[] | null;
  //   initialKey?: string | null;
  data: SelectData;
  order?: string[];
  onChange: (values: string[]) => void;
  onTypeSearch?: (value?: string) => void;
}

interface useAutocompleteMultiSelectReturnData {
  selectedValues: string[] | null | undefined;
  searchValue: string | undefined;
  onChangeInternal: (vals: string[]) => void;
  options: JSX.Element[];
  handleSearch: (newVal: string) => void;
}

const TYPE_SEARCH_WAITING_TIME = 100;

export const useAutocompleteMultiSelect = ({
  values,
  data,
  order,
  onChange,
  onTypeSearch,
}: useAutocompleteMultiSelectProps): useAutocompleteMultiSelectReturnData => {
  const [selectedValues, setSelectedValues] = useState(values);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<JSX.Element[]>(
    data.map((kvp) => (
      <Option key={kvp.key} value={kvp.key}>
        {selectedValues?.includes(kvp.key as string) ? <CheckedBoxBlueIcon /> : <UncheckedBoxLightIcon />}
        {kvp.value}
      </Option>
    ))
  );

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  const onChangeInternal = (vals: string[]): void => {
    setSelectedValues(vals);
    onChange(vals);
  };

  const orderedData = useMemo<SelectData>(() => {
    if (order) {
      const updatedData: SelectData = [];

      order?.forEach((ord) => {
        const option = data.find((d) => d.key === ord);

        if (option) {
          updatedData.push(option);
        }
      });
      return updatedData;
    }

    return data;
  }, [order, data]);

  useEffect(() => {
    setOptions(
      orderedData.map((kvp) => (
        <Option key={kvp.key} value={kvp.key}>
          {selectedValues?.includes(kvp.key as string) ? <CheckedBoxBlueIcon /> : <UncheckedBoxLightIcon />}
          {kvp.value}
        </Option>
      ))
    );
  }, [orderedData]);

  const handleSearch = (newVal: string): void => {
    const filteredData = orderedData.filter((kvp) =>
      kvp.value?.toString().toLowerCase().includes(newVal.toLowerCase())
    );
    setOptions(
      filteredData.map((kvp) => (
        <Option key={kvp.key} value={kvp.key}>
          {selectedValues?.includes(kvp.key as string) ? <CheckedBoxBlueIcon /> : <UncheckedBoxLightIcon />}
          {kvp.value}
        </Option>
      ))
    );
    setSearchValue(newVal);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (onTypeSearch) {
        onTypeSearch(searchValue === "" ? undefined : searchValue);
      }
    }, TYPE_SEARCH_WAITING_TIME);

    return () => clearTimeout(debounce);
  }, [searchValue]);

  return {
    searchValue,
    selectedValues,
    onChangeInternal,
    options,
    handleSearch,
  };
};
