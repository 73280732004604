import { FormEvent, useContext, useState } from "react";

import { InteractionObjectType, InteractionType } from "../../constants";
import { InteractionTagData } from "../../models";
import { addOrRemoveObjectInteractions, createInteraction } from "../../service/interaction";
import { ServiceError, Status } from "../../service/Shared";
import { useAuth } from "../../useAuth";
import { useIsLoadingWrapper } from "../../utils";
import { Toast } from "../../widget";
import { InteractionTag, NewTag } from "./models";
import { TagsContext } from "./TagsContext";

interface UseTagsPanelProps {
  projectUuid: string;
  onClose: () => void;
  shouldRefreshTags: () => void;
}

interface UseTagsPanelReturnData {
  currentTags: InteractionTag[] | undefined;
  newTags: NewTag[];
  errors: ServiceError[] | undefined;
  isHandleSubmitLoading: boolean;
  showTagProjectsPanelUuid: string | undefined;

  showManageTagsPanel: boolean;
  handleShowManageTagsPanel: () => void;

  handleClosePanel: () => void;
  handleCurrentCheckboxChange: (isChecked: boolean, tagUuid: string) => void;
  handleNewCheckboxChange: (isChecked: boolean, tagId: number) => void;
  handleNewTextboxChange: (text: string, tagId: number) => void;
  handleAddNewTag: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

export const useTagsPanel = ({
  projectUuid,
  onClose,
  shouldRefreshTags,
}: UseTagsPanelProps): UseTagsPanelReturnData => {
  const { currentOrganisationUuid } = useAuth();
  const {
    initTags,
    currentTags,
    setCurrentTags,

    newTags,
    setNewTags,
    handleAddNewTag,

    showManageTagsPanel,
    setShowManageTagsPanel,

    showTagProjectsPanelUuid,
    setShowTagProjectsPanelUuid,

    refresh,
    setRefresh,

    errors,
    setErrors,

    handleNewTextboxChange,
  } = useContext(TagsContext);

  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleClosePanel = (): void => {
    if (showTagProjectsPanelUuid) {
      setShowTagProjectsPanelUuid(undefined);
    }
    if (showManageTagsPanel) {
      setShowManageTagsPanel(false);
    }
    shouldRefreshTags();
    setRefresh(!refresh);
    setErrors(undefined);
    onClose();
  };

  const handleShowManageTagsPanel = (): void => {
    setRefresh((prevRefresh) => !prevRefresh);
    setErrors(undefined);
    setShowManageTagsPanel(true);
  };

  const handleCurrentCheckboxChange = (isChecked: boolean, tagUuid: string): void => {
    if (currentTags) {
      const updatedTags = currentTags.map((tag) => (tag.uuid === tagUuid ? { ...tag, checked: isChecked } : tag));

      setCurrentTags(updatedTags);
    }
  };

  const handleNewCheckboxChange = (isChecked: boolean, tagId: number): void => {
    if (newTags) {
      setNewTags(newTags.map((tag) => (tag.id === tagId ? { ...tag, checked: isChecked } : tag)));
    }
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    let newCheckedTags: InteractionTag[] = [];
    const filteredNewTags = newTags.filter((tag) => tag.name.length > 0);
    // Create new tags if any
    if (filteredNewTags.length > 0 && currentOrganisationUuid) {
      // Filter out empty tags and update newTags
      setNewTags(filteredNewTags);

      const createInteractionRes = await createInteraction({
        interactions: filteredNewTags.map((tag) => ({
          data: JSON.stringify({ name: tag.name } as InteractionTagData),
          objectType: InteractionObjectType.ORGANISATION,
          objectUuid: currentOrganisationUuid,
          type: InteractionType.TAG,
        })),
      });

      if (createInteractionRes.status === Status.Success) {
        Toast.success({ message: "Tags created successfully" });
      }

      if (createInteractionRes.status === Status.Error) {
        setErrors(createInteractionRes.errors);
        return;
      }

      // Add uuids to new tags and format as InteractionTag
      newCheckedTags = createInteractionRes.data?.uuids
        ? (newCheckedTags = filteredNewTags.map(
            (tag, index) =>
              ({
                uuid: createInteractionRes.data?.uuids[index],
                data: { name: tag.name },
                checked: tag.checked,
                rowVersion: 1,
              }) as InteractionTag
          ))
        : [];
    }

    // Update existing tags for object interactions, append any new tags that have been checked
    let changedTags =
      currentTags?.filter((currentTag) =>
        initTags?.some((initTag) => initTag.uuid === currentTag.uuid && initTag.checked !== currentTag.checked)
      ) ?? [];

    changedTags = [...changedTags, ...newCheckedTags];

    const addOrRemoveObjectInteractionsRes = await addOrRemoveObjectInteractions({
      objectInteractions:
        changedTags?.map((tag) => ({
          interactionUuid: tag.uuid,
          objectType: InteractionObjectType.PROJECT,
          objectUuid: projectUuid,
          operation: tag.checked === true ? "add" : "remove",
        })) ?? [],
    });

    if (addOrRemoveObjectInteractionsRes.status === Status.Success) {
      Toast.success({ message: "Project tags updated successfully" });
      handleClosePanel();
    }

    if (addOrRemoveObjectInteractionsRes.status === Status.Error) {
      setErrors(addOrRemoveObjectInteractionsRes.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    errors,
    newTags,
    currentTags,
    showTagProjectsPanelUuid,

    isHandleSubmitLoading,
    handleSubmit,

    showManageTagsPanel,
    handleShowManageTagsPanel,

    handleCurrentCheckboxChange,
    handleNewCheckboxChange,
    handleNewTextboxChange,
    handleAddNewTag,
    handleClosePanel,
  };
};
