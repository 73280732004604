import { ColorConstants } from "../../../../constants";

export type ProjectRoadmapRowLabelData = {
  displayName: string | undefined;
  uuid: string | undefined;
  status: string | undefined;
  additionalDates: boolean;
}[];

export type ChartReferenceNodes = {
  tooltipClone?: HTMLElement;
  timelineClone?: SVGElement;
  bars?: SVGElement;
  highlightBars?: SVGElement;
};

export const ProjectRoadmapColourMap: { [key: string]: string } = {
  Feasibility: ColorConstants.BLUE,
  Design: ColorConstants.CORAL,
  "Registration date": ColorConstants.CORAL,
  Implementation: ColorConstants.CYAN,
  "Implementation date": ColorConstants.CYAN,
  "Start date": ColorConstants.CYAN,
  "First assessment date": ColorConstants.CYAN,
  "Ongoing monitoring": ColorConstants.GREEN,
  "Second assessment date": ColorConstants.GREEN,
  "Final assessment date": ColorConstants.GREEN,
};

export enum ViewMode {
  Months3 = "200000px",
  Months6 = "100000px",
  Year = "50000px",
  Years3 = "15000px",
  Years10 = "5000px",
  Years20 = "2000px",
}
