import "./EditColumnsPanel.css";

import { HamburgerMenuGreyIcon } from "../../../../../assets";
import { Checkbox, Column, Row } from "../../../..";
import { Divider, FlyoutPanel } from "../../../../general";
import { ColumnDefinition, EditColumns } from "../../models";
import { useEditColumnsPanel } from "./useEditColumnsPanel";

interface EditColumnsPanelProps {
  masterColumns: ColumnDefinition[];
  editColumnsLocalStorageItemName: string;
  editColumnsText: string;
  defaultEditColumns?: EditColumns;
  show: boolean;
  onClose: () => void;
  onRefreshColumns: () => void;
}

export const EditColumnsPanel = ({
  masterColumns,
  editColumnsLocalStorageItemName,
  editColumnsText,
  defaultEditColumns,
  show,
  onClose,
  onRefreshColumns,
}: EditColumnsPanelProps): JSX.Element => {
  const { editColumns, onCheckboxChange, onColumnDrop } = useEditColumnsPanel({
    masterColumns,
    editColumnsLocalStorageItemName,
    defaultEditColumns,
    onRefreshColumns,
  });

  const content = (): JSX.Element => {
    return (
      <div className="EditColumnsPanelContent">
        <Row spacingV="ll">
          <Column span={12}>
            <p className="body1">{editColumnsText}</p>
          </Column>
        </Row>
        {editColumns.length > 0 &&
          editColumns.map((col) => (
            <div key={col.key}>
              <Row>
                <Column span={12}>
                  <div
                    className="EditColumnRow"
                    draggable
                    onDrop={(e) => onColumnDrop(col.key, e.dataTransfer.getData("columnKey"))}
                    onDragOver={(ev) => ev.preventDefault()}
                    onDragStart={(ev) => ev.dataTransfer.setData("columnKey", col.key)}
                  >
                    <HamburgerMenuGreyIcon className="HamburgerMenuIcon" />
                    <Checkbox checked={col.show} onChange={(e) => onCheckboxChange(e.target.checked, col.key)} />
                    <p className="body1">{col.name}</p>
                  </div>
                </Column>
              </Row>
              <Row>
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
            </div>
          ))}
      </div>
    );
  };

  return (
    <FlyoutPanel
      show={show}
      onClose={onClose}
      size="ExtraSmall"
      header="Edit columns"
      buttonsChildren={undefined}
      contentChildren={content()}
    />
  );
};
