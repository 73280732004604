import "./ManageTags.css";

import { EllipseGroupBlueIcon, PlusIcon } from "../../../../assets";
import {
  ActionButtons,
  Button,
  Column,
  Divider,
  ErrorBox,
  ProfileMenu,
  ProfileMenuItem,
  Row,
  TextInput,
} from "../../../../widget";
import { useManageTags } from "./useManageTags";

interface ManageTagsProps {
  onClose: () => void;
}
export const ManageTags = ({ onClose }: ManageTagsProps): JSX.Element => {
  const {
    errors,
    currentTags,
    newTags,
    tagOptionsList,
    showTagOptionsListUuid,
    setShowTagOptionsListUuid,
    handleNewTextboxChange,
    handleCurrentTextboxChange,
    handleAddNewTag,
    handleSubmit,
    isHandleSubmitLoading,
  } = useManageTags({ onClose });
  return (
    <div className="ManageTags">
      {errors && errors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row spacingV="ml">
          <Column span={12}>
            <h3>Tag</h3>
          </Column>
        </Row>
        <Row spacingV="s">
          <Column span={12}>
            <Divider type="horizontal" />
          </Column>
        </Row>
        {!!currentTags && currentTags?.length > 0 ? (
          currentTags?.map((tag) => (
            <div key={tag.uuid}>
              <Row spacingV="s">
                <Column span={12}>
                  <div className="TagNameAndOptions">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {tag.deleted ? (
                      <p className={`body1Light ${tag.deleted ? "Deleted" : ""}`}>{tag.data.name}</p>
                    ) : (
                      <>
                        {tag.editable ? (
                          <TextInput
                            name={tag.data.name}
                            value={tag.data.name}
                            onChange={(e) => handleCurrentTextboxChange(e, tag.uuid)}
                          />
                        ) : (
                          <p className="body1Light">{tag.data.name}</p>
                        )}
                        <Button
                          className="OptionsButton"
                          text=""
                          variant="reversedPrimary"
                          onClick={() => setShowTagOptionsListUuid(tag.uuid)}
                          hasNoPadding
                          icon={<EllipseGroupBlueIcon />}
                        />
                        <ProfileMenu
                          show={showTagOptionsListUuid === tag.uuid}
                          onClose={() => setShowTagOptionsListUuid(undefined)}
                          position={{ top: "0" }}
                        >
                          {tagOptionsList.map((el) => (
                            <ProfileMenuItem
                              key={el.id}
                              profileMenuItem={el}
                              onClick={() => {
                                if (el.action) {
                                  el.action({ uuid: tag.uuid });
                                }
                                setShowTagOptionsListUuid(undefined);
                              }}
                            />
                          ))}
                        </ProfileMenu>
                      </>
                    )}
                  </div>
                </Column>
              </Row>
              <Row spacingV="s">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
            </div>
          ))
        ) : (
          <Row spacingV="ll">
            <Column span={12}>
              <p className="body1 NoTags">Your organisation doesn&apos;t have any tags yet</p>
            </Column>
          </Row>
        )}
        {newTags.length > 0 &&
          newTags.map((tag) => (
            <div key={tag.id}>
              <Row spacingV="s">
                <Column span={12}>
                  <div className="NewTag">
                    <TextInput name={tag.name} value={tag.name} onChange={(e) => handleNewTextboxChange(e, tag.id)} />
                  </div>
                </Column>
              </Row>
              <Row spacingV="s">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
            </div>
          ))}
        <Row spacingV="ml">
          <Column span={12}>
            <Button
              className="AddTagButton"
              text="Add a new tag"
              variant="reversedPrimary"
              onClick={() => handleAddNewTag()}
              textUnderline
              textBold
              hasNoPadding
              icon={<PlusIcon />}
              iconPosition="left"
            />
          </Column>
        </Row>
        <ActionButtons onCancel={onClose} isLoading={isHandleSubmitLoading} />
      </form>
    </div>
  );
};
