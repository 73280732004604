import { Dispatch, FormEvent, SetStateAction, useState } from "react";

import { InteractionNoteData } from "../../../../models";
import { updateInteraction } from "../../../../service/interaction";
import { ServiceError, Status } from "../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../utils";
import { Toast } from "../../../../widget";
import { InteractionNote } from "../../models";

interface UseEditNoteProps {
  note: InteractionNote;
  onClose: () => void;
}

interface UseEditNoteReturnData {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  isHandleSubmitLoading: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;

  errors: ServiceError[] | undefined;
}

export const useEditNote = ({ note, onClose }: UseEditNoteProps): UseEditNoteReturnData => {
  const [errors, setErrors] = useState<ServiceError[] | undefined>([]);
  const [title, setTitle] = useState<string>(note.data.title);
  const [content, setContent] = useState<string>(note.data.content);
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setErrors([]);

    const res = await updateInteraction({
      interactions: [
        {
          data: JSON.stringify({ title, content } as InteractionNoteData),
          interactionUuid: note.uuid,
          rowVersion: note.rowVersion,
        },
      ],
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Note updated successfully" });
      onClose();
    }
    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSubmitLoading);

  return {
    title,
    setTitle,
    content,
    setContent,
    isHandleSubmitLoading,
    handleSubmit,

    errors,
  };
};
