import "./ProjectRoadmapPage.css";

import { EmptyRoadmapIcon, RoundedPlusSignIcon } from "../../../../assets";
import { EditDatesPanel } from "../../../../templates";
import { Button, Column, ErrorBox, ProjectRoadmap, Row } from "../../../../widget";
import { SelectProjectsModal } from "./components";
import { useProjectRoadmapPage } from "./useProjectRoadmapPage";

export const ProjectRoadmapPage = (): JSX.Element => {
  const {
    errors,
    data,
    projectsMultiSelectData,
    editDatesPanelData,
    showEditDatesPanel,
    setEditDatesPanelData,
    closeEditDatesPanel,
    roadmapProjectUuids,
    setRoadmapProjectUuids,
    showAddProjectsModal,
    setShowAddProjectsModal,
    closeSelectProjectsModal,
    onSaveProjectsSelectData,
    isLoading,
    setIsLoading,
    fetchSelectData,
  } = useProjectRoadmapPage();

  return (
    <div className="ProjectRoadmapPageContainer">
      <Row spacingV="ml">
        <Column span={12}>
          <h2>Project roadmap</h2>
        </Column>
      </Row>
      <Row spacingV="ml">
        <Column span={12}>
          <p className="body1">
            Only projects with associated dates can be added to the roadmap. To set dates, visit the project overview
            panel. You can edit dates directly by clicking on the dates in the timeline.
          </p>
        </Column>
      </Row>
      {errors && errors.length > 0 && (
        <Row spacingV="s">
          <Column span={12}>
            <ErrorBox text={errors.map((error) => error.message)} />
          </Column>
        </Row>
      )}
      {(data !== undefined && data.length > 0) || isLoading ? (
        <div className="PopulatedRoadmap">
          <div className="EditProjectsDropdownContainer">
            <Button
              className="EditProjectsButton"
              text="Edit projects"
              variant="tertiary"
              onClick={() => setShowAddProjectsModal(true)}
            />
          </div>
          <div style={{ height: 602 }}>
            <ProjectRoadmap data={data ?? []} isLoading={isLoading} setIsLoading={setIsLoading} />
          </div>
        </div>
      ) : (
        <div className="EmptyRoadmap">
          <EmptyRoadmapIcon />
          <h1>Your roadmap is almost ready</h1>
          <p className="body1">Add projects to populate your roadmap.</p>
          <Button
            className="AddProjectsButton"
            text="Add projects"
            icon={<RoundedPlusSignIcon />}
            onClick={() => setShowAddProjectsModal(true)}
          />
        </div>
      )}
      {editDatesPanelData && (
        <EditDatesPanel
          data={editDatesPanelData}
          setData={setEditDatesPanelData}
          show={showEditDatesPanel}
          onClose={closeEditDatesPanel}
        />
      )}
      <SelectProjectsModal
        roadmapProjectUuids={roadmapProjectUuids}
        setRoadmapProjectUuids={setRoadmapProjectUuids}
        projectsMultiSelectData={projectsMultiSelectData}
        onSaveProjectsSelectData={onSaveProjectsSelectData}
        show={showAddProjectsModal}
        closePanel={closeSelectProjectsModal}
        fetchSelectData={fetchSelectData}
      />
    </div>
  );
};
