import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { DiscussionVisibilityConstants, OrganisationTypeConstants } from "../../../../../constants";
import { useActivityWizardContextSwitcher } from "../../../../../route/shared/utils/activities/useActivityWizardContextSwitcher";
import { createDiscussionMessage, createDiscussionThread } from "../../../../../service/interaction";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserFullName, useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../general";
import { Thread } from "../../../models";
import { NewThreadInformation } from "../NewDiscussion/useNewDiscussion";

interface UseDiscussionThreadBodyReturnData {
  currentUserUuid: string | undefined;
  newDiscussionErrors: ServiceError[] | undefined;
  showNewDiscussionTextArea: boolean;
  setShowNewDiscussionTextArea: Dispatch<SetStateAction<boolean>>;
  newDiscussion: string;
  setNewDiscussion: Dispatch<SetStateAction<string>>;
  handleCloseNewDiscussionContainer: () => void;
  handleSendDiscussion: () => void;

  replyErrors: ServiceError[] | undefined;
  showReplyTextArea: boolean;
  newReply: string;
  setNewReply: Dispatch<SetStateAction<string>>;
  setShowReplyTextArea: Dispatch<SetStateAction<boolean>>;
  handleSendReply: (threadUuid: string) => void;
  handleCloseReplyContainer: () => void;
  canWriteExternalDiscussion: boolean;
  isHandleSendDiscussionLoading: boolean;
  isHandleSendReplyLoading: boolean;
}

type LatestMessageAndThreadUuids = {
  latestMessageUuid: string;
  threadUuid: string;
};

export const useDiscussionThreadBody = (
  threads: Thread[],
  setThreads: Dispatch<SetStateAction<Thread[]>>,
  newThreadInformation: NewThreadInformation,
  internalTeamSelected: boolean
): UseDiscussionThreadBodyReturnData => {
  const { activityHistoryUuid, canWriteExternalDiscussion, activityHistoryRowVersion, setActivityHistoryRowVersion } =
    useActivityWizardContextSwitcher();

  const { user, currentUserType, currentOrganisationUuid } = useAuth();

  const [newDiscussionErrors, setNewDiscussionErrors] = useState<ServiceError[] | undefined>();
  const [showNewDiscussionTextArea, setShowNewDiscussionTextArea] = useState<boolean>(false);
  const [newDiscussion, setNewDiscussion] = useState<string>("");

  const [replyErrors, setReplyErrors] = useState<ServiceError[] | undefined>();
  const [showReplyTextArea, setShowReplyTextArea] = useState<boolean>(false);
  const [newReply, setNewReply] = useState<string>("");
  const [isHandleSendDiscussionLoading, setIsHandleSendDiscussionLoading] = useState(false);
  const [isHandleSendReplyLoading, setIsHandleSendReplyLoading] = useState(false);

  const [latestMessageAndThreadUuids, setLatestMessageAndThreadUuids] = useState<LatestMessageAndThreadUuids[]>([]);

  useEffect(() => {
    const tempArr: LatestMessageAndThreadUuids[] = [];

    threads.forEach((t) => {
      tempArr.push({
        latestMessageUuid: t.messages[t.messages.length - 1].uuid,
        threadUuid: t.uuid,
      } as LatestMessageAndThreadUuids);
    });

    setLatestMessageAndThreadUuids(tempArr);
  }, [threads]);

  const handleCloseNewDiscussionContainer = (): void => {
    setNewDiscussion("");
    setShowNewDiscussionTextArea(false);
    setNewDiscussionErrors([]);
  };

  const visibility = internalTeamSelected
    ? DiscussionVisibilityConstants.INTERNAL
    : DiscussionVisibilityConstants.EXTERNAL;

  const internalMessageTargetOrganisationRoles =
    currentUserType === OrganisationTypeConstants.DEVELOPER
      ? ["Developer", "Guest - Read", "Guest - Write"]
      : ["Validator"];

  const messageStatus =
    currentUserType === OrganisationTypeConstants.VERIFIER && internalTeamSelected ? "Published" : "Draft";

  const handleSendDiscussion = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await createDiscussionThread({
      componentKey: newThreadInformation.componentKey,
      dataPath: newThreadInformation.dataPath,
      messageContent: newDiscussion,
      messageObjectType: newThreadInformation.messageObjectType,
      messageObjectUuid: newThreadInformation.messageObjectUuid,
      messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
      messageType: newThreadInformation.messageType,
      messageStatus,
      threadObjectType: "Activity",
      threadObjectUuid: newThreadInformation.threadObjectUuid,
      threadType: "Discussion",
      visibility,
      messageTargetOrganisationRoles: internalTeamSelected
        ? internalMessageTargetOrganisationRoles
        : ["Developer", "Validator", "Guest - Read", "Guest - Write"],
    });

    if (res.status === Status.Success && res.data && user) {
      const tempThreads = threads.concat([
        {
          resolved: false,
          uuid: res.data.threadUuid,
          hasNonDeletedMessagesCreatedByOtherUsers: false,
          messages: [
            {
              uuid: res.data.messageUuid,
              content: newDiscussion,
              createdAt: new Date(),
              isRead: false,
              status: messageStatus,
              messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
              createdByUserUuid: user.userUuid,
              createdByUserFullName: getUserFullName(user.fullName, user.firstName, user.lastName),
              createdByUserAvatarUrl: user.avatar?.url,
              rowVersion: 1,
            },
          ],
        },
      ]);

      tempThreads.sort((a, b) => {
        return Number(a.resolved) - Number(b.resolved);
      });

      setThreads(tempThreads);

      if (setActivityHistoryRowVersion && activityHistoryRowVersion)
        setActivityHistoryRowVersion(activityHistoryRowVersion + 1);

      handleCloseNewDiscussionContainer();

      Toast.success({ message: "Discussion added" });
    }

    if (res.status === Status.Error) {
      setNewDiscussionErrors(res.errors);
    }
  }, setIsHandleSendDiscussionLoading);

  const handleCloseReplyContainer = (): void => {
    setReplyErrors([]);
    setShowReplyTextArea(false);
    setNewReply("");
  };

  const handleSendReply = useIsLoadingWrapper(async (threadUuid: string): Promise<void> => {
    if (activityHistoryUuid && currentOrganisationUuid) {
      const res = await createDiscussionMessage({
        content: newReply,
        messageObjectType: "Activity History",
        messageObjectUuid: activityHistoryUuid,
        messageType: "Comment",
        messageStatus,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parentMessageUuid: latestMessageAndThreadUuids.find((lmtu) => lmtu.threadUuid === threadUuid)!
          .latestMessageUuid,
        sourceOrganisationUuid: currentOrganisationUuid,
        threadUuid,
        targetOrganisationRoles: internalTeamSelected
          ? internalMessageTargetOrganisationRoles
          : ["Developer", "Validator", "Guest - Read", "Guest - Write"],
      });

      if (res.status === Status.Success && res.data && user) {
        setThreads(
          threads.map((t) => {
            if (t.uuid === threadUuid) {
              return {
                ...t,
                messages: t.messages
                  .map((m) => {
                    if (
                      !m.isRead &&
                      ((m.messageSourceOrganisationUuid !== currentOrganisationUuid &&
                        visibility === DiscussionVisibilityConstants.EXTERNAL) ||
                        (m.createdByUserUuid !== user.userUuid &&
                          visibility === DiscussionVisibilityConstants.INTERNAL))
                    ) {
                      return { ...m, isRead: true };
                    }
                    return m;
                  })
                  .concat([
                    {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      uuid: res.data!.messageUuid,
                      content: newReply,
                      createdAt: new Date(),
                      isRead: false,
                      status: messageStatus,
                      messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
                      createdByUserUuid: user.userUuid,
                      createdByUserFullName: getUserFullName(user.fullName, user.firstName, user.lastName),
                      createdByUserAvatarUrl: user.avatar?.url,
                      rowVersion: 1,
                    },
                  ]),
              };
            }

            return { ...t };
          })
        );

        setLatestMessageAndThreadUuids(
          latestMessageAndThreadUuids.map((lmtu) => {
            if (lmtu.threadUuid === threadUuid) {
              return {
                ...lmtu,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                latestMessageUuid: res.data!.messageUuid,
              };
            }

            return {
              ...lmtu,
            };
          })
        );

        handleCloseReplyContainer();
      }

      if (res.status === Status.Error) {
        setReplyErrors(res.errors);
      }
    }
  }, setIsHandleSendReplyLoading);

  useEffect(() => {
    handleCloseNewDiscussionContainer();
    handleCloseReplyContainer();
  }, [internalTeamSelected]);

  return {
    currentUserUuid: user?.userUuid,
    newDiscussionErrors,
    showNewDiscussionTextArea,
    setShowNewDiscussionTextArea,
    newDiscussion,
    setNewDiscussion,
    handleCloseNewDiscussionContainer,
    handleSendDiscussion,
    replyErrors,
    showReplyTextArea,
    setShowReplyTextArea,
    newReply,
    setNewReply,
    handleCloseReplyContainer,
    handleSendReply,
    canWriteExternalDiscussion,
    isHandleSendDiscussionLoading,
    isHandleSendReplyLoading,
  };
};
