import "./RoadmapKey.css";

import { ColorConstants } from "../../../../../constants";

export const RoadmapKey = (): JSX.Element => {
  return (
    <div className="RoadmapKey">
      <h3>Key</h3>
      <div className="KeyItem">
        <div className="KeyItemColour" style={{ backgroundColor: ColorConstants.BLUE }} />
        <p>Feasibility</p>
      </div>
      <div className="KeyItem">
        <div className="KeyItemColour" style={{ backgroundColor: ColorConstants.CORAL }} />
        <p>Design</p>
      </div>
      <div className="KeyItem">
        <div className="KeyItemColour" style={{ backgroundColor: ColorConstants.CYAN }} />
        <p>Implementation</p>
      </div>
      <div className="KeyItem">
        <div className="KeyItemColour" style={{ backgroundColor: ColorConstants.GREEN }} />
        <p>Ongoing monitoring</p>
      </div>
      <div className="KeyItem">
        <div className="KeyItemColour" style={{ backgroundColor: ColorConstants.I_BLUE_40 }} />
        <p>Today</p>
      </div>
    </div>
  );
};
