import "./QuickViewOptions.css";

import { Button } from "../../../../forms";
import { ViewMode } from "../../models";

type ViewSwitcherProps = {
  viewMode: ViewMode;
  onChangeViewMode: (viewMode: ViewMode) => void;
};

export const QuickViewOptions = ({ viewMode, onChangeViewMode }: ViewSwitcherProps): JSX.Element => {
  return (
    <div className="QuickViewOptions">
      <Button
        onClick={() => onChangeViewMode(ViewMode.Months3)}
        text="3M"
        size="small"
        variant={viewMode === ViewMode.Months3 ? "primary" : "reversedPrimary"}
      />
      <Button
        onClick={() => onChangeViewMode(ViewMode.Months6)}
        text="6M"
        size="small"
        variant={viewMode === ViewMode.Months6 ? "primary" : "reversedPrimary"}
      />
      <Button
        onClick={() => onChangeViewMode(ViewMode.Year)}
        text="1Y"
        size="small"
        variant={viewMode === ViewMode.Year ? "primary" : "reversedPrimary"}
      />
      <Button
        onClick={() => onChangeViewMode(ViewMode.Years3)}
        text="3Y"
        size="small"
        variant={viewMode === ViewMode.Years3 ? "primary" : "reversedPrimary"}
      />
      <Button
        onClick={() => onChangeViewMode(ViewMode.Years10)}
        text="10Y"
        size="small"
        variant={viewMode === ViewMode.Years10 ? "primary" : "reversedPrimary"}
      />
      <Button
        onClick={() => onChangeViewMode(ViewMode.Years20)}
        text="20Y"
        size="small"
        variant={viewMode === ViewMode.Years20 ? "primary" : "reversedPrimary"}
      />
    </div>
  );
};
