import "./ProjectRoadmap.css";

import { Dispatch, memo, SetStateAction } from "react";
import { JSX } from "react/jsx-runtime";
import { Chart } from "react-google-charts";

import { ProjectRoadmapData } from "../../../models";
import { LoadingSpinner } from "../LoadingSpinner";
import { ProjectRoadmapRowLabels } from "./components/ProjectRoadmapRowLabels";
import { QuickViewOptions } from "./components/QuickViewOptions/QuickViewOptions";
import { RoadmapKey } from "./components/RoadmapKey";
import { useProjectRoadmap } from "./useProjectRoadmap";

interface ProjectRoadmapProps {
  data: ProjectRoadmapData[];
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const ProjectRoadmap = memo(({ data, isLoading, setIsLoading }: ProjectRoadmapProps): JSX.Element => {
  const {
    chartData,
    rowLabelData,

    options,
    chartEvents,
    height,
    viewMode,
    projectRoadmapRef,
    projectRoadmapRowLabelsRef,
    onChangeViewMode,
    onScroll,
  } = useProjectRoadmap({ data, setIsLoading });

  return data.length > 0 ? (
    <div className="ProjectRoadmap">
      <QuickViewOptions viewMode={viewMode} onChangeViewMode={onChangeViewMode} />
      <div
        ref={projectRoadmapRef}
        className={`ProjectRoadmapContainer ${isLoading ? "Hidden" : ""}`}
        onScroll={onScroll}
      >
        <ProjectRoadmapRowLabels ref={projectRoadmapRowLabelsRef} data={rowLabelData} />
        <Chart
          chartType="Timeline"
          data={chartData}
          width={viewMode}
          height={`${height}px`}
          options={options}
          chartEvents={chartEvents}
          chartVersion="51"
        />
      </div>
      {isLoading && <LoadingSpinner size="medium" type="dark" />}
      <RoadmapKey />
    </div>
  ) : (
    <p className="body2 NoData">No project roadmap data available</p>
  );
});
