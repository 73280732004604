import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { InteractionObjectType, InteractionType } from "../../constants";
import { InteractionTagData } from "../../models";
import { ProjectContext } from "../../route/shared/projects/project/ProjectContext";
import { searchInteractions } from "../../service/query";
import { ServiceError, Status } from "../../service/Shared";
import { useAuth } from "../../useAuth";
import { dataGridMapFilterCriteria } from "../../widget";
import { InteractionTag, NewTag } from "./models";

interface TagsProviderProps {
  children?: JSX.Element;
}

interface TagsContextType {
  initTags: InteractionTag[] | undefined;
  setInitTags: Dispatch<SetStateAction<InteractionTag[] | undefined>>;
  currentTags: InteractionTag[] | undefined;
  setCurrentTags: Dispatch<SetStateAction<InteractionTag[] | undefined>>;

  newTags: NewTag[];
  setNewTags: Dispatch<SetStateAction<NewTag[]>>;
  handleAddNewTag: () => void;

  showManageTagsPanel: boolean;
  setShowManageTagsPanel: Dispatch<SetStateAction<boolean>>;

  showTagProjectsPanelUuid: string | undefined;
  setShowTagProjectsPanelUuid: Dispatch<SetStateAction<string | undefined>>;

  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;

  errors: ServiceError[] | undefined;
  setErrors: Dispatch<SetStateAction<ServiceError[] | undefined>>;
  handleNewTextboxChange: (text: string, tagId: number) => void;
}

export const TagsContext = createContext<TagsContextType>({} as TagsContextType);

export const TagsProvider = ({ children }: TagsProviderProps): JSX.Element => {
  const { currentOrganisationUuid } = useAuth();
  const { projectDetails } = useContext(ProjectContext);

  const [refresh, setRefresh] = useState<boolean>(false);
  const [errors, setErrors] = useState<ServiceError[]>();

  // Using init & current tags to see the diff between the two when saving
  const [initTags, setInitTags] = useState<InteractionTag[]>();
  const [currentTags, setCurrentTags] = useState<InteractionTag[]>();

  const [newTags, setNewTags] = useState<NewTag[]>([]);

  const [showManageTagsPanel, setShowManageTagsPanel] = useState(false);
  const [showTagProjectsPanelUuid, setShowTagProjectsPanelUuid] = useState<string>();

  // --- Shared methods forManageTags & TagsPanel ---
  const handleNewTextboxChange = (text: string, tagId: number): void => {
    if (newTags) {
      setNewTags(newTags.map((tag) => (tag.id === tagId ? { ...tag, name: text } : tag)));
    }
  };

  const handleAddNewTag = (): void => {
    setNewTags([
      ...newTags,
      {
        id: newTags.length + 1,
        name: "",
        checked: true,
      },
    ]);
  };

  const fetchData = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);
    filterCriteria.type = {
      operator: "eq",
      value: InteractionType.TAG,
    };
    filterCriteria.objectType = {
      operator: "eq",
      value: InteractionObjectType.ORGANISATION,
    };
    filterCriteria.objectUuid = {
      operator: "eq",
      value: currentOrganisationUuid,
    };
    const res = await searchInteractions({
      paging: {
        beforeCursor: null,
        afterCursor: null,
        limit: 50,
      },
      filter: { results: filterCriteria },
    });

    if (res.status === Status.Success && res.data?.results != null) {
      const resTags = res.data.results.map((x) => ({
        uuid: x.uuid ?? "",
        data: JSON.parse(x.data) as InteractionTagData,
        checked:
          projectDetails != null
            ? x.objectInteractions.some((oi) => oi.objectUuid === projectDetails.uuid && oi.endDate == null)
            : true,
        rowVersion: x.rowVersion,
      }));

      setInitTags(resTags);
      setCurrentTags(resTags);
      setNewTags([]);
    }
  }, [currentOrganisationUuid, projectDetails, refresh]);

  useEffect(() => {
    fetchData();
  }, [currentOrganisationUuid, projectDetails, refresh]);

  const memoisedValue = useMemo(
    () => ({
      initTags,
      setInitTags,

      currentTags,
      setCurrentTags,

      newTags,
      setNewTags,
      handleAddNewTag,

      showManageTagsPanel,
      setShowManageTagsPanel,

      showTagProjectsPanelUuid,
      setShowTagProjectsPanelUuid,

      refresh,
      setRefresh,

      errors,
      setErrors,

      handleNewTextboxChange,
    }),
    [initTags, currentTags, newTags, refresh, errors, showManageTagsPanel, showTagProjectsPanelUuid]
  );

  return <TagsContext.Provider value={memoisedValue}>{children}</TagsContext.Provider>;
};
