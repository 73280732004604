export const INVALID_DATE_OBJECT_STRING_SUFFIX = "|INVALID_DATE_OBJECT";

/** *
 * Transforms a `string` of format `DD/MM/YYYY` to a `Date`
 * @param value
 */
export const parseDate = (value: string): Date => {
  const [day, month, year] = value.split("/").map((p) => parseInt(p, 10));
  return new Date(Date.UTC(year, month - 1, day));
};

/** *
 * Transforms a `Date` to a 'string' of format `DD/MM/YYYY`
 * @param value
 */
export const convertDateToStringDDMMYYYY = (value: Date): string => {
  const day = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
  const month = value.getMonth() + 1 < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
  const year = value.getFullYear();
  return `${day}/${month}/${year}`;
};

/** *
 * Converts a date to a string of format YYYY-MM-DD
 * @param value
 */
export const convertDateToStringYYYYMMDD = (value: Date | null | undefined): string | null => {
  if (!value) {
    return null;
  }

  const dateUTC = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
  return dateUTC.toISOString().slice(0, 10);
};

/** *
 * Checks if a string is a valid date and fits one of this 2 formats `dd/MM/yyyy` or `d/M/yyyy`
 * @param value
 */
export const isValidDate = (value: string | null | undefined): boolean => {
  if (!value) {
    return true;
  }

  const [day, month, year] = value.split("/");
  if (
    !day ||
    day.length < 1 ||
    day.length > 2 ||
    !month ||
    month.length < 1 ||
    month.length > 2 ||
    !year ||
    year.length !== 4
  ) {
    return false;
  }

  const [dayInt, monthInt, yearInt] = [day, month, year].map((p) => parseInt(p, 10));
  const date = new Date(yearInt, monthInt - 1, dayInt);
  return date.getDate() === dayInt && date.getMonth() + 1 === monthInt && date.getFullYear() === yearInt;
};

/** *
 *  Checks if a string is a valid date and transforms it to a `Date`
 * @param value
 */
export const validateAndParseDate = (value: string): Date => {
  if (!isValidDate(value)) {
    return new Date("Invalid Date");
  }

  return parseDate(value);
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
};

export const formatDateWithRecency = (date: Date): string => {
  const currentDate = new Date();
  if (isSameDay(currentDate, date)) {
    return "Today";
  }

  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  if (isSameDay(yesterdayDate, date)) {
    return "Yesterday";
  }

  return convertDateToStringDDMMYYYY(date);
};

export const calculateApproximateYearsAndMonthsBetween = (
  startDate: Date,
  endDate: Date
): { years: number; months: number } => {
  const secondsInAMonth = 30 * 24 * 60 * 60; // Approximate
  const secondsInAYear = 365 * 24 * 60 * 60; // Approximate

  const dateDifferenceInSeconds = (endDate.getTime() - startDate.getTime()) / 1000;

  const years = Math.floor(dateDifferenceInSeconds / secondsInAYear);
  const remainingSeconds = dateDifferenceInSeconds % secondsInAYear;
  const months = Math.floor(remainingSeconds / secondsInAMonth);

  return { years, months };
};

export const addDaysToDate = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};
