import "../Tooltip.css";
import "./GenericTooltip.css";

import Tooltip from "rc-tooltip";
import { ReactElement } from "react";

import { useTooltip } from "../useTooltip";

export interface GenericTooltipProps {
  content: ReactElement;
  children: ReactElement;
}

export const GenericTooltip = ({ content, children }: GenericTooltipProps): JSX.Element => {
  const { isVisible, handleVisibleChange } = useTooltip();

  return (
    <Tooltip
      placement="bottom"
      visible={isVisible}
      onVisibleChange={(visible: boolean) => handleVisibleChange(visible)}
      classNames={{ root: "KanaTooltip GenericTooltip" }}
      overlay={content}
    >
      {children}
    </Tooltip>
  );
};
